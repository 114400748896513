import axios from "axios";

/**
 {
    // `url` is the server path that will be used for the request
    url,
    // `params` are the URL parameters to be sent with the request
    params,
    // `data` is the data to be sent as the request body
    // Only applicable for request methods 'PUT', 'POST', 'DELETE', and 'PATCH'
    data,
    signal,
    // `withCredentials` indicates whether or not cross-site Access-Control requests
    // should be made using credentials
    withCredentials,
  } = requestConfig;
  
  let config = { headers, params, withCredentials };
  const response = await api.post(url, data, config, signal);
 */

export const Urls = {
  baseURL: `${process.env.REACT_APP_FASTAPI_BASE}${process.env.REACT_APP_V1}`,
  users: "/users/profile",
  actions: "/users/resources/actions",
  refresh: "/users/auth/refresh",
  forgotPassword: "/users/forgot-password",
  resetPassword: "/users/profile/password",
  suspend: "/users/suspend",
  profile: "/repo/settings/users/:id",
  verify: "/users/verify",
  channels: "/mirth/channels/search",
  services: "/mirth/services/search",
  stats: "/mirth/connectors/stats",
  servers: "/mirth/servers",
  dashboard: "/reports/dashboard",
  notifications: "/repo/notifications",
  reports: {
    users: "/reports/users",
    channels: "/reports/channels",
    inventory: "/reports/inventory",
  },
};

export const headers = {
  "Access-Control-Allow-Origin": process.env.REACT_APP_CDA_ORIGIN,
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const axiosPrivate = axios.create({
  baseURL: Urls.baseURL,
  headers,
  withCredentials: true,
});

const api = axios.create({ baseURL: Urls.baseURL });

export default api;

/**
 * Returns a JSON response with status and data
 * with { status: int, data: obj }
 * @param {*} response: the fastAPI response object.
 * @returns custom JSON response
 */
export const JsonResponse = (response) => {
  return { status: response.status, data: response.data };
};

export function getTerms(filters) {
  const { name, page, limit, status, ...defaults } = filters;
  const terms = { searchTerm: name, pageSize: limit, page, status };

  for (let [key, valueObj] of Object.entries(defaults)) {
    for (let [k, v] of Object.entries(valueObj))
      if (v) (terms[key] = terms[key] || []).push(k);
  }

  return terms;
}

export function removeKey(filters, filterKey) {
  return Object.entries(filters || {}).reduce((reduceObj, [key, value]) => {
    if (value) {
      let lowerValue = value.toString().toLowerCase();
      if (lowerValue !== filterKey.toLowerCase()) reduceObj[key] = value;
    }
    return reduceObj;
  }, {});
}
