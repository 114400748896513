import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Skeleton } from "../../components";

// Service lines using a simple radar chart
export const ServiceCard = ({ data }) => {
  return (
    <div className="w-[34%] flex flex-col content-between h-[23rem]">
      <div className="border border-slate-300 shadow h-full rounded-sm bg-white">
        <div className="mt-3 ml-4">
          <span className="text-[16px] text-neutral-700 font-semibold tracking-tight">
            Service Lines
          </span>
          <p className="text-xs text-neutral-500 leading-4">
            Provided across all hospital locations
          </p>
        </div>
        {data ? (
          <ResponsiveContainer width="100%" height="90%">
            <RadarChart data={data} outerRadius={124}>
              <PolarGrid />
              <Tooltip />
              <PolarAngleAxis dataKey="shortname" tick={{ fontSize: 12 }} />
              <PolarRadiusAxis angle={60} tick={{ fontSize: 12 }} />
              <Radar
                name="Services"
                dataKey="value"
                stroke="#8884d8"
                fill="#8884d8"
                fillOpacity={0.6}
              />
            </RadarChart>
          </ResponsiveContainer>
        ) : (
          <div className="w-full flex-ij">
            <Skeleton widget />
          </div>
        )}
      </div>
    </div>
  );
};
