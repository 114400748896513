const Card = ({ children, sx }) => {
  return (
    <div className="card" style={sx}>
      {children}
    </div>
  );
};

const MiniOption = ({ key, label, value, Icon, callback }) => {
  return (
    <li
      className={
        "border shadow-sm w-[96px] p-3 flex flex-col space-y-1 text-gray-700 items-center cursor-pointer justify-center transition-all duration-300 hover:bg-slate-50" +
        (value === "All" ? "border-slate-300" : " border-[#AAFF00] shadow-md")
      }
      key={key || label}
      onClick={callback}
    >
      <span className="mb-2">
        <Icon size={30} />
      </span>
      <span className="font-semibold">{label}</span>
      <span>{value}</span>
    </li>
  );
};

const MiniCard = ({ children }) => {
  return (
    <ol className="flex-i justify-start space-x-3 h-[110px] text-[12px] text-gray-700 leading-tight">
      {children}
    </ol>
  );
};

export default Card;
export { MiniCard, MiniOption };
