import { createContext, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert, useCommon, useDrawer } from "../hooks";

const ServerContext = createContext();

export const ServerContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [dialog, setDialog] = useState({});
  const { fetchServers } = useCommon();
  const { repoAlert, setAlertMessage } = useAlert("server-alert");
  const { drawer, showWindow, closeWindow } = useDrawer(
    "server-portal",
    dialog,
    () => setDialog({})
  );

  const resetDialog = useCallback(() => {
    setDialog({});
    closeWindow();
    // eslint-disable-next-line
  }, []);

  // Set the dialog content for the drawer
  // and open the window
  const dialogHandler = useCallback((dlg) => {
    setDialog(dlg);
    showWindow();
    // eslint-disable-next-line
  }, []);

  const closeHandler = useCallback(
    (message = null) => {
      if (message) {
        setAlertMessage(message);
        fetchServers();
      }
      resetDialog();
    },
    [setAlertMessage, fetchServers, resetDialog]
  );

  return (
    <ServerContext.Provider
      value={{
        drawer,
        repoAlert,
        handlers: {
          alert: (message) => setAlertMessage(message, false),
          error: (message, statusCode = null) => {
            setAlertMessage(message, true);
            if (statusCode === 403) navigate("/expired", { replace: true });
          },
          close: closeHandler,
          dialog: dialogHandler,
        },
      }}
    >
      {children}
    </ServerContext.Provider>
  );
};

export default ServerContext;
