import dagre from "dagre";
import FlowRender from "../../services/FlowRender";
import { useEffect, useState } from "react";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeSize = { width: 136, height: 24 };

function getLayoutedElements(nodes, edges, direction = "TB") {
  const isHorizontal = direction === "LR";

  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: nodeSize.width,
      height: nodeSize.height,
    });
  });

  edges.forEach((edge) => dagreGraph.setEdge(edge.source, edge.target));

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    // Shifting the dagre node position (anchor-center center) to the top left
    // so it matches the React Flow node anchor point (top left)
    node.position = {
      x: nodeWithPosition.x - nodeSize.width / 2,
      y: nodeWithPosition.y - nodeSize.height / 2,
    };

    return node;
  });

  return { nodes, edges };
}

function getCustomLayout(nodes, edges) {
  const nodeHeight = 36;

  nodes.forEach((node, i) => {
    node.position = { x: i ? 100 : 0, y: i * nodeHeight * 1.5 };
    node.targetPosition = "left";
    node.sourcePosition = "bottom";
  });

  return { nodes, edges };
}

const useDagre = (origin, element, createNodes, direction, fitView) => {
  const [{ nodes, edges }, setCurr] = useState({});

  useEffect(() => {
    let flow;
    const [initialNodes, initialEdges] = createNodes(origin, element);

    if (direction === "LR") {
      flow = getCustomLayout(initialNodes, initialEdges);
    } else {
      const cd = direction || "TB";
      flow = getLayoutedElements(initialNodes, initialEdges, cd);
    }

    setCurr(flow);
  }, [origin, direction, element, createNodes]);

  return (
    <FlowRender
      initialNodes={nodes}
      initialEdges={edges}
      fitView={fitView}
      zoom={1.2}
      showCotnrols={false}
    />
  );
};

export default useDagre;
