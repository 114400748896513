const Avatar = () => {
  return <div>Avatar</div>;
};

const IconAvatar = ({ sx, Icon, color, size = 17, ...props }) => {
  return (
    <div className="avatar-icon" style={sx || { padding: 6 }} {...props}>
      <Icon size={size} color={color || "gray"} />
    </div>
  );
};

/**
 * Use this component if you want to stack a group of items
 * by overlapping the avatar components
 */
const StackedAvatar = ({ labels }) => {
  return (
    <div className="avatar-stacked">
      {labels.map((lbl) => (
        <span key={lbl.toLowerCase().replace("'", "")}>{lbl}</span>
      ))}
    </div>
  );
};

export default Avatar;
export { IconAvatar, StackedAvatar };
