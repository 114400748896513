import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { Button } from "../../components";
import { useAxiosPrivate, useServer } from "../../store/hooks";
import { MirthLoginForm } from "./MirthLoginForm";

export const ServerProgress = ({ server }) => {
  const axiosPrivate = useAxiosPrivate();
  const { staging } = server["data-import"];
  const {
    handlers: { close, dialog, error },
  } = useServer();

  const importMutation = useMutation({
    enabled: false,
    mutationKey: ["staging"],
    mutationFn: async ({ username, password }) => {
      return axiosPrivate.post(
        `/mirth/servers/${server.serverId}/staging/${staging.id}`,
        { username, password }
      );
    },
    onSuccess: ({ data }) => close(data.message),
    onError: ({ response }) => {
      const { detail } = response?.data || {};
      error(
        detail ||
          "Oops! The Repository server encountered an unexpected error.",
        response?.status
      );
    },
  });

  const handleAuthorize = useCallback(
    (username, password) => {
      importMutation.mutate({ username, password });
      close();
    },
    // eslint-disable-next-line
    [close]
  );

  // Step [3]
  // --------
  // Extract channels from Mirth Connect server.
  // Transform the XML metadata into models suitable to
  // Load into the EIIR repository database.

  const handleClick = useCallback(() => {
    dialog({
      title: "Mirth API Login",
      content: (
        <div className="drawer-form">
          <MirthLoginForm
            serverName={server.name}
            title="Start Import"
            onAuthorize={handleAuthorize}
          />
        </div>
      ),
    });
  }, [dialog, handleAuthorize, server.name]);

  return (
    <div className="flex mx-4 px-4">
      <div className="server-progress">
        <span>This may take a few minutes.</span>
        <p>Import Mirth Channels</p>
      </div>
      <div className="flex-ij">
        <Button
          content="Launch!"
          loading={importMutation.isLoading || server.status === "S"}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};
