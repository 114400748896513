import { useEffect, useRef } from "react";

export const useClickAway = (callback) => {
  const currRef = useRef();

  useEffect(() => {
    const clickHandler = ({ target }) => {
      // const currId = currRef.current.id;
      const outside = currRef.current && !currRef.current.contains(target);

      callback(outside);
    };

    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, 
  // eslint-disable-next-line
  [callback]);

  return currRef;
};

export default useClickAway;
