import { createContext, useState } from "react";

const AuthContext = createContext({});

export const Role = {
  Analyst: 1,
  Manager: 2,
  Admin: 3,
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  return (
    <AuthContext.Provider
      value={{
        auth,
        isManager: auth.rank >= Role.Manager,
        isAdmin: auth.rank === Role.Admin,
        setAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
