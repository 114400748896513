import { useNavigate } from "react-router-dom";
import { Button, Repography } from "../components";

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <div className="page-err">
      <Repography h2 center text="403" />
      <Repography h6 center text="Forbidden" />
      <Repography center text="Unfortunately, access to this resource on the server is denied." />
      <div>
        <Button
          content="Go back"
          onClick={() => navigate(-1, { replace: true })}
        />
      </div>
    </div>
  );
};

export default Unauthorized;
