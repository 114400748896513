import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Button, IconButton } from "../../components";
import { createInput } from "../../form/FormCreate";
import { isValidEmail, required } from "../../form/validation-rules";
import { useForm, useToast } from "../../store/hooks";
import api, { Urls, headers } from "../../store/axios";
import useForceUpdate from "../../store/hooks/use-forceupdate";
import { FiCornerUpLeft as LeftIcon } from "react-icons/fi";

const forgotConfig = {
  fguser: {
    type: "input",
    ...createInput({
      id: "fguser",
      label: "Username",
      helperText: "Reset Requested For",
    }),
    required,
    validateOnBlur: true,
    validationRules: [isValidEmail("Username"), required("Username")],
  },
};

export const ForgotPassword = ({ onBack }) => {
  const [requestedUser, setRequestedUser] = useState({});
  const { renderForm, resetForm } = useForm(forgotConfig);
  const { fguser } = renderForm();
  const { element: toastEl, warn } = useToast("pwd-request");
  const _isMounted = useRef(false);
  const { forceUpdate } = useForceUpdate();

  const email = fguser?.props?.value;

  useEffect(() => {
    if (_isMounted.current) return;
    _isMounted.current = true;

    resetForm();
    forceUpdate();
  }, [forceUpdate, resetForm]);

  const requestMutation = useMutation({
    mutationFn: async (email) =>
      await api.put(Urls.forgotPassword, { email }, { headers }),
    onSuccess: (response) => {
      const { user } = response.data;
      setRequestedUser({ name: user, email });
    },
    onError: ({ response }) => {
      const { data } = response;
      warn(data.detail);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    requestMutation.mutate(email);
  };

  return (
    <form id="forgot-pwdform" onSubmit={handleSubmit}>
      <fieldset>
        {requestedUser.name ? (
          <>
            <p className="header">Hi {requestedUser.name},</p>
            <div className="text-center">
              <p>We have sent a request to reset the password for:</p>
              <p className="text-center text-envision tracking-normal mb-4">
                {requestedUser.email}
              </p>
              A System Administrator will contact you soon with instructions on
              how to unlock your account.
              <br />
            </div>
            <Button content="Back to login" onClick={onBack} />
          </>
        ) : (
          <>
            <p className="header">Trouble Logging In?</p>
            <p className="text-sm text-center text-neutral-700 tracking-tight my-1 mx-5">
              Tell us your username to request a password reset and an
              Administrator will get you back on track in no time.
            </p>
            {toastEl}
            {fguser}
            <Button
              type="submit"
              content="Request Password Reset"
              loading={requestMutation.isLoading}
            />
            <IconButton
              icon={<LeftIcon />}
              position="left"
              label="Back to log in"
              onClick={onBack}
            />
          </>
        )}
      </fieldset>
    </form>
  );
};
