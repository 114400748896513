import { useCallback, useEffect, useState } from "react";
import PasswordGen from "../../form/PasswordGen";

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const digits = "0123456789";
const symbols = "!@#$%^&*()<>,.?/[]{}-=_+|/"

const usePasswordGen = (length) => {
  const [password, setPassword] = useState(null);
  const [pwdPolicy, setPwdPolicy] = useState({});

  const getRandomIndex = useCallback((min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }, []);

  useEffect(() => {
    let chars = letters;
    const { special, numbers } = pwdPolicy;
    if (special) chars += symbols;
    if (numbers) chars += digits;

    const pwd = Array.from({ length }).map((c) => {
      return chars[getRandomIndex(1, chars.length - 1)];
    });

    setPassword(pwd.join(""));
    // eslint-disable-next-line
  }, [pwdPolicy]);

  return {
    control: (
      <PasswordGen
        onPolicyChange={(value, checked) =>
          setPwdPolicy((curr) => ({ ...curr, [value]: checked }))
        }
        value={password}
      />
    ),
    password,
  };
};

export default usePasswordGen;
