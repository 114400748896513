import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

export const DestinationCard = ({ data }) => {
  return (
    <div className="container relative">
      <div className="absolute left-0 top-0 m-4 z-10">
        <p className="dash-title">Destination Types</p>
        <p className="dash-description w-[60%]">
          Active (inactive) destination connectors
        </p>
      </div>

      <section className="w-full h-[84%] absolute bottom-0 pb-2 -ml-4 z-0">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data}>
            <CartesianGrid horizontal={false} vertical={false} />
            <Tooltip />
            <Bar yAxisId="left" dataKey="active" fill="#560BD0" />
            <Bar yAxisId="right" dataKey="inactive" fill="#CAD0E8" />
          </BarChart>
        </ResponsiveContainer>
      </section>
    </div>
  );
};

export const ConnectorsList = ({ data }) => {
  return (
    <div className="container-list">
      {data?.map((x, i) => {
        return (
          <div
            key={x.connector}
            className={`connector${i % 2 ? " bg-neutral-50" : ""}`}
          >
            <div>{x.alias}</div>
            <div className="grid grid-cols-2 gap-x-1 w-10">
              <span className="text-end">{x.active}</span>
              <span className="inactive">| {x.inactive}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
