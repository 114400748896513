import { createContext, useCallback, useMemo, useState } from "react";
import { useAxiosPrivate } from "../hooks";
import { Urls } from "../axios";

const initialCommon = {
  servers: { stats: [] },
  connectors: { sources: [], destinations: [] },
  notifications: [],
};

const initialBoard = {
  hospitals: {},
  services: [],
  exchangeTypes: [],
};

const CommonContext = createContext(initialCommon);

function getConnectors(payload) {
  const types = [];
  for (let [, values] of Object.entries(payload)) {
    for (let [connector, value] of Object.entries(values)) {
      types.push({
        connector,
        alias: value.name,
        active: value.active,
        inactive: value.inactive,
      });
    }
  }

  types.sort((a, b) => (a.active > b.active ? 1 : -1));
  return types;
}

export const CommonProvider = ({ children }) => {
  const [servers, setServers] = useState({ stats: [] });
  const [notifications, setNotifications] = useState([]);
  const [{ sources, destinations }, setConnectors] = useState({});
  const [{ hospitals, services, exchangeTypes }, setDashboard] =
    useState(initialBoard);

  const axiosPrivate = useAxiosPrivate();

  const initialized = useMemo(() => sources?.length > 0, [sources]);

  const fetchServers = useCallback(async () => {
    try {
      const { data } = await axiosPrivate(Urls.servers);
      const stats = (data || []).map((s) => ({
        server: s.name,
        serverId: s.serverId,
        value: s.channels,
      }));
      setServers({ data, stats });
    } catch ({ code, response }) {
      console.log(response);
    }
    // eslint-disable-next-line
  }, []);

  const fetchNotifications = useCallback(async () => {
    try {
      const { data } = await axiosPrivate(Urls.notifications);
      setNotifications(data);
    } catch ({ code, response }) {
      console.log(response);
    }
    // eslint-disable-next-line
  }, []);

  const fetchConnectorsStats = useCallback(
    async () => {
      const { data } = await axiosPrivate(Urls.stats);

      // update connectors only if sources and/or destinations
      // have not already been fulfilled
      if (initialized) return;

      setConnectors({
        sources: getConnectors(data.sources),
        destinations: getConnectors(data.dests),
      });
    },
    // eslint-disable-next-line
    [initialized]
  );

  const fetchDashboard = useCallback(async () => {
    const { data } = await axiosPrivate(Urls.dashboard);
    setDashboard({
      hospitals: data.hospital || {},
      exchangeTypes: data.exchange || [],
      services: data.service || [],
    });
    // eslint-disable-next-line
  }, []);

  const loadCommon = useCallback(async () => {
    await Promise.all([
      fetchDashboard(),
      fetchConnectorsStats(),
      fetchNotifications(),
      fetchServers(),
    ]);
  }, [fetchConnectorsStats, fetchDashboard, fetchNotifications, fetchServers]);

  const clearCommon = useCallback(() => {
    setServers({ stats: [] });
    setNotifications([]);
    setConnectors({ sources: [], destinations: [] });
    setDashboard(initialBoard);
  }, []);

  const removeNotification = useCallback((noteId) => {
    setNotifications((curr) => {
      return noteId === "all" ? [] : curr.filter((n) => n.id !== noteId);
    });
  }, []);

  const hasNotifications = useMemo(
    () => notifications?.length > 0,
    [notifications]
  );

  return (
    <CommonContext.Provider
      value={{
        notifications,
        servers,
        services,
        hospitals,
        sources,
        exchangeTypes,
        destinations,
        hasNotifications,
        clearCommon,
        loadCommon,
        fetchServers,
        removeNotification,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContext;
