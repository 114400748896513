import { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAlert, useAuth, useAxiosPrivate } from "../../store/hooks";
import { Panel, PanelHeader, Skeleton } from "../../components";
import { ShowPolicies } from "./ShowPolicies";
import { ShowProfile } from "./ShowProfile";
import { ShowPassword } from "./ShowPassword";
import { RiUserSettingsLine as AccountIcon } from "react-icons/ri";

const UserAccount = () => {
  const { repoAlert, setAlertMessage } = useAlert("account-alert");
  const { id } = useParams();
  const {
    auth: { publicId: updator },
  } = useAuth();

  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();

  const handleInvalidate = useCallback(
    (message) => {
      setAlertMessage(message);
      queryClient.invalidateQueries({
        queryKey: ["users", { userId: id }],
        exact: true,
      });
    },
    [id, queryClient, setAlertMessage]
  );

  const { data, isFetching } = useQuery({
    queryKey: ["users", { userId: id }],
    queryFn: async () => await axiosPrivate(`/users/${id}/profile`),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const isSelf = id === updator;
  const profile = data?.data || {};

  return (
    <>
      <Panel>
        <PanelHeader
          PanelIcon={AccountIcon}
          title="User Profile"
          subtitle="Account"
        />
      </Panel>

      <div className="flex space-x-3 w-full min-h-[600px] mb-20 tracking-tight">
        <div className="w-full min-h-96 flex flex-col space-y-3">
          {isFetching ? (
            <Skeleton list={{ rows: 5, cols: 2 }} />
          ) : profile ? (
            <>
              <ShowProfile
                self={isSelf}
                account={profile.account}
                invalidate={handleInvalidate}
              />
              {isSelf && <ShowPassword invalidate={handleInvalidate} />}
            </>
          ) : null}
        </div>
        <div>
          {repoAlert}
          {isFetching ? (
            <Skeleton list={{ rows: 5, cols: 1 }} />
          ) : (
            <ShowPolicies
              publicId={id}
              permissions={profile.permissions || {}}
              onInvalidate={handleInvalidate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default memo(UserAccount);
