import { useCallback, useState } from "react";
import { useClickAway, useOpenState } from ".";
import { DropContainer } from "../../components";

const useDropContainer = (id) => {
  const [dialog, setDialog] = useState({});
  const { isOpen, showWindow, dismiss } = useOpenState();
  const divRef = useClickAway((outside) => {
    if (outside) dismiss();
  });

  const showContainer = useCallback(
    (dlg) => {
      if (isOpen) return;
      setDialog(dlg);
      showWindow();
    },
    [isOpen, showWindow]
  );

  const closeWindow = useCallback(() => {
    dismiss();
  }, [dismiss]);

  return [
    <DropContainer containerId={id} isOpen={isOpen}>
      {dialog.content}
    </DropContainer>,
    divRef,
    showContainer,
    closeWindow,
    isOpen,
  ];
};

export default useDropContainer;
