import { memo } from "react";
import { BiServer as NewIcon } from "react-icons/bi";
import { Button } from "../../components";
import { useCommon } from "../../store/hooks";
import ServerManager from "./ServerManager";

const ServerList = ({ onSetDialog }) => {
  const { servers } = useCommon();

  const managers = (servers.data || []).map((server) => {
    return {
      key: server.serverId,
      content: (
        <ServerManager
          server={server}
          onSync={() => onSetDialog("Mirth Client API", server, true)}
          onUpdate={() => onSetDialog("Mirth Server Settings", server)}
        />
      ),
    };
  });

  managers.push({
    key: "newserver",
    content: (
      <div className="h-full flex-ij-col">
        <div className="mb-5">
          <Button outline onClick={() => onSetDialog("Add New Server", {})}>
            <div className="newserver">
              <span className="avatar">
                <NewIcon size={48} />
              </span>
              <span className="sbt">Add Server</span>
            </div>
          </Button>
        </div>
        <p className="tcenter">Connect a new server - it only takes</p>
        <p className="tcenter">a few seconds.</p>
      </div>
    ),
  });

  return (
    <div className="slist">
      <ul>
        {managers?.map((server) => {
          const { key, content } = server;
          return <li key={key}>{content}</li>;
        })}
      </ul>
    </div>
  );
};

export default memo(ServerList);
