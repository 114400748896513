import { useCallback, useState } from "react";
import { Alert } from "../../components";
import { useTimeout } from ".";

const useAlert = (id, footer = false) => {
  const [message, setAlert] = useState({});

  const close = useCallback(() => {
    setAlert((curr) => ({ danger: curr.danger }));
  }, []);

  const setAlertMessage = useCallback((message, danger) => {
    setAlert({ display: message, delay: 5000, danger });
  }, []);

  const repoAlert = (
    <Alert
      id={id}
      footer={footer}
      danger={message.danger}
      message={message.display}
      callback={close}
    />
  );

  useTimeout(close, message.delay);

  return { repoAlert, setAlertMessage, close };
};

export default useAlert;
