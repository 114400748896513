import api from "../axios";
import useAuth from "./use-auth";
import useCommon from "./use-common";

const useLogout = () => {
  const { setAuth } = useAuth();
  const { clearCommon } = useCommon();

  const logout = async () => {
    setAuth({ loggedOut: true });
    try {
      await api.post("/users/logout", {}, { withCredentials: true });
      clearCommon();
    } catch (error) {
      console.log(error);
    }
  };

  return logout;
};

export default useLogout;
