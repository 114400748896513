import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Button, IconAvatar, IconButton } from "../../components";
import { createInput } from "../../form/FormCreate";
import { required } from "../../form/validation-rules";
import { Urls } from "../../store/axios";
import {
  useAttempt,
  useAxiosPrivate,
  useForm,
  useRequirements,
  useToast,
} from "../../store/hooks";
import { BsPersonLock as LockIcon } from "react-icons/bs";
import {
  FiCheckCircle as CheckIcon,
  FiKey as KeyIcon,
  FiCornerUpLeft as LeftIcon,
} from "react-icons/fi";

export const ResetForm = ({ user, onBack, onContinue, onLockUser }) => {
  const [updated, setUpdated] = useState(false);
  const { isBelowThreshold, recordMistake } = useAttempt(3);
  const toast = useToast("reset-toast");
  const axiosPrivate = useAxiosPrivate();

  const {
    element: reqElements,
    handleBlur,
    handleValidation,
    handleVerify,
  } = useRequirements({ paddingBottom: 8, paddingTop: 3 });

  const resetMutation = useMutation({
    mutationFn: async ({ current, passwd }) =>
      await axiosPrivate.patch(Urls.resetPassword, {
        current,
        passwd,
        first: true,
      }),
    onSuccess: () => setUpdated(true),
    onError: ({ response }) => {
      const { detail } = response.data || {};
      recordMistake(user, null);
      toast.warn(detail);
    },
  });

  const resetFormConfig = {
    currentpwd: {
      type: "input",
      ...createInput({
        type: "password",
        id: "currentpwd",
        name: "reset-currentpwd",
        label: "Old Password",
        helperText: "Your current password",
      }),
      required: true,
      validateOnBlur: true,
      validationRules: [required("Current Password")],
    },
    newpwd: {
      type: "input",
      ...createInput({
        type: "password",
        id: "newpwd",
        name: "reset-newpwd",
        label: "New Password",
      }),
      required: true,
      validationRules: [required("New Password")],
      onInputChange: (control, value) => handleValidation(control, value),
      onBlur: (value) => handleBlur("pwdValue", value),
    },
    confirmpwd: {
      type: "input",
      ...createInput({
        type: "password",
        id: "confirmpwd",
        name: "reset-confirmpwd",
        label: "Confirm Password",
        helperText: "Must match new password",
      }),
      required: true,
      validateOnBlur: true,
      validationRules: [required("Confirm Password")],
      onBlur: (value) => handleBlur("confirmValue", value),
    },
  };

  const { renderForm, isFormValid } = useForm(resetFormConfig);
  const { currentpwd, newpwd, confirmpwd } = renderForm();

  const currentValue = currentpwd.props.value;
  const newValue = newpwd.props.value;
  const confirmValue = confirmpwd.props.value;

  useEffect(() => {
    if (!isBelowThreshold) {
      toast.warn(
        <span>
          The password for this account was not changed.
          <br />
          Please contact System Administrator for login assistance.
        </span>
      );
      if (onLockUser(user, "failed reset password")) {
      }
    }
    // eslint-disable-next-line
  }, [isBelowThreshold, onLockUser]);

  useEffect(
    () => toast.clear(),
    // eslint-disable-next-line
    [currentValue, newValue, confirmValue]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const sameValue =
        !!newValue && !!confirmValue && newValue === confirmValue;
      const formValid = await isFormValid();

      // validate entry against password requirements
      const message = handleVerify(formValid, newValue, sameValue);

      if (isBelowThreshold) {
        if (message) {
          toast.warn(message);
          recordMistake(user);
        } else
          resetMutation.mutate({ current: currentValue, passwd: newValue });
      }
    },
    // eslint-disable-next-line
    [newValue, currentValue, confirmValue, isBelowThreshold]
  );

  return (
    <form id="update-pwdform" onSubmit={handleSubmit}>
      <fieldset>
        {updated ? (
          <>
            <div className="avatar">
              <IconAvatar
                Icon={CheckIcon}
                color="green"
                size={20}
                sx={{ padding: 8 }}
              />
            </div>
            <p className="header">Password reset</p>
            <p className="info">
              Your password has been succesfully reset.
              <br /> Click below to log into the dashboard.
            </p>
            <Button content="Continue" onClick={() => onContinue("reset")} />
          </>
        ) : (
          <>
            <div className="avatar">
              <IconAvatar
                Icon={isBelowThreshold ? KeyIcon : LockIcon}
                color={isBelowThreshold ? "green" : "red"}
                size={24}
                sx={{ padding: 7 }}
              />
              <div className="header">Reset Password</div>
            </div>

            {toast.element}
            <div className="grid grid-cols-1 gap-y-3">
              {currentpwd}
              {newpwd}
              {reqElements}
              {confirmpwd}
            </div>
            <Button
              type="submit"
              content="Update Password"
              loading={resetMutation.isLoading}
              disabled={!isBelowThreshold}
            />
          </>
        )}
        <IconButton
          icon={<LeftIcon />}
          position="left"
          label="Back to log in"
          onClick={onBack}
        />
      </fieldset>
    </form>
  );
};
