import "../assets/css/toggle.css";

const ToggleSwitch = ({ allowed, disabled, rounded, id, label, onSwitch }) => {
  const handleChange = () => {
    if (!disabled && onSwitch) onSwitch(!allowed);
  };

  const box = rounded ? "" : "-box";
  const currId = label || id;

  return (
    <div className="toggle-container" aria-disabled={disabled}>
      <span className="tracking-tight">{label}</span>
      <div className={`toggle-switch${box}`}>
        <input
          type="checkbox"
          className="toggle-checkbox"
          checked={allowed}
          name={label}
          id={currId}
          disabled={disabled}
          onChange={handleChange}
        />
        <label className={`toggle-label${box}`} htmlFor={currId}>
          <span className="inner before:content-['YES'] after:content-['NO']" />
          <span className={`switch${box}`} />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
