import { useEffect, useMemo, useRef } from "react";

/**
 * Custom hook that creates an interval to invoke a callback function
 * at a specified delay.
 *
 * Note: Use `null` to clear the interval.
 *
 * @param {*} callback - the function to be invoked at each interval.
 * @param {*} delay - The time, in seconds, between each invoation fo the callback.
 */
const useInterval = (callback, delay) => {
  const savedCallback = useRef(callback);

  const delayInmsecs = useMemo(
    () => (delay === null ? null : delay * 1000),
    [delay]
  );

  // set up the interval
  useEffect(() => {
    // Do not schedule if no delay is specified.
    // However, a delay of `0` is valid value.
    if (delayInmsecs === null) return;

    const id = setInterval(() => {
      savedCallback.current();
    }, delayInmsecs);

    return () => clearInterval(id);
  }, [delayInmsecs]);
};

export default useInterval;
