import { GoSync } from "react-icons/go";
import { VscBlank } from "react-icons/vsc";

export const IconButton = ({ loading, sx, ...props }) => {
  const { icon, label, position, type, ...rest } = props;
  const currClass = position === "left" ? "flex-ij space-x-1" : "flex-i-col";
  return (
    <button type={type || "button"} className="btn-icon" {...rest} style={sx}>
      <div>
        {loading ? (
          <GoSync className="anime" />
        ) : (
          <div className={`${currClass} text-primary hover:text-primarydark`}>
            {icon}
            {label && <span>{label}</span>}
          </div>
        )}
      </div>
    </button>
  );
};

export const SimpleButton = ({
  content,
  children,
  className,
  sx,
  ...props
}) => {
  return (
    <button
      type="button"
      className={className}
      {...props}
      style={{ ...sx, outlineStyle: "none" }}
    >
      {content || children || <VscBlank />}
    </button>
  );
};

const Button = ({ content, children, icon, sx, ...props }) => {
  const { type, loading, disabled, outline, light, circle, danger, ...rest } =
    props;
  const currIcon = icon ? (
    <span className="mx-1">
      <icon.icon size={icon.size} />
    </span>
  ) : null;

  const renderContent = loading ? (
    <div className="flex">
      <span className="mr-1">
        <GoSync className="animate-spin" />
      </span>
      {content || children}
    </div>
  ) : (
    <div>
      {icon && (!icon.position || icon.position === "left") && currIcon}
      {content || children}
      {icon && icon.position === "right" && currIcon}
    </div>
  );

  const btnClass = [];
  const style = circle
    ? "circle"
    : danger
    ? "danger"
    : light
    ? "btn-light"
    : "btn-primary";

  btnClass.push(style);
  if (outline) btnClass.push("outline");

  return (
    <button
      type={type || "button"}
      className={btnClass.join(" ")}
      style={sx}
      disabled={loading || disabled}
      {...rest}
    >
      {renderContent}
    </button>
  );
};

export default Button;
