import { Outlet } from "react-router-dom";
import { Breadcrumb } from ".";

const Layout = () => {
  return (
    <div className="outlet">
      <Breadcrumb />
      <Outlet />
    </div>
  );
};

export default Layout;
