import { Button } from "../../components";
import { useCommon, useSidebar } from "../../store/hooks";
import { DestinationCard, ConnectorsList } from "./DestinationCard";
import { ExchangeCard } from "./ExchangeCard";
import { ServiceCard } from "./ServiceCard";

const Dashboard = () => {
  const {
    servers: { stats },
    destinations,
    hospitals,
    services,
    exchangeTypes,
  } = useCommon();
  const { onNavigate } = useSidebar();

  let totalChannels = stats.reduce((sum, s) => {
    sum += s.value;
    return sum;
  }, 0);

  const feedCount = (exchangeTypes || []).reduce((sum, e) => {
    sum += e.value;
    return sum;
  }, 0);

  return (
    <div className="dashboard">
      <div className="grid grid-cols-1 py-4 leading-tight">
        <span className="h8">Hi, welcome!</span>
        <span>Your Enterprise Interface Inventory Repository.</span>
      </div>

      <div className="dashboard-panel">
        <div className="container">
          <div className="h-full grid grid-cols-1 content-between">
            <div>
              <p className="text-[16px] text-neutral-700 font-semibold">
                Total Channels
              </p>
              <div className="description">
                <span>100%</span>
                <span className="ml-1 text-neutral-500">
                  across {stats?.length} Mirth Server
                </span>
              </div>
            </div>
            <div className="flexib mr-8">
              <p className="count">{totalChannels}</p>
              <Button
                outline
                content="View Channels"
                onClick={() => onNavigate("/repo/channels")}
              />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="h-full grid grid-cols-1 content-between">
            <div>
              <p className="text-[16px] text-neutral-700 font-semibold">
                DIS Interfaces
              </p>
              <p className="description text-neutral-500">
                The total number of inbound and outbound feeds
              </p>
            </div>
            <div className="flexib mr-8">
              <span className="count">{feedCount}</span>
              <Button
                outline
                content="View Interfaces"
                onClick={() => onNavigate("/repo/services")}
              />
            </div>
          </div>
        </div>

        <DestinationCard data={destinations} />

        <ConnectorsList data={destinations} />
      </div>

      <div className="flex space-x-4 h-full">
        <ExchangeCard
          servers={stats}
          data={exchangeTypes || []}
          feeds={feedCount}
          hospitals={hospitals}
          onNavigate={() => onNavigate("/repo/reports")}
        />
        <ServiceCard data={services} />
      </div>
    </div>
  );
};

export default Dashboard;
