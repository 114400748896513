import { memo } from "react";
import { TabContent, Table, Tabs } from "../../components";
import { useSortableData } from "../../store/hooks";

export const StatsInfo = memo(({ logs }) => {
  const { dataSorted, headerConfig } = useSortableData(logs, statsConfig);

  return (
    <Tabs>
      <TabContent label="Analysis">
        <Table
          loose
          dataSource={dataSorted}
          config={headerConfig}
          sx={{ maxHeight: 720 }}
        />
      </TabContent>
    </Tabs>
  );
});

const statsConfig = [
  {
    key: "SEQ",
    render: () => null,
    // render: (log) => log.index + 1,
    // align: "text-center",
    // width: 64,
  },
  {
    label: "Code",
    render: (log) => log.code,
    sortValue: (log) => log.code,
  },
  {
    label: "Field",
    render: (log) => log.selector,
    sortValue: (log) => log.selector,
  },
  {
    label: "Description",
    render: (log) => (
      <span className="whitespace-nowrap">{log.description}</span>
    ),
  },
  {
    label: "Usage",
    render: (log) =>
      log.usage && (
        <div className="flex-ij">
          <p className="px-2 pt-0.5 w-6 h-6 rounded-sm border border-slate-600 bg-slate-100 text-xs flex-ij">
            {log.usage}
          </p>
        </div>
      ),
    sortValue: (log) => log.usage,
    align: "text-center",
  },
  {
    label: "Audit",
    render: (log) => log.audit,
  },
  {
    label: "Sample",
    render: (log) => (
      <p
        title={log.sample.length > 20 ? log.sample : null}
        className="truncate w-[140px]"
      >
        {log.sample}
      </p>
    ),
    width: 140,
  },
  {
    label: "Control ID",
    render: (log) => log.cid,
  },
  {
    label: "Count",
    render: (log) => log.count,
    sortValue: (log) => log.count,
    align: "text-center",
    width: 84,
  },
];
