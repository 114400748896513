import { Checkbox } from "../components";

const PasswordGen = ({ value, onPolicyChange }) => {

  const handlePolicyChange = (event) => {
    const { value, checked } = event.target;
    onPolicyChange(value, checked);
  };

  return (
    <div className="pwgen">
      <span>{value}</span>
      <div className="flex space-x-3">
        <Checkbox
          id="character-chk"
          value="special"
          label="Special Characters"
          onChange={handlePolicyChange}
        />
        <Checkbox
          id="numbers-chk"
          value="numbers"
          label="Numbers"
          onChange={handlePolicyChange}
        />
      </div>
    </div>
  );
};

export default PasswordGen;
