import { memo, useCallback, useMemo, useState } from "react";
import { HiOutlineDotsHorizontal as Icon } from "react-icons/hi";
import {
  Dropdown,
  IconButton,
  InputField,
  Option,
  Table,
} from "../../components";
import { useCommon, useDebounce, useSortableData } from "../../store/hooks";
import SearchPaginate from "../SearchPaginate";
import InterfaceSummary from "./InterfaceSummary";

const HL7Avatar = ({ code }) => {
  return (
    <div className="hl7-avatar">
      <span className="container">
        <span>{code}</span>
      </span>
    </div>
  );
};

export const InterfaceList = memo(
  ({ loading, services, filters, onTypeChange, onNameChange, onPaginate }) => {
    const [serviceId, setServiceId] = useState(null);
    const { exchangeTypes } = useCommon();
    const [setDebounce] = useDebounce(500, null, onNameChange);

    const handleTermChange = useCallback(
      ({ target }) => {
        setDebounce(target.value);
      },
      [setDebounce]
    );

    const handleClose = useCallback((serviceId = null) => {
      setServiceId(serviceId);
    }, []);

    const serviceConfig = useMemo(
      () => [
        {
          key: "code",
          render: (entry) => <HL7Avatar code={entry.hl7} />,
        },
        {
          label: "Source Channel Feed",
          render: (entry) => entry.source,
          sortValue: (entry) => entry.source,
          filterValue: (
            <InputField
              id="serv-search"
              outline
              required
              placeholder="Search by name"
              defaultValue={filters.name}
              onChange={handleTermChange}
            />
          ),
        },
        {
          label: "Direction",
          render: (entry) => (
            <span className="pr-3">{entry.direction || "Inbound"}</span>
          ),
        },
        {
          label: "Exchange Type",
          render: (entry) => (
            <div className="py-1 leading-tight">
              <span className="text-xs text-slate-500">{entry.dataformat}</span>
              <p>{entry.exchange}</p>
            </div>
          ),
          filterValue: (
            <Dropdown
              outline
              clearButton
              id="serv-exchange"
              width={160}
              value={filters.exchange}
              onChange={onTypeChange}
            >
              {exchangeTypes.map((e) => (
                <Option key={e.shortname} value={e.type} />
              ))}
            </Dropdown>
          ),
        },
        {
          label: "Health System",
          render: (entry) => entry.healthsystem,
          sortValue: (entry) => entry.healthsystem,
        },
        {
          key: "serviceView",
          render: (entry) => (
            <IconButton
              title="View Interface Summary"
              icon={<Icon size={18} />}
              sx={{ paddingRight: 10 }}
              onClick={() => handleClose(entry.id)}
            />
          ),
        },
      ],
      [filters, exchangeTypes, handleClose, handleTermChange, onTypeChange]
    );

    const { entry, total } = services;
    const { results } = entry || {};
    const { dataSorted, headerConfig } = useSortableData(
      results,
      serviceConfig
    );

    return (
      <div className="w-full">
        {serviceId ? (
          <InterfaceSummary serviceId={serviceId} onClose={handleClose} />
        ) : (
          <>
            <Table
              loading={loading}
              dataSource={dataSorted}
              config={headerConfig}
            />
            {!filters.curr && (
              <SearchPaginate
                onChange={(type, payload) => onPaginate(type, payload)}
                totalCount={total}
                pageSize={filters.limit || 24}
                page={filters.page || 1}
              />
            )}
          </>
        )}
      </div>
    );
  }
);
