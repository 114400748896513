import { useQueryClient } from "@tanstack/react-query";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsBoxArrowInRight as CheckIcon } from "react-icons/bs";
import { MdOutlineCodeOff as TermedIcon } from "react-icons/md";
import {
  TbArrowBackUp as BackIcon,
  TbCards as LocationIcon,
} from "react-icons/tb";
import {
  Button,
  Divider,
  InputField,
  Panel,
  PanelBody,
  RadioGroup,
  Skeleton,
  TabContent,
  Table,
  Tabs,
} from "../../components";
import { useAxiosPrivate, useDagre, useSortableData } from "../../store/hooks";
import { SummaryHeader } from "./SummaryHeader";

const position = { x: 0, y: 0 };
const edgeType = "";

const InterfaceSummary = ({ serviceId, onClose }) => {
  const [service, setService] = useState(null);
  const isMounted = useRef(false);
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;

    const fetchService = async () => {
      const { data } = await queryClient.fetchQuery({
        queryKey: ["servers", { serviceId }],
        queryFn: async () => await axiosPrivate(`/mirth/services/${serviceId}`),
      });

      setService(data);
    };

    void fetchService();
    // eslint-disable-next-line
  }, [serviceId]);

  const createNodes = useCallback((origin, endpoint) => {
    if (!endpoint) return [[], []];

    const nodes = [];
    const edges = [];
    const [firstEl, ...rest] = endpoint;

    const firstNode = {
      id: firstEl.node,
      type: "input",
      data: { label: firstEl.node },
      style: {
        width: "auto",
      },
    };

    if (origin) {
      firstNode.type = "default";
      const originNode = {
        id: origin.name || "origin",
        type: "origin",
        data: { label: origin.name },
        style: {
          width: "auto",
        },
      };

      nodes.push(originNode);
      nodes.push(firstNode);
      edges.push({
        id: `${originNode.id}-${firstNode.id}`,
        source: origin.name,
        target: firstEl.node,
        type: edgeType,
        label: firstEl.connector,
      });
    } else nodes.push(firstNode);

    rest.forEach((neighbor) => {
      let animated = false;
      const style = {
        color: "#333",
        border: "1px solid #222138",
        width: "auto",
      };

      const label = neighbor.node
        .replace("COMMON_SERVICES", "CS")
        .replace("EVHC_SOURCE_MSG", "ESM");

      const currNode = {
        id: neighbor.node,
        data: { label },
        type: neighbor.isLeaf ? "output" : "default",
        style,
        position,
      };

      if (label.toLowerCase().includes("mirth")) {
        style.background = "#D6D5E6";
        animated = true;
      }

      nodes.push(currNode);
      edges.push({
        id: `${neighbor.node}-${neighbor.partof || firstNode.id}`,
        source: neighbor.partof || firstNode.id,
        target: neighbor.node,
        type: edgeType,
        animated,
      });
    });

    return [nodes, edges];
  }, []);

  const flowR = useDagre(
    service?.managingOrg,
    service?.endpoint,
    createNodes,
    "TB",
    false
  );
  const { dataSorted, headerConfig } = useSortableData(
    service?.location || [],
    orgConfig
  );

  const directionGroups = useMemo(
    () => [
      { value: "inbound", label: "Inbound" },
      { value: "outbound", label: "Outbound" },
      { value: "bi-directional", label: "Bi-Directional" },
    ],
    []
  );

  if (service === null || service === undefined)
    return <Skeleton list={{ rows: 5, cols: 4 }} />;

  const { managingOrg, category, identifier, provision } = service || {};

  const renderExtra = Object.entries({
    ctr: service.active ? "Active" : "Inactive",
    df: `${provision.dataformat} ${category?.hl7}`,
    dx: category?.name,
    hl7: provision?.transport,
  }).map(([k, v]) => (
    <li key={k} className="flex space-x-3 space-y-1 items-center">
      <CheckIcon size={18} />
      <span>{v}</span>
    </li>
  ));

  return (
    <div className="grid grid-cols-1 gap-y-3 w-full">
      <Panel>
        <PanelBody>
          <form>
            <div className="flex items-center justify-start font-semibold">
              <Button outline onClick={() => onClose()}>
                <div className="text-primary tracking-tight flex space-x-1 items-center mb-3">
                  <BackIcon size={18} />
                  <span>back to list</span>
                </div>
              </Button>
            </div>

            <div className="grid grid-cols-1 mr-5 mb-3 space-y-2">
              <div className="flex space-x-3">
                <span className="flex justify-end w-[15%] mt-2 tracking-tight">
                  Data Entry Point:
                </span>
                <div className="w-[85%]">
                  <div className="space-y-2">
                    <div className="flex space-x-2">
                      {createControl(
                        "channel",
                        service?.name,
                        "Channel Name",
                        360
                      )}
                      {createControl(
                        "uuid",
                        identifier?.uuid,
                        "Channel UUID",
                        340
                      )}
                    </div>
                    <div className="flex space-x-2">
                      {createControl(
                        "server",
                        provision?.server,
                        "EVPS Mirth Server",
                        360
                      )}
                      {createControl(
                        "connector",
                        service?.type,
                        "Connector Type",
                        200
                      )}
                      {createControl("port", provision?.port, "Port", 100)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex space-x-3 my-2 py-2">
                <span className="flex justify-end w-[15%] items-center tracking-tight">
                  Health System:
                </span>
                <div className="w-[85%]">
                  {createControl("hs", service?.healthsystem, null, 360, false)}
                </div>
              </div>
              <div className="flex space-x-3 mt-2">
                <span className="flex justify-end w-[15%] items-center tracking-tight">
                  Facility HIS:
                </span>
                <div className="w-[85%]">
                  {createControl("his", "", null, 360, false)}
                </div>
              </div>
              <div className="flex space-x-3">
                <span className="w-[15%]"></span>
                <div className="w-[70%]">
                  <div className="flex space-x-3 my-2">
                    <div className="space-y-4">
                      <div className="flex flex-nowrap space-x-3">
                        <span className="mt-2 w-2/5 flex justify-end tracking-tight">
                          Downstream System:
                        </span>
                        <div className="w-3/5">
                          <InputField disabled id="ds" width={216} />
                        </div>
                      </div>
                      <div className="flex flex-nowrap space-x-3">
                        <span className="mt-2 w-2/5 flex justify-end tracking-tight">
                          Vendor:
                        </span>
                        <div className="w-3/5">
                          {createControl("vendor", "", null, 216, false)}
                        </div>
                      </div>
                      <div className="flex flex-nowrap space-x-3 w-full mr-5 pr-5">
                        <span className="w-2/5 flex justify-end tracking-tight">
                          Direction:
                        </span>
                        <div className="w-3/5">
                          <RadioGroup
                            disabled
                            name="direction"
                            selectedValue="inbound"
                            group={directionGroups}
                          />
                        </div>
                      </div>
                    </div>

                    <Divider sx={{ marginLeft: 20 }} />

                    <ol>{renderExtra}</ol>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </PanelBody>
      </Panel>

      <Tabs>
        <TabContent label="Managing Organization">
          {managingOrg && (
            <>
              <SummaryHeader org={managingOrg} locations={service.location} />
              <Table
                dataSource={dataSorted}
                config={headerConfig}
                sx={{ maxHeight: "480px" }}
              />
            </>
          )}
        </TabContent>
        <TabContent label="Flowchart">
          <div className="service-flow">{flowR}</div>
        </TabContent>
        <TabContent label="Change History" />
      </Tabs>
    </div>
  );
};

function createControl(id, value, text, size, required = true) {
  return (
    <InputField
      disabled
      required={required}
      id={id}
      helperText={text}
      value={value || ""}
      width={size}
    />
  );
}

const orgConfig = [
  {
    key: "icon",
    render: (_) => (
      <div className="py-2.5 my-1.5 ml-2.5 flex-ij w-6 h-6">
        <LocationIcon size={16} />
      </div>
    ),
  },
  {
    label: "Hospital (facility)",
    render: (location) => location.name,
  },
  {
    label: "Facility ecode",
    render: (location) => location.ecode,
  },
  {
    label: "COCID",
    render: (location) => location.cocid,
  },
  {
    label: "Service Line(s)",
    render: (location) => {
      const notes = location.specialty.map((s, i) => {
        let note = s.display;
        const key = `${s.display}.${i}`;

        if (s.period.end) {
          note += ` (terminated ${s.period.end})`;
          return (
            <li className="flex items-center space-x-2" key={key}>
              <span>
                <TermedIcon size={14} color="#CC0000" />
              </span>
              <span>{note}</span>
            </li>
          );
        }

        return (
          <li className="ml-0.5" key={key}>
            {note}
          </li>
        );
      });

      return <ol className="m-1 list-disc list-inside">{notes}</ol>;
    },
  },
  {
    label: "Decommissioned",
    render: () => null,
  },
];

export default memo(InterfaceSummary);
