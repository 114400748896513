import { useCallback, useReducer } from "react";
import { PasswordRequirement } from "../../form/PasswordRequirement";
import {
  lowerCase,
  minLength,
  number,
  specialChar,
  upperCase,
  invalidateChar,
} from "../../form/validation-rules";

const isEmpty = (valueObj) => {
  for (let i in valueObj) return false;
  return true;
};

const initialState = { checks: {} };

const validationRules = [
  minLength(6, false),
  lowerCase(),
  upperCase(),
  specialChar(),
  number(),
];

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "checks":
      return { ...state, checks: { ...payload } };
    case "err":
      return { ...state, error: payload };
    case "reset":
      return initialState;
    case "pwdValue":
    case "confirmValue":
      return { ...state, [type]: payload };
    default:
      return state;
  }
};

const useRequirements = (sx) => {
  const [states, dispatch] = useReducer(reducer, initialState);
  const { confirmValue, checks } = states;

  const satisfied = !isEmpty(checks) && Object.values(checks).every(Boolean);

  const handleValidation = useCallback((_, value) => {
    const payload = {};
    for (let rule of validationRules) payload[rule.name] = rule.validate(value);
    dispatch({ type: "checks", payload });
  }, []);

  const handleBlur = useCallback((control, value) => {
    dispatch({ type: control, payload: value });
  }, []);

  const handleClear = useCallback(() => {
    dispatch({ type: "reset" });
  }, []);

  const handleVerify = useCallback(
    (isFormValid, pwd, sameValue) => {
      return !isFormValid
        ? "Incorrect or incomplete form values."
        : !satisfied
        ? "New password does not meet Password Criteria. Please try again!"
        : !sameValue
        ? "New and Confirm passwords do not match. Please try again!"
        : !invalidateChar(pwd)
        ? "The new password contains invalid characters."
        : null;
    },
    [satisfied]
  );

  return {
    element: <PasswordRequirement sx={sx} checks={checks} />,
    handleBlur,
    handleClear,
    handleValidation,
    handleVerify,
    confirmpwd: confirmValue,
    satisfied,
  };
};

export default useRequirements;
