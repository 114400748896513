import { useReducer } from "react";
import { Panel, PanelHeader } from "../../components";
import ChannelList from "./ChannelList";
import ChannelsChart from "./ChannelsChart";
import { ChannelFilter } from "./ChannelFilter";
import { MdToll as ChannelsIcon } from "react-icons/md";

const initialFilter = {
  connectors: {},
  servers: {},
  status: "All",
  limit: 24,
  page: 1,
};

const filterReducer = (state, { type, payload }) => {
  switch (type) {
    case "connector":
      const { connectors, ...rest } = state;
      return {
        ...rest,
        connectors: {
          ...connectors,
          [payload]: !connectors[payload],
        },
      };
    case "name":
    case "page":
    case "status":
      return { ...state, [type]: payload };
    case "limit":
      if (payload === undefined) return state;
      const { totalCount, limit } = payload;
      const lastExpected = Math.ceil(totalCount / limit);
      const curr = { ...state, limit };

      if (state.page > lastExpected) curr.page = lastExpected;
      return curr;
    case "server":
      const { servers, ...noServers } = state;
      return {
        ...noServers,
        servers: {
          ...servers,
          [payload]: !servers[payload],
        },
      };
    case "clear":
      return initialFilter;
    default:
      return state;
  }
};

const Channels = () => {
  const [filters, dispatch] = useReducer(filterReducer, initialFilter);

  return (
    <div className="flex flex-col space-y-3">
      <Panel>
        <PanelHeader
          PanelIcon={ChannelsIcon}
          title="Mirth Channels"
          subtitle="Connectors"
        />
      </Panel>

      <ChannelsChart />

      <div className="flex space-x-3">
        <div className="w-[75%]">
          <ChannelList filters={filters} dispatchSearch={dispatch} />
        </div>
        <div className="w-[25%] mt-2">
          <ChannelFilter dispatch={dispatch} filters={filters} />
        </div>
      </div>
    </div>
  );
};

export default Channels;
