const DropContainer = ({ containerId, children, isOpen }) => {
  return (
    <div
      id={containerId}
      className={
        "drop-container transition-opacity duration-200 " +
        (isOpen ? "opacity-100 ease-in`" : "opacity-0 ease-out hidden")
      }
    >
      {children}
    </div>
  );
};

export default DropContainer;
