import { useCallback, useState } from "react";
import { PiCheckCircleDuotone as CheckIcon } from "react-icons/pi";
import { RxCircle as UncheckIcon } from "react-icons/rx";
import {
  Button,
  Card,
  CircularProgressbar,
  Divider,
  FileBrowser,
  LoadSpinner,
} from "../../components";
import { useDrawer, useUpload } from "../../store/hooks";
import { StatsInfo } from "./StatsInfo";
import { ValidateSettings } from "./ValidateSettings";

const MAX_VALUE = 60;
const initialState = { valid: null };
const initialChecks = {
  required: {
    label: "Required fields only",
    description:
      "EIIR may not raise an error due to the presence of a required element, but raise an error due the absence of a required element.",
  },
  standard: {
    label: "Standard codes",
    checked: true,
    description:
      "Check only HL7 defined, table-based (set of) values defined and published by HL7",
  },
  repeating: {
    label: "Repeating fields",
    checked: true,
    description: "Check if element is allowed to repeat",
  },
  maxAllowed: {
    label: "Max characters allowed",
    description:
      "The length of the field within a segment shall accurately reflect the maximum allowed length for the field within this segment definition",
  },
  custom: { label: "Ignore custom segment" },
};

function createTreeItem(key, label, checked) {
  return (
    <li key={`option-${key}`} className="flex-i space-x-1">
      {checked ? <CheckIcon size={20} /> : <UncheckIcon size={18} />}
      <Divider />
      <span>{label}</span>
    </li>
  );
}

export const HL7Analysis = () => {
  const [{ messages, logs }, setReport] = useState(initialState);
  const [options, setOptions] = useState(initialChecks);
  const [dialog, setDialog] = useState({});

  const { drawer, showWindow, closeWindow } = useDrawer(
    "optionportal",
    dialog,
    () => setDialog({})
  );

  const { loading, analyze } = useUpload(
    "/conformance",
    useCallback((data) => setReport(data), [])
  );

  const handleClearReport = useCallback(() => setReport(initialState), []);

  const handleCancel = useCallback(() => {
    setDialog({});
    closeWindow();
  }, [closeWindow]);

  const updateOptions = useCallback(
    (checks) => {
      const currOptions = {};
      for (let [id, option] of Object.entries(initialChecks)) {
        option.checked = checks[id];
        currOptions[id] = option;
      }

      setOptions(currOptions);
      handleCancel();
    },
    [handleCancel]
  );

  const handleEditOptions = useCallback(() => {
    setDialog({
      title: "General Configuration",
      content: (
        <ValidateSettings
          items={options}
          onSave={updateOptions}
          onCancel={handleCancel}
        />
      ),
    });
    showWindow();
  }, [showWindow, handleCancel, updateOptions, options]);

  const handleUpload = useCallback(
    (files) => {
      const checks = {};
      for (let [k, v] of Object.entries(options)) checks[k] = !!v.checked;
      analyze(files, checks);
    },
    [options, analyze]
  );

  // const total = validCount === null ? validCount / 2 : "";
  const { total, valid } = messages || { valid: 0 };
  const value = Math.round((10000 * valid) / (total || 1)) / 100;

  return (
    <div className="w-full">
      <main className="flex space-x-2">
        <section className="w-3/5">
          <FileBrowser
            sx={{ height: 240 }}
            onUpload={handleUpload}
            onRemove={handleClearReport}
          />
        </section>

        <section className="w-1/5">
          <Card
            sx={{
              marginTop: 8,
              height: 240,
              alignContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ul className="text-[0.92em] tracking-tight text-neutral-700 divide-y p-2 py-1.5 mb-0">
              {Object.entries(options).map(([key, { label, checked }]) =>
                createTreeItem(key, label, checked)
              )}
            </ul>

            <Button
              outline
              content="Edit Options"
              onClick={handleEditOptions}
            />
          </Card>
        </section>

        <section className="w-1/5">
          <Card sx={{ marginTop: 8, height: 240 }}>
            <div className="grid grid-cols-1 h-full content-between p-1">
              <div className="text-center h-1/5">
                <span className="text-sm text-neutral-600 font-semibold">
                  HL7 Validation
                </span>
              </div>
              <div className="h-3/5 flex-j">
                {loading ? (
                  <LoadSpinner large />
                ) : (
                  <CircularProgressbar
                    radius={50}
                    selectedValue={value}
                    maxValue={MAX_VALUE}
                    withGradient
                  />
                )}
              </div>
              <div className="text-[14px] tracking-tight leading-tight text-center mx-4 px-4 h-1/5">
                {/* bottom side */}
                <p className="text-neutral-600 text-lg">
                  {valid} of {total}
                </p>
                messages found to be conformant.
              </div>
            </div>
          </Card>
        </section>
      </main>

      <StatsInfo logs={logs} />

      {drawer}
    </div>
  );
};
