import { memo } from "react";
import {
  MdCheckCircleOutline as CheckedIcon,
  MdRadioButtonUnchecked as UncheckedIcon,
} from "react-icons/md";

const passwordLabels = {
  minLength: "Minimum six characters",
  lowerCase: "At least one lowercase character",
  upperCase: "At least one uppercase character",
  specialChar: "At least one special character !@#$%^&*",
  number: "At least one number",
};

export const PasswordRequirement = memo(({ checks, sx }) => {
  const requirements = Object.entries(passwordLabels).map(([key, label]) => (
    <li key={key} className="flex items-center space-x-2">
      <span>
        {checks[key] ? (
          <CheckedIcon color="green" size={18} />
        ) : (
          <UncheckedIcon color="gray" size={18} />
        )}
      </span>
      <span className={checks[key] ? "text-neutral-400" : null}>{label}</span>
    </li>
  ));

  return (
    <ul className="text-sm whitespace-nowrap space-y-0.5" style={sx}>
      {requirements}
    </ul>
  );
});
