import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../store/hooks";

const parseJWT = (accessToken) => {
  try {
    const jwtDecoded = atob(accessToken.split(".")[1]);
    return JSON.parse(jwtDecoded);
  } catch ({ response }) {
    if (response.status === 403) console.log(response.data);
    return {};
  }
};

const verifyJWT = (token) => {
  if (!token) return false;

  const jwt = parseJWT(token.accessToken);
  const now = Math.ceil(Date.now() / 1000);
  return jwt.exp > now;
};

/**
 * Check if JWT token every time the Route changes
 * and call the logOut event if token is expired.
 *
 * If the route is allowed and the user is authenticated, then move to the Outlet.
 * Otherwise, if the route is not allowed, and the user is authenticated,
 * then access is not authorized. In any other event, naviate to the login page.
 *
 */
const RequireAuth = ({ allowedRank = 1 }) => {
  const location = useLocation();
  const { auth } = useAuth();
  const { token, rank, loggedOut } = auth || {};

  // If logging out
  // just exit to the Login page
  if (loggedOut) return <Navigate to="/" state={{ from: location }} replace />;

  if (token === undefined) return;

  // Verify user is authenticated and authorized
  if (rank >= allowedRank) {
    if (verifyJWT(token)) return <Outlet />;
  } else
    return (
      <Navigate to="/repo/unauthorized" state={{ from: location }} replace />
    );

  return <Navigate to="/expired" state={{ from: location }} replace />;
};

export default RequireAuth;
