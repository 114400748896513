import { memo, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Button, Divider, Toast } from "../../components";
import {
  MdOutlineCheckCircleOutline as CheckIcon,
  MdOutlineRemoveDone as RemoveIcon,
  MdNavigateNext as NextIcon,
} from "react-icons/md";
import { BsPatchCheck as LimitedIcon } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Urls } from "../../store/axios";

export const ReviewCreate = memo(({ values, api, onPrevious, onCreate }) => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const newUserMutation = useMutation({
    mutationFn: async (newUser) => await api.post(Urls.users, newUser),
    onSuccess: ({ data }) => onCreate(data),
    onError: ({ response }) => {
      const { status } = response || {};
      if (status === 403) navigate("/expired", { replace: true });
      else if (status === 500) setError("Internal Server Error (500)");
    },
  });

  const [account, role] = [values["0"] || {}, values["1"] || {}];
  const reviews = useMemo(
    () => [
      {
        key: "acct-review",
        label: "Display & Username",
        content: (
          <>
            <p>{account.display}</p>
            <p>{account.username}</p>
          </>
        ),
      },
      {
        key: "role-review",
        label: "Role",
        content: <p>{role.role}</p>,
      },
      {
        key: "dept-review",
        label: "Department",
        content: <p>{account.department}</p>,
      },
    ],
    [account, role]
  );

  const tags = Object.values(role.permissions || {}).reduce(
    (reduceObj, { category, allowed }) => {
      const curr = reduceObj[category] || { allowed: 0, denied: 0 };
      if (allowed) curr.allowed++;
      else curr.denied++;
      reduceObj[category] = curr;
      return reduceObj;
    },
    {}
  );

  if (!role) return;

  const handleCreate = async () => {
    const payload = {
      identifiers: [
        {
          type: "CAI",
          "require-change": account.reset,
          value: {
            username: account.username,
            password: null,
            temp_pwd: account.generatedPwd,
          },
        },
      ],
      name: {
        family: account.family,
        given: [account.given],
        display: account.display,
      },
      gender: "unknown",
      login: {
        "access-type": "Repo Auth",
        status: "Unlocked",
      },
      telecom: [
        { system: "phone", value: account.phone },
        { system: "email", value: account.email },
      ],
      status: "A",
      role: role.role,
      policies: [],
      permissions: Object.entries(role.permissions).map(([key, action]) => ({
        id: key,
        allowed: action.allowed,
      })),
      manager: {
        department: account.department,
      },
    };

    newUserMutation.mutate(payload);
  };

  return (
    <section className="drawer-section">
      <div className="review">
        <p className="title">
          Almost done! Review all the info for this user before adding.
        </p>
        {error && (
          <div className="toast">
            <Toast warning message={error} />
          </div>
        )}
        <div className="grid grid-cols-2 gap-3 my-10">
          <ol className="mx-5 tracking-tight leading-tight flex flex-col space-y-5">
            {reviews.map((review) => {
              return (
                <li key={review.key} className="text-end">
                  <p className="font-semibold text-[15px]">{review.label}</p>
                  {review.content}
                </li>
              );
            })}
          </ol>
          <div>
            <p className="font-semibold mb-1 text-[15px]">Permissions</p>

            <ul className="flex flex-col space-y-1.5 list-disc">
              {Object.entries(tags).map(([tag, count]) => {
                const { allowed, denied } = count;
                const icon = allowed ? (
                  denied ? (
                    <LimitedIcon size={18} color="#CC5500" />
                  ) : (
                    <CheckIcon size={18} color="green" />
                  )
                ) : (
                  <RemoveIcon size={18} color="gray" />
                );

                return (
                  <li key={tag} className="flex items-center space-x-1">
                    {icon}
                    <span>{tag}</span>
                    <span className="text-secondary tracking-normal pl-2">
                      ({count.allowed}/{count.allowed + count.denied})
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="flexj space-x-2 mb-5">
        <Button onClick={onPrevious}>
          <div className="stepbtn">
            <span className="-scale-x-100">
              <NextIcon size={20} />
            </span>
            <span className="label pr-1">PREVIOUS</span>
          </div>
        </Button>
        <Divider />
        <Button onClick={handleCreate} loading={newUserMutation.isLoading}>
          <div className="stepbtn">
            <span className="label pl-0.5">CREATE USER</span>
          </div>
        </Button>
      </div>
    </section>
  );
});
