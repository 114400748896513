import { Handle, Position } from "reactflow";
import { PiMediumLogoDuotone as OriginIcon } from "react-icons/pi";

function CustomNode(node) {
  const { id, data } = node;

  return (
    <div className="border border-dashed border-neutral-700 p-5 h-[36px] rounded flex-ij text-xs bg-neutral-100">
      <div className="flex-i space-x-1">
        <span>
          <OriginIcon size={22} />
        </span>
        <span>{data.label}</span>
      </div>
      <Handle type="source" position={Position.Bottom} id={id} />
    </div>
  );
}

export default CustomNode;
