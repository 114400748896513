import { useCallback, useState } from "react";
import { MdOutlineInventory as AnalysisIcon } from "react-icons/md";
import "../../assets/css/tree.css";
import { Button, Divider, Panel, PanelHeader } from "../../components";
import { HL7Analysis } from "./HL7Analysis";
import { MessageOverview } from "./MessageOverview";

const Analysis = () => {
  const [isOverview, setIsOverview] = useState(true);

  const handleOverview = useCallback((state) => setIsOverview(state), []);

  return (
    <div className="w-full space-y-4">
      <Panel>
        <PanelHeader
          PanelIcon={AnalysisIcon}
          subtitle="Message Profiles"
          title="Conformance"
        />
      </Panel>

      <div className="flex space-x3">
        <Button
          light={!isOverview}
          content="Message Overview"
          onClick={() => handleOverview(true)}
        />
        <Divider sx={{ marginLeft: 12, marginRight: 12 }} />
        <Button
          light={isOverview}
          content="Analysis"
          onClick={() => handleOverview(false)}
        />
      </div>

      {isOverview ? <MessageOverview /> : <HL7Analysis />}
    </div>
  );
};

export default Analysis;
