import { useCallback, useMemo, useState } from "react";
import { BsCloudDownload as ServerIcon } from "react-icons/bs";
import { MdOutlineSettings as SettingsIcon } from "react-icons/md";
import { SiGoogleanalytics as AnalyticsIcon } from "react-icons/si";
import { SlRefresh as RefreshIcon } from "react-icons/sl";
import { BadgeIndicator, IconButton, Indicator } from "../../components";
import { ServerProgress } from "./ServerProgress";
import { useInterval } from "../../store/hooks";

const initialBackOff = { multiplier: 2, value: 1 };

/**
 * Indicator and import status for the Server Manager
 * green ~> the process is complete
 * blue ~> on track or processing
 * yellow ~> at risk
 * @returns the indicator component and
 */
function getIndicator(status) {
  let indicator,
    importing = false;
  if (status === "A") indicator = <BadgeIndicator color="ok" label="Active" />;
  else if (status === "S") {
    importing = true;
    indicator = <BadgeIndicator color="progress" label="Staged" />;
  } else if (status === "R")
    indicator = <BadgeIndicator color="basic" label="Ready" />;
  else indicator = <Indicator color="danger" />;

  return { indicator, importing };
}

const ServerManager = ({ server, onSync, onUpdate }) => {
  const [backOff, setBackOff] = useState(initialBackOff);

  const linkConfig = useMemo(
    () => [
      {
        label: "settings",
        icon: SettingsIcon,
        handler: () => onUpdate(server),
      },
      {
        label: "sync",
        icon: RefreshIcon,
        handler: () => onSync(server.mirthserver_id),
      },
      { label: "analytics", icon: AnalyticsIcon },
      { label: "view logs" },
    ],

    // eslint-disable-next-line
    [onSync]
  );

  const handleCheckStatus = useCallback(() => {
    setBackOff((curr) => {
      let value = null;
      if (0 <= curr.value && curr.value < 4)
        value = curr.value * curr.multiplier;
      return {
        multiplier: curr.multiplier,
        value,
      };
    });
  }, []);

  useInterval(handleCheckStatus, backOff.value);

  const status = getIndicator(server.status);

  const content = status.importing ? (
    <ServerProgress server={server} />
  ) : (
    <div className="server-links">
      {linkConfig
        .map((btn) => {
          const { label, icon: Icon, handler } = btn;
          return (
            handler && (
              <IconButton
                key={`${label}-btn`}
                icon={Icon && <Icon size={24} />}
                label={label}
                onClick={handler}
              />
            )
          );
        })
        .filter(Boolean)}
    </div>
  );

  return (
    <div className="smanager">
      <div className="indicatr">{status.indicator}</div>

      <div className="logo">
        <div className="icon">
          <ServerIcon size={36} color="#FF6F00" />
        </div>
      </div>

      <div className="server">
        <p className="t15-semi-tt">{server.name}</p>
        <p className="sm-text">
          {server.hostname}:{server.port}
        </p>
        <p className="text-sm">{server.dilp}</p>
      </div>

      <div className="details">
        <div>
          <p>{server.ipaddress}</p>
          <span>ip address</span>
        </div>
        <div>
          <p>{server.channels.toLocaleString()}</p>
          <span>channels</span>
        </div>
        <div>
          <p>{server.status === "R" ? "---" : server["last-synced"]}</p>
          <span>last synced</span>
        </div>
      </div>

      {content}
    </div>
  );
};

export default ServerManager;
