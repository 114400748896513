import { useMemo, memo, useEffect, useReducer, useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAxiosPrivate, useDrawer, useSortableData } from "../../store/hooks";
import { IconButton, Skeleton, Table } from "../../components";
import SearchPaginate from "../SearchPaginate";
import { ChannelSummary } from "./ChannelSummary";
import { HiOutlineDotsHorizontal as Icon } from "react-icons/hi";
import { MdGraphicEq as OnIcon, MdCodeOff as OffIcon } from "react-icons/md";
import { Urls, getTerms, removeKey } from "../../store/axios";

const initialState = {};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "curr":
    case "error":
      return { ...state, [type]: payload };
    case "data":
      return { ...state, loading: false, data: payload };
    default:
      return state;
  }
};

const ChannelList = ({ dispatchSearch, filters }) => {
  const [terms, setTerms] = useState({});
  const [{ curr }, dispatch] = useReducer(reducer, initialState);
  const axiosPrivate = useAxiosPrivate();
  const refreshRef = useRef(false);

  const { drawer, showWindow } = useDrawer(
    "channels-portal",
    { content: <ChannelSummary channel={curr} /> },
    () => dispatch({ type: "curr" })
  );

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["channels"],
    queryFn: async () => await axiosPrivate.post(Urls.channels, terms),
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    if (refreshRef.current) return;
    refreshRef.current = true;

    const filterObj = getTerms(filters);
    const terms = removeKey(filterObj, "All");
    setTerms(terms);
  }, [filters]);

  useEffect(() => {
    if (refreshRef.current && Object.keys(terms).length) {
      refetch();
      if (window.scrollY > 300)
        window.scrollTo({ top: 300, behavior: "smooth" });
      refreshRef.current = false;
    }

    // eslint-disable-next-line
  }, [terms]);

  const channelsConfig = useMemo(
    () => [
      {
        key: "activecol",
        render: (channel) =>
          channel.is_enabled ? (
            <div className="flex ml-2">
              <div className="channelcol">
                <OnIcon size={20} />
              </div>
            </div>
          ) : (
            <div className="flex-ij">
              <OffIcon size={20} />
            </div>
          ),
      },
      {
        label: "Channel",
        render: (channel) => (
          <div className="fcoly25">
            <span className="txl1">{channel.uuid}</span>
            <span>{channel.name}</span>
          </div>
        ),
        sortValue: (channel) => channel.name,
      },
      {
        label: "Connector",
        render: (channel) => (
          <div className="fcoly25">
            <span className="txl1">{channel.dataformat.name}</span>
            <span>{channel.source_connector}</span>
          </div>
        ),
      },
      {
        label: "Server",
        render: (channel) => channel.mirthserver.name,
      },
      {
        key: "channelview",
        render: (channel) => (
          <IconButton
            icon={<Icon size={18} />}
            sx={{ paddingRight: 10 }}
            title="open"
            onClick={() => {
              dispatch({ type: "curr", payload: channel });
              showWindow();
            }}
          />
        ),
      },
    ],
    [showWindow, dispatch]
  );

  const { entry, total } = data?.data || {};
  const { results } = entry || {};
  const { dataSorted, headerConfig } = useSortableData(results, channelsConfig);

  return (
    <>
      {isFetching ? (
        <Skeleton list={{ rows: 6, cols: 4 }} />
      ) : (
        <>
          <Table config={headerConfig} dataSource={dataSorted} />
          <SearchPaginate
            onChange={(type, payload) => dispatchSearch({ type, payload })}
            totalCount={total}
            pageSize={filters.limit || 24}
            page={filters.page || 1}
          />
        </>
      )}
      {drawer}
    </>
  );
};

export default memo(ChannelList);
