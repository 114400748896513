import { memo } from "react";
import { MdClose as CloseIcon } from "react-icons/md";
import { DefaultEditor } from "react-simple-wysiwyg";
import { Button, Card, Image, RepoTooltip, Table } from "../../components";
import { useSortableData } from "../../store/hooks";
import DOMPurify from "isomorphic-dompurify";

const pdfContentType = "application/pdf";

function sanitizeHTML(data) {
  const clean = DOMPurify.sanitize(data, { USE_PROFILES: { html: true } });
  return (
    <div
      dangerouslySetInnerHTML={{ __html: clean }}
      className="h-[99%] overflow-y-scroll my-2 p-5"
      id="repo-scroll"
    />
  );
}

function createImage(data) {
  return (
    <div id="repo-scrool" className="w-full h-full overflow-y-scroll">
      <Image src={`data:image/jpeg;base64,${data}`} alt="MR Imaging" />
    </div>
  );
}

function createPDFViewer(data) {
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) out[length] = bytes.charCodeAt(length);

  const blob = new Blob([out], { type: pdfContentType });
  const url = URL.createObjectURL(blob);

  return (
    <object data={url} type={pdfContentType} width="100%" height="100%">
      Medical Record document
    </object>
  );
}

function createRTFViewer(data) {
  return (
    <DefaultEditor
      containerProps={{
        style: { height: "100%", overflow: "auto" },
      }}
      value={data}
      disabled={false}
    />
  );
}

export const DocumentViewer = memo(({ note, data, onClose }) => {
  let content = null;
  content = "XHTML".includes(note.subtype)
    ? sanitizeHTML(data)
    : note.subtype.includes("PDF")
    ? createPDFViewer(data)
    : note.subtype.includes("IMAGE")
    ? createImage(data)
    : note.subtype === "RTF"
    ? createRTFViewer(note.decoded)
    : null;

  const { dataSorted, headerConfig } = useSortableData([note], noteConfig);

  return (
    <div className="border rounded">
      <div className="w-full px-2.5 py-2 border border-b-2 border-gray-300 bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex-i justify-between">
        <Table dataSource={dataSorted} config={headerConfig} />
        <RepoTooltip label="Close document">
          <Button outline onClick={onClose}>
            <CloseIcon size={22} color="gray" />
          </Button>
        </RepoTooltip>
      </div>
      <div className="h-full">
        <Card
          sx={{
            height: "90vh",
            padding: 10,
            paddingRight: 20,
            paddingBottom: 16,
          }}
        >
          {content}
        </Card>
      </div>
    </div>
  );
});

const noteConfig = [
  {
    label: "Filler No.",
    render: (note) => note.filler_no,
  },
  {
    label: "Service ID",
    render: (note) => note.service_id,
  },
  {
    label: "Observer",
    render: (note) => note.observer,
  },
  {
    label: "Observation",
    render: (note) => note.observation_id,
  },
  {
    label: "Subtype",
    render: (note) => note.subtype,
    align: "text-center",
  },
  {
    label: "Encoding",
    render: (note) => note.encoding,
  },
  {
    label: "Status",
    render: (note) => note.status,
    align: "text-center",
  },
];
