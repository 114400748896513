import { BiLogOut } from "react-icons/bi";
import {
  MdSpellcheck as AnalysisIcon,
  MdToll as ChannelsIcon,
  MdOutlineDashboard as DashboardIcon,
  MdMediation as InterfaceIcon,
  MdOutlineSettings as SettingsIcon,
  MdArrowForwardIos as ToggleIcon,
} from "react-icons/md";
import { SiGoogleanalytics as ReportIcon } from "react-icons/si";
import { useLogout, useSidebar } from "../../store/hooks";

const Sidebar = () => {
  const logout = useLogout();
  const { currLink, onNavigate } = useSidebar();

  const comps = links?.map((comp) => {
    const { label, pathname, PathIcon } = comp;
    const linkClass = ["link"];
    if (currLink === pathname) linkClass.push("active");

    return (
      <li key={label} onClick={() => onNavigate(pathname)}>
        <div className={linkClass.join(" ")}>
          <PathIcon className="icon" />
          <span className="text nav-text">{label}</span>
        </div>
      </li>
    );
  });

  return (
    <>
      <nav className="sidebar close">
        <header>
          <div className="image-text">
            <span className="logo-text">EIIR</span>
            <div className="text header-text">
              <span className="project">Interface Repository</span>
            </div>
            <i className="toggle">
              <ToggleIcon />
            </i>
          </div>
        </header>

        <main className="menu-bar">
          <div className="menu">
            <ul className="menu-links">{comps}</ul>
          </div>

          <ul className="bottom-content">
            <li key="logout" onClick={async () => logout()}>
              <div className="link">
                <BiLogOut className="icon" />
              </div>
            </li>
          </ul>
        </main>
      </nav>
    </>
  );
};

export default Sidebar;

const links = [
  {
    label: "Dashboard",
    pathname: "/repo/dashboard",
    PathIcon: DashboardIcon,
  },
  {
    label: "Channels",
    pathname: "/repo/channels",
    PathIcon: ChannelsIcon,
  },
  {
    label: "Interfaces",
    pathname: "/repo/services",
    PathIcon: InterfaceIcon,
  },
  {
    label: "Reports",
    pathname: "/repo/reports",
    PathIcon: ReportIcon,
  },
  {
    label: "Analytics",
    pathname: "/repo/analysis",
    PathIcon: AnalysisIcon,
  },
  {
    label: "Settings",
    pathname: "/repo/settings",
    PathIcon: SettingsIcon,
  },
];
