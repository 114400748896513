import { memo } from "react";
import {
  Dropdown,
  Option,
  Panel,
  PanelFooter,
  PaginationNav,
} from "../components";

const SearchPaginate = ({ totalCount, pageSize, page, onChange }) => {
  return totalCount ? (
    <Panel>
      <div className="py-2">
        <PanelFooter>
          <div className="paginate">
            <div className="page-entry">
              <Dropdown
                id="pagentry"
                top
                outline
                value={pageSize}
                onChange={(limit) => onChange("limit", { limit, totalCount })}
              >
                <Option value={24} />
                <Option value={48} />
              </Dropdown>
              <span className="tsnw tt">/page</span>
            </div>
            <PaginationNav
              display
              onChange={(page) => onChange("page", page)}
              config={{
                totalCount: totalCount || 0,
                currPage: page,
                pageSize,
              }}
            />
          </div>
        </PanelFooter>
      </div>
    </Panel>
  ) : null;
};

export default memo(SearchPaginate);
