import { useCallback, useEffect, useReducer, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";
import { useAuth, useAxiosPrivate } from "../../store/hooks";
import { Panel, Tabs, TabContent, PanelHeader } from "../../components";
import UserList from "./UserList";
import { PiUsersDuotone as UserIcon } from "react-icons/pi";
import "../../assets/css/users.main.css";
import { removeKey } from "../../store/axios";

const initialState = {};

const filterReducer = (state, { type, payload }) => {
  switch (type) {
    case "term":
      return { ...state, searchTerm: payload };
    default:
      return state;
  }
};

const Users = () => {
  const {
    auth: { publicId, rank },
  } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [filters, dispatch] = useReducer(filterReducer, initialState);
  const isMounted = useRef(false);
  const navigate = useNavigate();
  const controller = new AbortController();

  const handleViewProfile = useCallback(
    (id) => {
      const url = generatePath("/repo/settings/users/:id", {
        id: publicId === id ? publicId : id,
      });
      navigate(url);
    },
    [publicId, navigate]
  );

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const config = { signal: controller.signal };

      const data = removeKey(filters, "All");
      data.rank = rank;
      return await axiosPrivate.post("/users/search", data, config);
    },
    // if enabled option is used
    // than this query will be ignored by invalidateQueries.
    // enabled: !isMounted.current,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    const fullyMounted =
      isMounted.current === true &&
      Object.keys(filters).length > Object.keys(initialState).length;

    if (fullyMounted) refetch();

    return () => {
      isMounted.current = true;
      if (fullyMounted) controller.abort();
    };
    // eslint-disable-next-line
  }, [filters]);

  return (
    <>
      <Panel>
        <PanelHeader
          PanelIcon={UserIcon}
          title="User Management"
          subtitle="Accounts"
        />
      </Panel>
      <Tabs>
        <TabContent label="Users">
          <UserList
            data={data?.data || {}}
            loading={isFetching}
            dispatch={dispatch}
            onViewProfile={handleViewProfile}
          />
        </TabContent>
      </Tabs>
    </>
  );
};

export default Users;
