import { useCallback, useState } from "react";

const useOpenState = () => {
  const [isOpen, setOpen] = useState(false);
  const showWindow = useCallback(() => setOpen(true), []);
  const dismiss = useCallback(() => setOpen(false), []);
  return { isOpen, showWindow, dismiss };
};

export default useOpenState;
