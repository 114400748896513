import { memo } from "react";
import {
  BiLineChart as LocationIcon,
  BiBarChart as SlIcon,
} from "react-icons/bi";
import { IoMdRefreshCircle as RefreshIcon } from "react-icons/io";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
} from "recharts";
import { Card, IconButton, Indicator, Progressbar } from "../../components";
import { useCommon } from "../../store/hooks";

function getTotalService(services) {
  return services.reduce((sum, sl) => {
    sum += sl.value;
    return sum;
  }, 0);
}

export const ServiceCharts = memo(({ sourcesCount = 1, onRefresh }) => {
  const { exchangeTypes, hospitals, services, servers } = useCommon();

  if (!services) return;

  const total = getTotalService(services);
  const renderServices = services.map((sl) => {
    const percent = Math.round((10000 * sl.value) / total) / 100;
    return (
      <li key={sl.service}>
        <div className="flex items-center space-between my-1">
          <span className="tracking-tight w-[55%]">{sl.service}</span>
          <span className="w-[20%] text-end">{sl.value}</span>
          <span className="text-neutral-400 text-end text-[14px] mr-1 w-[25%]">{`${percent}%`}</span>
        </div>
        <Progressbar value={percent} />
      </li>
    );
  });

  return (
    <div className="service-charts">
      <Card sx={{ padding: 16 }}>
        <div className="px-2">
          <span className="title">Lines of Service</span>
          <ol className="space-y-1">{renderServices}</ol>
        </div>
      </Card>

      <Card sx={{ paddingTop: 16, paddingBotton: 8 }}>
        <span className="title">Sources by Exchange Type</span>
        <ResponsiveContainer width="100%" height={224}>
          <BarChart
            layout="horizontal"
            data={exchangeTypes}
            margin={{
              top: 16,
              right: 0,
              bottom: -8,
              left: -4,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              interval={0}
              dataKey={(e) => `${e.shortname}`}
              type="category"
              scale="auto"
              tick={{ fontSize: 14, letterSpacing: "-0.025em" }}
              width={88}
            />
            <Bar
              animationDuration={1000}
              background={{ fill: "#eee" }}
              barSize={24}
              dataKey="value"
              fill="#413ea0"
              label={{ position: "top", backgroundColor: "#fff" }}
              radius={[12, 12, 12, 12]}
            />
          </BarChart>
        </ResponsiveContainer>
      </Card>

      <div className="space-y-1.5">
        <Card sx={{ padding: 16, height: "45%" }}>
          <span className="title">Traffic Sources</span>
          <div className="traffic">
            <div>
              <IconButton
                icon={<RefreshIcon size={32} />}
                label="Refresh"
                onClick={onRefresh}
              />
            </div>
            <div className="col">
              <span>{sourcesCount?.toLocaleString()}</span>
              <div>
                <Indicator color="warn" />
                <span>Interfaces</span>
              </div>
            </div>
            <div className="col">
              <span>{(servers?.data || []).length}</span>
              <div>
                <Indicator color="ok" /> <span>Servers</span>
              </div>
            </div>
          </div>
        </Card>

        <Card sx={{ padding: 16, height: "53%" }}>
          <p className="font-semibold">Healthcare Facilities</p>
          <span className="leading-[0.5rem]">
            Physical places where services are provided,
            <br />
            grouped into organizations (or divisions).
          </span>
          <div className="hospitals">
            <div className="flex items-center space-x-2 ml-2">
              <span className="locations">
                <SlIcon color="white" size={32} />
              </span>
              <div className="flex flex-col leading-tight">
                <span className="text-[14px]">Locations</span>
                <span className="text-[20px] font-semibold">
                  {hospitals.locations?.toLocaleString()}
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center space-x-2">
                <span className="orgs">
                  <LocationIcon color="white" size={26} />
                </span>
                <div className="flex flex-col leading-tight">
                  <span className="text-[14px]">Divisions</span>
                  <span className="text-[20px] font-semibold">
                    {hospitals.groups?.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
});
