import { useCallback, useState } from "react";
import { Toast } from "../../components";

const useToast = (id) => {
  const [message, setMessage] = useState(null);
  const [isWarning, setIsWarning] = useState(false);

  const infoHandler = useCallback((text) => {
    if (isWarning) setIsWarning(false);
    setMessage(text);
    // eslint-disable-next-line
  }, []);

  const warnHandler = useCallback((text) => {
    if (!isWarning) setIsWarning(true);
    setMessage(text);
    // eslint-disable-next-line
  }, []);

  const clearHandler = useCallback(() => {
    warnHandler(null);
  }, [warnHandler]);

  return {
    element: <Toast id={id} warning={isWarning} message={message} />,
    info: infoHandler,
    warn: warnHandler,
    clear: clearHandler
  };
};

export default useToast;
