import { useCallback } from "react";
import { useDagre } from "../../store/hooks";
import { BadgeIndicator, Tabs, TabContent, Timeline } from "../../components";
import {
  BsBoxArrowInRight as CheckIcon,
  BsCpu as CWIcon,
  BsAlt as TCPIcon,
  BsCodeSlash as JSIcon,
  BsFonts as FWIcon,
  BsActivity as JMSIcon,
  BsFiletypeDoc as DocIcon,
  BsHddNetwork as HTTPIcon,
  BsPaperclip as SMTPIcon,
} from "react-icons/bs";
import { TimelineItem } from "../../components/Timeline";

function getContent(dest) {
  switch (dest.connector_type) {
    case "TCP Sender":
      return [
        <span>
          <p>{dest.name}</p>Port {dest.port}
        </span>,
        <TCPIcon size={20} />,
      ];
    case "Channel Writer":
      return [
        <span className="leading-tight">
          <p>{dest.name}</p>
          <span className="text-[15px] tracking-tight">{dest.uuid}</span>
        </span>,
        <CWIcon size={20} />,
      ];
    case "Document Writer":
      return [
        <div>
          <p>{dest.name}</p>
          <p className="text-xs truncate">{dest.directory}</p>
        </div>,
        <DocIcon size={20} />,
      ];
    case "File Writer":
      return [
        <span>
          <p>
            {dest.protocol} {dest.name}
          </p>
          <p>host {dest.host}</p>
        </span>,
        <FWIcon size={20} />,
      ];
    case "HTTP Sender":
      return [
        <span>
          <p>{dest.name}</p>
          <p className="text-xs truncate">{dest.url}</p>
        </span>,
        <HTTPIcon size={20} />,
      ];
    case "JavaScript Writer":
      return [<span>{dest.goal}</span>, <JSIcon size={20} />];
    case "JMS Sender":
      return [
        <span>
          <p>{dest.name}</p>destination {dest.destination}
        </span>,
        <JMSIcon size={20} />,
      ];
    case "SMTP Sender":
      return [null, <SMTPIcon size={20} />];
    default:
      return [null, null];
  }
}

export const ChannelSummary = ({ channel }) => {
  const createNodes = useCallback((_, element) => {
    if (!element) return [[], []];

    const edges = [],
      nodes = [
        {
          id: "0",
          type: "input",
          data: { label: element.name },
          style: {
            width: "auto",
          },
        },
      ];

    const sourceId = nodes[0].id;

    element.destinations.forEach((dest, i) => {
      let animated = false;
      const style = {
        color: "#333",
        border: "1px solid #222138",
        width: "auto",
      };

      const label = dest.name
        .replace("COMMON_SERVICES", "CS")
        .replace("EVHC_SOURCE_MSG", "ESM");

      if (label.toLowerCase().includes("mirth")) {
        style.background = "#D6D5E6";
        animated = true;
      }

      const currNode = {
        id: `${i + 1}`,
        type: "output",
        data: { label },
        style,
      };

      nodes.push(currNode);
      edges.push({
        id: `el${sourceId}-${currNode.id}`,
        source: sourceId,
        target: currNode.id,
        animated,
      });
    });

    return [nodes, edges];
  }, []);

  const flowR = useDagre(null, channel, createNodes, "LR", false);
  if (!channel) return;

  const renderDests = channel.destinations.map((dest) => {
    const [content, connIcon] = getContent(dest);
    return (
      <li key={dest.destination_id}>
        <div className="icon">{connIcon}</div>
        <div className="dest">
          <p className="leading-tight">Dest. {dest.destination_id}</p>
          {dest.connector_type}
        </div>
        <div className="w-[260px]">{content}</div>
      </li>
    );
  });

  return (
    <div id="repo-scroll" className="channel-summary">
      <div className="box">
        <div>
          <h3 className="title">{channel.name}</h3>
          <BadgeIndicator
            label={channel.is_enabled ? "enabled" : "disabled"}
            color={channel.is_enabled ? "ok" : "basic"}
          />
        </div>
        <ol>
          {[
            channel.uuid,
            channel.mirthserver.name,
            `${channel.dataformat.name} ${channel.source_connector} ${
              channel.port ? `on port ${channel.port}` : ""
            }`.trim(),
          ].map((value, i) => (
            <li key={i}>
              <CheckIcon size={18} />
              <span>{value}</span>
            </li>
          ))}
        </ol>
        <div className="flex flex-col justify-end mt-4">
          <span>Channel Group: </span>
          <span className="text-sm font-medium text-violet-600">
            {channel.channelgroup?.name}
          </span>
        </div>
        <p className="mt-3 text-gray-600 text-sm">{channel.description}</p>
      </div>

      <Tabs>
        <TabContent label="Destinations">
          <ol className="channel-dest">{renderDests}</ol>
        </TabContent>
        <TabContent label="Channel Flow">
          <div className="h-screen w-lg">{flowR}</div>
        </TabContent>
        <TabContent label="Changes History">
          <Timeline sx={{ paddingLeft: 20 }}>
            <TimelineItem
              title="Last Modified"
              date={(channel.last_modified || "").split(".")[0]}
            />
          </Timeline>
        </TabContent>
      </Tabs>
    </div>
  );
};
