import { useCallback } from "react";
import useOpenState from "./use-openstate";
import { Drawer, Portal } from "../../components";

const useDrawer = (id, props, callback = null) => {
  const { isOpen, showWindow, dismiss } = useOpenState();
  const { title, content, isFileViewer } = props;

  const closeWindow = useCallback(() => {
    if (callback) callback();
    dismiss();
  }, [callback, dismiss]);

  return {
    isOpen,
    drawer: (
      <Portal wrapperId={id || "simple-portal"}>
        <Drawer
          title={title}
          isOpen={isOpen}
          isFileViewer={isFileViewer}
          onClose={closeWindow}
        >
          {content}
        </Drawer>
      </Portal>
    ),
    showWindow,
    closeWindow,
  };
};

export default useDrawer;
