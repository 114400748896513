const Repography = ({ h2, h4, h6, bold, center, text }) => {
  const centerClass = center ? " text-center" : "";
  if (h2) return <h2 className={`h2${centerClass}`}>{text}</h2>;
  else if (h4) return <h4 className={`h4${centerClass}`}>{text}</h4>;
  else if (h6) return <h6 className={`h6${centerClass}`}>{text}</h6>;
  else if (bold) return <span className="font-semibold text-gray-900">{text}</span>;
  else return <span className={`${centerClass}`}>{text}</span>;
};

export default Repography;
