import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { LoadSpinner } from "../components";
import { useAuth, useCommon, useRefreshToken } from "../store/hooks";

const PersistLogin = () => {
  const [loading, setLoading] = useState(true);
  const { auth, persist } = useAuth();
  const { loadCommon } = useCommon();
  const refreshToken = useRefreshToken();
  const _isMounted = useRef(true);

  const navigate = useNavigate();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refreshToken();
        loadCommon();
      } catch ({ response }) {
        const { status, data } = response || {};

        if (status === 401 || data?.detail?.includes("Invalid token"))
          navigate("/", { replace: true });
        else if (status === 403) navigate("/", { replace: true });
      } finally {
        setLoading(false);
      }
    };

    if (_isMounted.current) {
      auth.token ? setLoading(false) : verifyRefreshToken();
    }

    return () => (_isMounted.current = false);

    // eslint-disable-next-line
  }, []);

  return !(persist && loading) ? (
    <Outlet />
  ) : (
    <LoadSpinner medium label="Refreshing EIIR Repository" />
  );
};

export default PersistLogin;
