import { useCallback, useState } from "react";
import { SimpleButton } from "./Button";
import ExpandedArea from "./ExpandedArea";

const names = {
  button: "flex items-center justify-between w-full text-leff text-[15px]",
  rectangle: "transform origin-center transition duration-200 ease-out",
  region: "grid ml-2 overflow-hidden transition-all duration-300 ease-in-out",
  svg: "fill-indigo-500 shrink-0 ml-8",
};

const Rectangle = ({ units, rotate }) => {
  return (
    <rect
      y={units}
      height={2}
      width={2 * (units + 1)}
      rx="1"
      className={`${rotate} ${names.rectangle}`}
    />
  );
};

const SVG = ({ children, size }) => {
  return (
    <svg
      width={size}
      height={size}
      className={names.svg}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

// aria-controls helps estbalish the relationship between the button
// and its associated content

const Accordion = ({ items, sx }) => {
  const [expanders, setExpanders] = useState({});

  const handleClick = useCallback((index) => {
    setExpanders((curr) => ({ [index]: !curr[index] }));
  }, []);

  if (!expanders) return;

  return (
    <div className="accordion" style={sx}>
      {items.map((item, i) => {
        const key = item.label || item.key;
        const [titleId, textId, expanded] = [
          key + "-title",
          key + "-text",
          expanders[i],
        ];

        return (
          <div key={key}>
            <SimpleButton
              id={titleId}
              className={names.button + " container"}
              aria-controls={textId}
              onClick={() => handleClick(i)}
            >
              <span>{item.label}</span>
              <SVG size={12}>
                <Rectangle units={5} rotate={expanded ? "!rotate-180" : ""} />
                <Rectangle units={5} rotate={expanded ? "!rotate-180" : "rotate-90"} />
              </SVG>
            </SimpleButton>
            <ExpandedArea
              id={textId}
              expanded={expanded}
              aria-labelledby={titleId}
            >
              {item.content}
            </ExpandedArea>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
