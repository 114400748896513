import { useCallback, useState } from "react";
import { BUFFER_SIZE } from ".";
import useAxiosPrivate from "./use-axios";

const useUpload = (pathname, callback) => {
  const [isLoading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const _upload = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        const { data } = await axiosPrivate.post(pathname, formData, {
          onUploadProgress: ({ loaded, total }) => {
            const progress = Math.round((loaded / total) * 100);
            console.log(progress);
          },
        });

        if (callback) callback(data);
      } catch ({ response }) {
        console.log(response.data);
      } finally {
        setLoading(false);
      }
    },

    // eslint-disable-next-line
    [callback, pathname]
  );

  const layOverview = useCallback(
    async (files, isAtomic) => {
      const selectedFile = files[0].file;
      const formData = new FormData();

      // calculate the number of chunks and the size of each chunk
      const totalChunks = Math.ceil(selectedFile.size / BUFFER_SIZE);

      formData.append("file", selectedFile);
      formData.append("fileName", selectedFile.name);
      formData.append("totalChunks", totalChunks);
      formData.append("isatomic", isAtomic);

      await _upload(formData);
    },
    [_upload]
  );

  const analyze = useCallback(
    async (files, options) => {
      const flatFiles = Object.values(files).reduce((rObj, file) => {
        if (file.isDirectory) {
          for (let f of Object.values(file.files)) rObj.push(f);
        } else rObj.push(file);

        return rObj;
      }, []);

      // create a FormData object and add file information
      const formData = new FormData();

      // set validation checks and options
      formData.append("checks", JSON.stringify(options));

      flatFiles.forEach((file, index) => {
        const selectedFile = file.file;

        // calculate the number of chunks and the size of each chunk
        const totalChunks = Math.ceil(selectedFile.size / BUFFER_SIZE);

        formData.append("files", selectedFile);
        formData.append(`files[${index}]-totalChunks`, totalChunks);

        // loop through and upload chunks
        for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
          const start = chunkNumber * BUFFER_SIZE;
          const end = Math.min(start + BUFFER_SIZE, selectedFile.size);
          const chunk = selectedFile.slice(start, end);

          formData.append(
            `files[${index}]-chunk-${chunkNumber}`,
            chunk,
            selectedFile.name
          );
        }
      });

      await _upload(formData);
    },
    [_upload]
  );

  return { loading: isLoading, setLoading, layOverview, analyze };
};

export default useUpload;
