import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { Urls } from "../../store/axios";
import { useAuth, useLogout } from "../../store/hooks";
import { MdOutlineLogout as LogOutIcon } from "react-icons/md";
import { RiUser6Line as ProfileIcon } from "react-icons/ri";

export const MenuOption = ({ onClose }) => {
  const { auth } = useAuth();
  const logout = useLogout();
  const navigate = useNavigate();

  const handleView = useCallback(() => {
    navigate(generatePath(Urls.profile, { id: auth.publicId }));
    onClose();
    // eslint-disable-next-line
  }, []);

  const handleLogout = useCallback(
    async () => await logout(),
    // eslint-disable-next-line
    []
  );

  return (
    <div id="menuoption" className="menuoption">
      <div className="profile">
        <span className="title">{auth.display}</span>
        <span className="subtitle">{auth.email}</span>
      </div>
      <ul>
        <li onClick={handleView}>
          <ProfileIcon size={16} />
          <span>Profile</span>
        </li>
        <li onClick={handleLogout}>
          <LogOutIcon size={16} />
          <span>Log Out</span>
        </li>
      </ul>
    </div>
  );
};
