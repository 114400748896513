export const PanelHeader = ({ children, PanelIcon, title, subtitle }) => {
  return (
    <div className="panelheader">
      {children ? (
        children
      ) : (
        <>
          <span className="hpanelicon">
            <PanelIcon />
          </span>
          <div>
            <p className="sm-text">{subtitle}</p>
            <p className="t23m">{title}</p>
          </div>
        </>
      )}
    </div>
  );
};

export const PanelBody = ({ className, children }) => {
  const classes = ["panelbody"];
  if (className) classes.push(className);
  return <div className={classes.join(" ")}>{children}</div>;
};

export const PanelFooter = ({ children }) => {
  return <div className="panelfooter">{children}</div>;
};

const Panel = ({ title, children }) => {
  const headerContent = title && (
    <PanelHeader>
      <p className="title">{title}</p>
    </PanelHeader>
  );
  return (
    <div className="hpanel">
      {headerContent}
      {children}
    </div>
  );
};

export default Panel;
