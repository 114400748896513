import { useNavigate } from "react-router-dom";
import { Button, Repography } from "../components";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="page-err">
      <Repography h2 center text="OOPS!" />
      <div className="flex-ij space-x-3 mb-4 pb-4">
        <Repography h6 center text="Error 404:" />
        <Repography center text="Page Not Found!" />
      </div>
      <div>
        <Button
          content="Go Back"
          onClick={() => navigate(-1, { replace: true })}
        />
      </div>
    </div>
  );
};

export default NotFound;
