import { Route, Routes } from "react-router-dom";
import { Animate, Layout, PersistLogin } from "./components";
import * as Page from "./pages";
import Channels from "./pages/channels/Channels";
import Settings from "./pages/settings/Settings";
import RequireAuth from "./services/RequireAuth";
import {
  ReportProvider,
  ServerContextProvider,
  SidebarProvider,
} from "./store/contexts";

const App = () => {
  const analysis = <Page.AnimatedPage element={<Page.Analysis />} />;
  const dashboard = <Page.AnimatedPage element={<Page.Dashboard />} />;
  const users = <Page.AnimatedPage element={<Page.Users />} />;

  const home = (
    <SidebarProvider>
      <Page.Home />
    </SidebarProvider>
  );
  const settings = (
    <Animate fade>
      <Settings />
    </Animate>
  );
  const reports = (
    <ReportProvider>
      <Page.Reports />
    </ReportProvider>
  );
  const servers = (
    <ServerContextProvider>
      <Page.Servers />
    </ServerContextProvider>
  );

  return (
    <Routes>
      <Route exact patth="/" element={<Layout />} />
      {/* public routes */}
      <Route index element={<Page.Login />} />
      <Route path="expired" element={<Page.Expired />} />

      {/* protected routes */}
      <Route path="repo" element={home}>
        <Route path="unauthorized" element={<Page.Unauthorized />} />

        <Route element={<PersistLogin />}>
          {/** SuperAdmin routes */}
          <Route element={<RequireAuth allowedRank={3} />}>
            <Route
              path="settings/servers"
              element={<Page.AnimatedPage element={servers} />}
            />
          </Route>

          {/** Admin routes */}
          <Route element={<RequireAuth allowedRank={2} />}>
            <Route path="settings/users" element={users} />
          </Route>

          {/** SuperUser routes */}
          <Route element={<RequireAuth allowedRank={1} />}>
            <Route index element={dashboard} />
            <Route path="analysis" element={analysis} />
            <Route path="dashboard" element={dashboard} />
            <Route path="settings" element={settings} />
            <Route
              path="channels"
              element={<Page.AnimatedPage element={<Channels />} />}
            />
            <Route
              path="services"
              element={<Page.AnimatedPage element={<Page.Interfaces />} />}
            />
            <Route
              path="reports"
              element={<Page.AnimatedPage element={reports} />}
            />
            <Route
              path="settings/users/:id"
              element={<Page.AnimatedPage element={<Page.UserAccount />} />}
            />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Page.NotFound />} />
    </Routes>
  );
};

export default App;
