import { useCallback, useEffect, useState } from "react";

const getStorageItem = (defaultValue) => {
  const items = {};
  for (let [key, value] of Object.entries(defaultValue)) {
    const storedItem = localStorage.getItem(key);
    items[key] = JSON.parse(storedItem) || value;
  }

  return items;
};

const useLocalStorage = (initialValue) => {
  const [storage, setValue] = useState(() => getStorageItem(initialValue));

  const storeItem = useCallback((key, value) => {
    setValue((curr) => ({ ...curr, [key]: value }));
  }, []);

  const storeDataItem = useCallback((key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  }, []);

  useEffect(() => {
    let _isMounted = false;
    if (_isMounted) return;

    for (let [keyName, value] of Object.entries(storage))
      storeDataItem(keyName, value);

    return () => (_isMounted = true);
  }, [storage, storeDataItem]);

  return [storage, storeItem];
};

export default useLocalStorage;
