import { Fragment } from "react";
import Skeleton from "./Skeleton";

function getHeaders(config) {
  const [headers, filters] = [[], []];
  let hasFilter = false;

  for (let column of config) {
    headers.push(
      <Fragment key={column.label || column.key}>{column.header()}</Fragment>
    );

    if (column.filterValue) {
      if (!hasFilter) hasFilter = true;
      filters.push(
        <td className="py-3" key={`filter-${column.label || column.key}`}>
          {column.filterValue}
        </td>
      );
    } else filters.push(null);
  }

  return [
    headers,
    hasFilter
      ? filters.map((currFilter, i) =>
          currFilter ? currFilter : <td key={`ftd-${i}`} />
        )
      : null,
  ];
}

const TableRow = ({ config, record, loose }) => {
  const cells = config.map((column, i) => (
    <td
      key={i}
      className={[column.align, loose ? "py-[7px]" : "py-0"].join(" ")}
      style={{ width: column.width }}
    >
      {column.render(record)}
    </td>
  ));

  return <tr className="trow">{cells}</tr>;
};

const NoRow = ({ label, columnCount }) => {
  return (
    <tr className="trow">
      <td colSpan={columnCount}>
        <span className="norow">{label || "No matching records found."}</span>
      </td>
    </tr>
  );
};

/**
 * Table.js offers all the basic features necessary
 * for displaying data on any simple tabular format
 * and provides features like search, filtering, sorting, and pagination.
 * @param {*} param0
 * @returns
 */
const Table = ({
  sx,
  dark,
  verticalOnly,
  config,
  dataSource,
  pagination,
  label,
  loading,
  ...props
}) => {
  if (!config) return;

  const [headers, filters] = getHeaders(config);

  const rows =
    dataSource && dataSource.length > 0 ? (
      dataSource?.map((row, i) => (
        <TableRow
          key={`${row.channelId || row.uuid}${i}`}
          record={row}
          config={config}
          {...props}
        />
      ))
    ) : (
      <NoRow label={label} columnCount={config.length} />
    );

  return (
    <div
      id="repo-scroll"
      className={`table-wrapper${
        verticalOnly === undefined || verticalOnly === true ? " vertical" : ""
      }`}
      style={sx}
    >
      <table className="table-repo sticky">
        <thead className={dark && "thead-dark"}>
          <tr key="header">{headers}</tr>
        </thead>
        <tbody>
          {filters && <tr key="filters">{filters}</tr>}
          {loading ? (
            <tr>
              <td key="filterrow" className="p-3" colSpan={headers.length}>
                <Skeleton list={{ rows: 6, cols: 4 }} />
              </td>
            </tr>
          ) : (
            rows
          )}
        </tbody>
      </table>

      {pagination && !loading && (
        <nav aria-label="Table navigation">
          <span className="showing">
            Showing{" "}
            <span>
              {pagination.from}-{pagination.to}
            </span>{" "}
            of <span>{pagination.total}</span>
          </span>
        </nav>
      )}
    </div>
  );
};

export default Table;
