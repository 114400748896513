import { GrPieChart as PieIcon } from "react-icons/gr";

const ListSkeleton = ({ rows, cols }) => {
  const renderedList = Array(rows)
    .fill(0)
    .map((_, i) => {
      const classes = ["table"];
      if (i > 0) classes.push("pt-4");
      return (
        <div key={i} className={classes.join(" ")}>
          <div>
            <div className="first"></div>
            <div className="second"></div>
          </div>
          {Array(cols)
            .fill(1)
            .map((_, j) => (
              <div key={i + j} className="cols"></div>
            ))}
        </div>
      );
    });

  return (
    <div role="status" className="skeleton-list">
      {renderedList}
      <span className="sr-only">Loading...</span>
    </div>
  );
};

const ImageSkeleton = () => {
  return (
    <div role="status" className="skeleton-image">
      <div className="icon">
        <PieIcon size={32} className="text-gray-400" />
      </div>
      <div className="w-full">
        <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
        <div className="h-2 bg-gray-200 rounded-full max-w-[480px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full max-w-[440px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full max-w-[460px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full max-w-[360px]"></div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

const WidgetSkeleton = () => {
  return (
    <div role="status" className="skeleton-widget">
      <div className="h-2.5 bg-gray-200 rounded-full w-32 mb-2.5"></div>
      <div className="w-48 h-2 mb-10 bg-gray-200 rounded-full"></div>
      <div className="flex items-baseline mt-4 h-full">
        <div className="w-full bg-gray-200 rounded-t-lg h-[90%]"></div>
        <div className="w-full h-[70%] ms-6 bg-gray-200 rounded-t-lg"></div>
        <div className="w-full bg-gray-200 rounded-t-lg h-full ms-6"></div>
        <div className="w-full h-[80%] ms-6 bg-gray-200 rounded-t-lg"></div>
        <div className="w-full bg-gray-200 rounded-t-lg h-full ms-6"></div>
        <div className="w-full bg-gray-200 rounded-t-lg h-[90%] ms-6"></div>
        <div className="w-full bg-gray-200 rounded-t-lg h-full ms-6"></div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

const Skeleton = ({ list, placeholder, widget }) => {
  return placeholder ? (
    <ImageSkeleton />
  ) : widget ? (
    <WidgetSkeleton />
  ) : (
    <ListSkeleton rows={list.rows} cols={list.cols} />
  );
};

export default Skeleton;
