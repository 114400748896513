import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Panel,
  PanelHeader,
  RepoTreeItem,
  TreeView,
} from "../../components";

export const ValidateSettings = ({ items, onSave, onCancel }) => {
  const [checkValues, setCheckValues] = useState({});
  const _isMounted = useRef(false);

  const entries = useMemo(() => Object.entries(items), [items]);

  const handleChange = useCallback(
    ({ target }) =>
      setCheckValues((prev) => ({ ...prev, [target.id]: target.checked })),
    []
  );

  const handleSaveChanges = useCallback(
    () => onSave(checkValues),
    [checkValues, onSave]
  );

  useEffect(() => {
    if (_isMounted.current) return;
    _isMounted.current = true;

    const values = entries.reduce((rObj, [k, { checked }]) => {
      rObj[k] = !!checked;
      return rObj;
    }, {});

    setCheckValues(values);
  }, [entries]);

  const renderTreeItems = entries
    .filter(([k, _]) => k !== "custom")
    .map(([k, option]) => {
      return (
        <RepoTreeItem
          key={`treeitem-${k}`}
          id={k}
          label={option.label}
          description={option.description}
          checked={checkValues[k]}
          onChange={handleChange}
        />
      );
    });

  return (
    <div className="p-5 pt-10 text-[14px] drawer-section">
      <main>
        <TreeView id="validation-checks" caption="Validation Checks">
          {renderTreeItems}
        </TreeView>

        <div className="space-y-4">
          <Panel>
            <PanelHeader>
              <div>
                <p className="tree-label">Custom Z-Segment</p>
                Site-specific segment that falls outside HL7 standards.
              </div>
            </PanelHeader>
          </Panel>

          <Checkbox
            key="treeitem-custom"
            id="custom"
            label="Ignore Z-segment"
            checked={checkValues.custom}
            onChange={handleChange}
            sx={{ marginLeft: 32 }}
          />
        </div>
      </main>
      <section className="flex-ij space-x-2">
        <Button content="Save Changes" onClick={handleSaveChanges} />
        <Divider />
        <Button light content="Cancel" onClick={onCancel} />
      </section>
    </div>
  );
};
