import { useEffect } from "react";
import { axiosPrivate } from "../axios";
import { useRefreshToken } from "../hooks";

const useAxiosPrivate = () => {
  const refreshToken = useRefreshToken();

  useEffect(() => {
    let interResponse = axiosPrivate.interceptors.response;
    let interRequest = axiosPrivate.interceptors.request;

    const reqIntercept = interRequest.use(
      (config) => {
        // using Bearer token
        // if (!config.headers["Authorization"] && token)
        //   config.headers["Authorization"] = `Bearer ${token?.accessToken}`;
        if (config.url.startsWith("/conform"))
          config.headers["Content-Type"] = "multipart/form-data";

        return config;
      },
      (error) => Promise.reject(error)
    );

    const respIntercept = interResponse.use(
      (response) => response,
      async (error) => {
        const { config, response } = error || {};

        if (response?.status === 401 && !config.sent) {
          config.sent = true;
          await refreshToken();
          // config.headers["Authorization"] = `Bearer ${token.accessToken}`;
          return config.method === "post"
            ? axiosPrivate.post(config)
            : axiosPrivate(config);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      interRequest.eject(reqIntercept);
      interResponse.eject(respIntercept);
    };
  }, [refreshToken]);

  return axiosPrivate;
};

export default useAxiosPrivate;
