import { useCallback } from "react";
import {
  MdOutlineNotificationsNone as NotifyIcon,
  MdOutlineWorkspaces as ServiceIcon,
} from "react-icons/md";
import { PiUserDuotone as UserIcon } from "react-icons/pi";
import eiirLogo from "../../assets/images/eiir_logo.png";
import {
  IconAvatar,
  IconButton,
  Indicator,
  RepoTooltip,
} from "../../components";
import Restrict from "../../services/Restrict";
import { useCommon, useDropContainer, useModal } from "../../store/hooks";
import { InterfaceBuild } from "./InterfaceBuild";
import { MenuOption } from "./MenuOption";
import { NotifyOption } from "./NotifyOption";

const Topbar = () => {
  const { hasNotifications, removeNotification } = useCommon();
  const { modal, showModal } = useModal(InterfaceBuild);
  const [menuDroparea, menuRef, showMenu, closeMenu] =
    useDropContainer("menuoption");
  const [notifyDroparea, notifyRef, showNotify, closeNotify] =
    useDropContainer("notify");

  const handleOpenMenu = useCallback(() => {
    showMenu({ content: <MenuOption onClose={closeMenu} /> });
  }, [showMenu, closeMenu]);

  const handleOpenNotification = useCallback(() => {
    showNotify({
      content: (
        <NotifyOption onClose={closeNotify} onRemove={removeNotification} />
      ),
    });
  }, [showNotify, closeNotify, removeNotification]);

  return (
    <div className="topbar z-50">
      <div className="header">
        <div>
          {/* left side of the top bar */}
          <img src={eiirLogo} width={144} alt="EIIR Logo" />
        </div>

        <div className="drop-menu">
          <Restrict allowedAction="BuildRepository" hidden>
            <RepoTooltip label="Build Repository">
              <IconButton
                icon={<ServiceIcon size={22} onClick={showModal} />}
              />
            </RepoTooltip>
          </Restrict>

          <div id="notifyoption" className="relative" ref={notifyRef}>
            <RepoTooltip label="Notifications" position="bottom">
              <section
                className="cursor-pointer"
                onClick={handleOpenNotification}
              >
                <IconButton
                  icon={<NotifyIcon size={22} />}
                  sx={{ padding: 4 }}
                />
                {hasNotifications ? (
                  <div className="absolute top-0 right-0 border border-white mt-1 mr-0.5">
                    <Indicator color="danger" />
                  </div>
                ) : null}
              </section>
            </RepoTooltip>
            {notifyDroparea}
          </div>

          <div ref={menuRef} id="menuoption" className="relative">
            <IconAvatar
              Icon={UserIcon}
              size={18}
              color="darkblue"
              onClick={handleOpenMenu}
            />
            {menuDroparea}
          </div>
        </div>
      </div>
      {modal}
    </div>
  );
};

export default Topbar;
