import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Button, Progressbar, Skeleton } from "../../components";
import { memo, useEffect, useState } from "react";

const initialTypes = { total: 0, dataTypes: [] };

const COLORS = [
  "#969DAB",
  "#007BFF",
  "#FF8042",
  "#6F42C1",
  "#00C49F",
  "#FFD700",
  "#800080",
];

const Stats = ({ title, count }) => {
  return (
    <div className="p-3 pb-1">
      <span>{count}</span>
      <p>{title}</p>
    </div>
  );
};

// Data exchange types
export const ExchangeCard = memo(
  ({ servers, data, feeds, hospitals, onNavigate }) => {
    const [{ dataTypes, total }, setDataTypes] = useState(initialTypes);

    useEffect(() => {
      const labs = {
        type: "Lab Results",
        shortname: "Labs",
        hl7: "ORU",
        value: 0,
      };
      const types = [];
      let total = 0;

      for (let d of data) {
        total += d.value;
        if (d.hl7 === "ORU") labs.value += d.value;
        else {
          if (!d.hl7.trim()) d.hl7 = "***";
          types.push(d);
        }
      }

      types.push(labs);

      setDataTypes({ dataTypes: types, total });
    }, [data]);

    return (
      <div className="relative border border-slate-300 shadow-sm w-[66%] h-[23rem] bg-white rounded-sm">
        <div className="absolute mt-1 divide-neutral-400 divide-x flex space-x-4 leading-tight tracking-tight">
          <Stats title="Servers" count={servers.length} />
          <Stats title="Feeds" count={feeds} />
          <Stats title="Groups" count={hospitals.groups} />
          <Stats title="Locations" count={hospitals.locations} />
          <div className="p-3 pb-1 relative w-60">
            <div className="absolute w-full">
              <span className="dash-title">Exchange Types</span>
              <p className="dash-description">
                What data (and their formats) expected to receive from external
                systems.
              </p>
            </div>
          </div>
          <Button
            outline
            content="View Reports"
            onClick={onNavigate}
            sx={{ position: "absolute", right: 0, marginTop: 4 }}
          />
        </div>
        <div className="flex h-full mt-8">
          {dataTypes.length > 0 ? (
            <>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={servers}
                    outerRadius={60}
                    dataKey="value"
                    fill="#8884d8"
                  />
                  <Pie
                    data={dataTypes}
                    innerRadius={70}
                    outerRadius={100}
                    paddingAngle={3}
                    dataKey="value"
                    fill="#82ca9d"
                    label
                  >
                    {dataTypes.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>

              <div className="w-full flex flex-col justify-center m-2 mr-4">
                {dataTypes.map((x, i) => {
                  const percent = Math.round((1000 * x.value) / total) / 10;
                  return (
                    <div key={i}>
                      <div className="w-full text-[14px] flex items-center space-between space-x-3 mt-2">
                        <div className="w-[50%]">{x.type}</div>
                        <div className="w-[20%]">{x.hl7}</div>
                        <div className="w-[15%]">{x.value}</div>
                        <div className="w-[15%]">{percent}%</div>
                      </div>
                      <div className="w-[96%]">
                        <Progressbar value={percent} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="h-full w-full flex-ij">
              <Skeleton placeholder />
            </div>
          )}
        </div>
      </div>
    );
  }
);
