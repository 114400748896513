import { Card, Checkbox, DragNDrop } from "../../components";

export const Droparea = ({ selectedFile, isAtomic, onChange, onCombine }) => {
  const handleChange = ({ target }) => onCombine(target.checked);

  return (
    <Card>
      <div className="p-4 text-center">
        <p className="font-semibold text-neutral-700">Message Overview</p>
        <span>See an HL7 interpretation based on the DIS specifications</span>
      </div>
      <div className="px-4 mb-4 flex justify-center space-x-5">
        <div className="w-full space-y-1">
          <DragNDrop onChange={onChange} />
          <Checkbox
            label="Combine OBX segments"
            onChange={handleChange}
            checked={!isAtomic}
          />
        </div>
        <div className="w-1/3 text-[15x] leading-tight">
          <p className="font-semibold text-[14px]">File Input</p>
          {selectedFile.name ? (
            <>
              <p>filename: {selectedFile.name}</p>
              <p>Size: {selectedFile.size} KB</p>
            </>
          ) : (
            <>
              <p>No file chosen</p>
              <p className="whitespace-nowrap text-sm text-neutral-500">
                Only .dat, .hl7, and .txt files
              </p>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};
