import { memo } from "react";
import { useMutation } from "@tanstack/react-query";
import { Button, Repography } from "../../components";
import { LuFolderSync as SyncIcon } from "react-icons/lu";
import { MdOutlineAlternateEmail as PasswordIcon } from "react-icons/md";
import { useAxiosPrivate } from "../../store/hooks";

const PasswordNotice = ({ resource }) => {
  return (
    <>
      Password reset requested for <Repography bold text={resource} />{" "}
    </>
  );
};

const SyncNotifice = ({ resource }) => {
  return (
    <>
      Channels were imported for <Repography bold text={resource} />. Sync
      required to apply the latest changes.{" "}
    </>
  );
};

export const SyncUpdate = memo(({ notification, onDismiss }) => {
  const axiosPrivate = useAxiosPrivate();
  const dismissMutation = useMutation({
    mutationKey: ["notifications"],
    mutationFn: async () =>
      await axiosPrivate.delete(`/repo/notifications/${notification.id}`),
    onSuccess: () => onDismiss(notification.id),
  });

  const isImport = notification.type === "import";

  return (
    <div className="flex px-3">
      <div className="flex-shrink-0">
        <div className="rounded-full p-2 shadow-sm border text-blue-600 bg-slate-100">
          {isImport ? <SyncIcon size={20} /> : <PasswordIcon size={18} />}
        </div>
      </div>
      <div className="w-full px-3">
        <span className="text-[13px] text-blue-600">{notification.time}</span>
        <div className="text-gray-500 text-sm leading-tight">
          {notification.type === "import" ? (
            <SyncNotifice resource={notification.resource} />
          ) : (
            <PasswordNotice resource={notification.resource} />
          )}
        </div>
        <Button
          outline
          content="Dismiss"
          loading={dismissMutation.isLoading}
          sx={{ marginLeft: -8 }}
          onClick={() => dismissMutation.mutate()}
        />
      </div>
    </div>
  );
});
