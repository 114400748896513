import { ImSpinner2 as Spinner } from "react-icons/im";
import "../assets/css/loader.css";

const LoadSpinner = ({ medium, large, label }) => {
  const key = medium ? "medium" : large ? "large" : "default";
  const currSize = { medium: 32, large: 48, default: 24 }[key];

  return (
    <div className="spinner">
      <Spinner className="anime" size={currSize} />
      <span>{label}</span>
    </div>
  );
};

const LoadThreeSpins = () => {
  return <div className="loader"></div>;
}

export default LoadSpinner;
export { LoadThreeSpins };
