import { FaUsersCog as UserIcon } from "react-icons/fa";
import { HiServerStack as ServerIcon } from "react-icons/hi2";
import { SiOkta as OktaIcon } from "react-icons/si";
import {
  // MdMediation as InterfaceIcon,
  // MdToll as ChannelsIcon,
  MdSecurity as SecurityIcon,
} from "react-icons/md";

export const menuItems = [
  {
    title: "Server Instances",
    description: "Control Mirth Connect instance access",
    PathIcon: ServerIcon,
    pathname: "servers",
  },
  // {
  //   title: "Mirth Channels",
  //   description: "Management interface for deployed channel groups",
  //   PathIcon: ChannelsIcon,
  //   pathname: "channels",
  // },
  // {
  //   title: "Interface Services",
  //   description:
  //     "Complex flow of information exchanges from a source to one or more destinations",
  //   PathIcon: InterfaceIcon,
  //   pathname: "services",
  // },
  {
    title: "User Management",
    description:
      "Centralized identity access, create and manage user profiles, role mamangement",
    PathIcon: UserIcon,
    pathname: "users",
  },
  {
    title: "Security Profiles",
    description:
      "Access levels and policies to manage permissions for actions and user access",
    PathIcon: SecurityIcon,
  },
  {
    title: "Okta Configuration",
    description: "Create Okta integration",
    PathIcon: OktaIcon,
  },
  // {
  //   title: "Views & Reports",
  //   description:
  //     "Dynamic, real-time, historical usage of repository statistics",
  //   PathIcon: ReportIcon,
  //   pathname: "reports",
  // },
];
