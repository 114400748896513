import { Card, Link } from "../../components";
import { menuItems } from "./menuItems";

const prefix = "/repo/settings/";

const Settings = () => {
  return (
    <div className="settings">
      {menuItems.map((item) => {
        const { title, description, PathIcon, pathname } = item;
        return (
          <Card key={item.title} sx={{ padding: 24 }}>
            <div className="container">
              <div className="h-full">
                <div className="icon">
                  <PathIcon size={48} />
                </div>
                <p className="title">{title}</p>
                <p className="description">{description}</p>
              </div>
              {pathname ? (
                <Link to={`${prefix}${pathname}`} label="More details" />
              ) : (
                <span className="text-secondary text-sm">Coming Soon!</span>
              )}
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default Settings;
