import { memo } from "react";
import { Panel, PanelBody } from "../../components";
import { useCommon } from "../../store/hooks";
import {
  Area,
  CartesianGrid,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  XAxis,
  AreaChart,
} from "recharts";

function arrSwitch(list, i, j) {
  const tmp = list[i];
  list[i] = list[j];
  list[j] = tmp;
}

const ChannelsChart = () => {
  const {
    servers: { stats },
    sources,
    destinations,
  } = useCommon();

  if (destinations === undefined) return;

  // merge stats into series of protocols
  const seriesObj = destinations.reduce((reduceObj, { connector, active }) => {
    reduceObj[connector] = {
      protocol: connector,
      Writers: active,
      Readers: 0,
    };
    return reduceObj;
  }, {});

  for (let source of sources) {
    if (source.connector in seriesObj)
      seriesObj[source.connector].Readers = source.active;
    else
      seriesObj[source.connector] = {
        protocol: source.connector,
        Readers: source.active,
        Writers: 0,
      };
  }

  let channelsCount = 0;
  const serversList = stats.map((s) => {
    channelsCount += s.value;
    return (
      <li key={s.serverId} className="border-b border-neutral-200">
        <div className="flexib tracking-tight">
          <span>{s.server}</span>
          <span>{s.value.toLocaleString()}</span>
        </div>
      </li>
    );
  });

  const series = Object.values(seriesObj);
  // temp positional hack
  if (series.length > 5) {
    arrSwitch(series, 5, 3);
    arrSwitch(series, 1, 4);
  }

  return (
    <div className="flex3 w-full">
      <div className="w-[75%]">
        <Panel>
          <PanelBody>
            <main className="-ml-7">
              <ResponsiveContainer width="100%" height={284}>
                <AreaChart data={series}>
                  <defs>
                    <linearGradient
                      id="colorReaders"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>

                    <linearGradient
                      id="colorWriters"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="protocol"
                    tick={{ fontSize: 13, transform: "translate(-1, 4)" }}
                  />
                  <YAxis tick={{ fontSize: 14 }} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip itemStyle={{ fontSize: 13 }} />
                  <Area
                    type="monotone"
                    dataKey="Readers"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill="url(#colorReaders)"
                  />
                  <Area
                    type="monotone"
                    dataKey="Writers"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#colorWriters)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </main>
          </PanelBody>
        </Panel>
      </div>
      <div className="stats-channels">
        <Panel>
          <PanelBody>
            <p className="title">Channels</p>
            <div className="stat-container">
              <span>{channelsCount.toLocaleString()}</span>
              <p>Source Connectors</p>
            </div>
          </PanelBody>
        </Panel>
        <Panel>
          <PanelBody>
            <p className="text-sm font-semibold mb-3">Servers</p>
            <ol className="w-full space-y-2">{serversList}</ol>
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default memo(ChannelsChart);
