import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Panel, PanelBody } from "../../components";
import { createInput, createRadioGroup } from "../../form/FormCreate";
import { useCommon, useDebounce, useForm } from "../../store/hooks";
import { MdFilterList as FilterIcon } from "react-icons/md";

export const ChannelFilter = memo(({ dispatch, filters }) => {
  const {
    servers: { stats },
    sources,
  } = useCommon();
  const [controls, setControls] = useState({});
  const [setValue, debounce] = useDebounce(500);

  const filterConfig = useMemo(
    () => ({
      channelname: {
        type: "input",
        ...createInput({
          id: "channelname",
          outline: true,
          helperText: "Search channel by name",
        }),
        required: true,
        onInputChange: (_, value) => setValue(value),
      },
      statusradios: {
        type: "radio-group",
        required: true,
        defaultValue: "all",
        ...createRadioGroup({
          name: "statusgrp",
          group: {
            allChannels: {
              id: "allChannels",
              value: "all",
              label: "All",
            },
            activeChannels: {
              id: "activeChannels",
              value: "active",
              label: "Active",
            },
            inactiveChannels: {
              id: "inactiveChannels",
              value: "inactive",
              label: "Inactive",
            },
          },
        }),
      },
    }),
    // eslint-disable-next-line
    []
  );

  const handleChange = useCallback(
    (type, checkbox) => {
      dispatch({ type, payload: checkbox });
    },
    [dispatch]
  );

  useEffect(() => {
    if (debounce !== null) handleChange("name", debounce);
  }, [debounce, handleChange]);

  useEffect(() => {
    setControls({
      sources: sources?.map((source, i) => (
        <Checkbox
          key={i}
          id={`${source.connector}-${i}`}
          label={source.alias}
          onChange={() => handleChange("connector", source.alias)}
          checked={filters.connectors[source.alias] || false}
        />
      )),
      servers: stats.map((curr) => (
        <Checkbox
          id={`server-${curr.serverId}-chk`}
          key={curr.serverId}
          label={curr.server}
          onChange={() => handleChange("server", curr.serverId)}
          checked={filters.servers[curr.serverId] || false}
        />
      )),
    });
  }, [filters, sources, stats, handleChange]);

  const { renderForm, resetForm } = useForm(filterConfig);
  const { statusradios, channelname } = renderForm();
  const { radios: inputs, value: statusChoice } = statusradios;

  const handleClearAll = useCallback(() => {
    dispatch({ type: "clear" });
    resetForm();
    // eslint-disable-next-line
  }, [resetForm]);

  useEffect(() => {
    if (statusChoice) handleChange("status", statusChoice);
  }, [statusChoice, handleChange]);

  return (
    <Panel>
      <PanelBody>
        <div className="channel-filter">
          <div className="flex-i">
            <FilterIcon size={18} />
            <span>Filters</span>
          </div>
          <Button outline content="Clear all" onClick={handleClearAll} />
        </div>

        {channelname}

        <div className="space-y-4 mt-4 ml-2">
          <div>
            <span className="txb mb-2 flex">Status</span>
            <ol>
              {Object.entries(inputs).map(([key, radio]) => (
                <li key={key}>{radio}</li>
              ))}
            </ol>
          </div>
          <div>
            <p className="txb mb-2">Mirth Server Instances</p>
            {controls.servers}
          </div>
          <div>
            <p className="txb mb-2">Source Connectors</p>
            {controls.sources}
          </div>
        </div>
      </PanelBody>
    </Panel>
  );
});
