import { useQueryClient } from "@tanstack/react-query";
import { memo, useCallback, useState } from "react";
import { ImUserPlus as NewIcon } from "react-icons/im";
import { MdOpenInNew, MdSearch } from "react-icons/md";
import { RiUserLine as UserIcon } from "react-icons/ri";
import {
  Divider,
  GroupInput,
  IconAvatar,
  IconButton,
  PaginationNav,
  Panel,
  PanelBody,
  RepoTooltip,
  Skeleton,
  Table,
} from "../../components";
import { useDrawer, useSortableData } from "../../store/hooks";
import NewUserForm from "./NewUserForm";

function getConfig(key, callback) {
  return [
    {
      key,
      render: (user) => {
        return (
          <div>
            <RepoTooltip label="Open" position="right">
              <IconButton
                icon={
                  <MdOpenInNew
                    color="primary"
                    size={20}
                    onClick={() => callback(user)}
                  />
                }
              />
            </RepoTooltip>
          </div>
        );
      },
      align: "align-center",
    },
    {
      label: "Display Name",
      render: (user) => (
        <div className="flex space-x-1 items-center py-2">
          <IconAvatar Icon={UserIcon} color="purple" />
          <div>
            <p>{user.display}</p>
            <p className="sm-text">{user.email}</p>
          </div>
        </div>
      ),
      sortValue: (user) => user.display,
    },
    {
      label: "Department",
      render: (user) => (
        <span className="tracking-tight ml-1">{user.department}</span>
      ),
      sortValue: (user) => user.department,
    },
    {
      label: "Last Active On",
      render: (user) => <span className="ml-1">{user.lastLogin}</span>,
      sortValue: (user) => user.lastLogin,
    },
  ];
}

const UserList = ({ data, loading, dispatch, onViewProfile }) => {
  const [dialog, setDialog] = useState({});
  const { drawer, showWindow, closeWindow } = useDrawer(
    "userportal",
    dialog,
    () => setDialog({})
  );

  const queryClient = useQueryClient();
  const { total, entry } = data || {};

  const handleClose = useCallback(
    (success = false) => {
      closeWindow();
      if (success)
        queryClient.invalidateQueries({ queryKey: ["users"], exact: true });
    },
    // eslint-disable-next-line
    [closeWindow]
  );

  const handleAddUserClick = useCallback(() => {
    setDialog({
      title: "Create New User",
      content: <NewUserForm onClose={handleClose} />,
    });
    showWindow();
  }, [handleClose, showWindow]);

  const handleNameChange = useCallback(
    (name) => dispatch({ type: "term", payload: name }),
    [dispatch]
  );

  // Group users by role
  const { adminGroup, userGroup } =
    entry?.results.reduce((reduceObj, user) => {
      const tag = user.role === "SuperUser" ? "userGroup" : "adminGroup";
      if (!(tag in reduceObj)) reduceObj[tag] = { data: [] };
      reduceObj[tag].data.push(user);
      return reduceObj;
    }, {}) || {};

  const { dataSorted: admins, headerConfig: adminHeaderConfig } =
    useSortableData(
      adminGroup?.data,
      getConfig("key-admins", (admin) => onViewProfile(admin.publicId))
    );

  const { dataSorted: users, headerConfig: userHeaderConfig } = useSortableData(
    userGroup?.data,
    getConfig("key-users", (user) => onViewProfile(user.publicId))
  );

  return (
    <>
      <Panel>
        <PanelBody className="pbody">
          <PaginationNav
            // display
            title="Users"
            display={total > 0}
            config={{
              pageSize: 24,
              currPage: 1,
              totalCount: total,
            }}
          />
          <div className="search">
            <GroupInput
              id="usersearch-input"
              width={260}
              lead={{ icon: <MdSearch size={20} color="gray" /> }}
              placeholder="Search user by name"
              onChange={handleNameChange}
            />
            <Divider />
            <span className="user" onClick={handleAddUserClick}>
              <RepoTooltip position="right" label="Add user">
                <IconButton icon={<NewIcon size={20} />} />
              </RepoTooltip>
            </span>
          </div>
        </PanelBody>
      </Panel>

      <div className="users">
        <div className="admins">
          <div className="w-1/4 pr-5 pl-2">
            <span className="font-semibold">Admin Users</span>
            <p className="tl">
              Admin can add and remove users and manage Repository-level
              settings.
            </p>
          </div>
          <div className="w-3/4">
            {loading ? (
              <Skeleton list={{ rows: 2, cols: 4 }} />
            ) : (
              <Table
                id="admins"
                config={adminHeaderConfig}
                dataSource={admins}
                sx={{ maxHeight: 328 }}
              />
            )}
          </div>
        </div>

        <div className="accts">
          <div className="w-1/4 pr-5 pl-2">
            <span className="font-semibold">Account Users</span>
            <p className="tl">
              Account users can access the Repository reports and view interface
              details.
            </p>
          </div>
          <div className="w-3/4">
            {loading ? (
              <Skeleton list={{ rows: 3, cols: 4 }} />
            ) : (
              <Table
                id="users"
                config={userHeaderConfig}
                dataSource={users}
                sx={{ maxHeight: 720 }}
              />
            )}
          </div>
        </div>
      </div>

      {drawer}
    </>
  );
};

export default memo(UserList);
