import { useCallback, useMemo, useState } from "react";

const initialCount = { mistakeCount: 0 };

const useAttempt = (max, logins = 3) => {
  const [attempts, setAttempts] = useState(initialCount);
  const [{ threshold, maxLogins }] = useState({
    threshold: max,
    maxLogins: logins,
  });

  const recordMistake = useCallback((user) => {
    setAttempts((previous) => {
      return {
        ...previous,
        mistakeCount: previous.mistakeCount + 1,
        [user]: (previous[user] || 0) + 1,
      };
    });
  }, []);

  const isBelowThreshold = useMemo(
    () => attempts.mistakeCount < threshold,
    [attempts, threshold]
  );

  const canRetry = useCallback(
    (user) => {
      const count = (attempts[user] || 0) + 1;
      return maxLogins - count;
    },
    [attempts, maxLogins]
  );

  const resetCount = useCallback(() => {
    setAttempts(initialCount);
  }, []);

  return {
    isBelowThreshold,
    recordMistake,
    resetCount,
    canRetry,
  };
};

export default useAttempt;
