import { memo } from "react";
import { BsFiletypeHtml as HtmlIcon } from "react-icons/bs";
import {
  GrSchedule as ScheduleIcon,
  GrDocumentPdf as TranscriptIcon,
} from "react-icons/gr";
import { IoMdCheckmarkCircleOutline as CheckIcon } from "react-icons/io";
import { RxOpenInNewWindow as OpenNoteIcon } from "react-icons/rx";
import {
  TbBuildingHospital as EncounterIcon,
  TbCode as HeaderIcon,
  TbBrandBandlab as LabIcon,
  TbPrescription as OrderIcon,
  TbBrandXing as OtherIcon,
} from "react-icons/tb";
import { TbCurrencyDollar as CashIcon } from "react-icons/tb";
import { TfiUser as PersonIcon } from "react-icons/tfi";
import { BadgeIndicator, Card } from "../../components";

function createItem(key, Icon, header, description, display) {
  return (
    <li key={key} className="mb-5 ms-8 w-[75%]">
      <span className="absolute text-blue-800 flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full -start-4 ring-8 ring-white">
        <Icon size={20} />
      </span>
      <div className="flex space-x-1 items-center justify-start">
        <h3 className="flex items-center font-semibold text-gray-900 text-[14px]">
          {description}
        </h3>
        {header && (
          <span className="block text-sm font-normal leading-none text-gray-400">
            ( {header} )
          </span>
        )}
      </div>
      <div className="mb-4 font-normal text-gray-700">{display}</div>
    </li>
  );
}

function getBilling(charge) {
  return (
    <li
      key={charge.order}
      className="leading-tight flex space-x-2 w-full flex-i"
    >
      <span className="p-3 rounded border bg-slate-50">
        <CashIcon size={20} color="green" />
      </span>
      <div>
        <p className="font-semibold text-[14px]">{charge.type}</p>
        <p>
          {charge.quant} {charge.procedure} at ${charge.amount} (${charge.total}{" "}
          total)
        </p>
      </div>
    </li>
  );
}

function getCoverage(policy) {
  const buffer = [`${policy.insured}`];
  buffer.push(` insured by ${policy.by}`);
  buffer.push(`, effective ${policy.effective}`);
  if (policy.expiration) buffer.push(` and expires on ${policy.expiration}`);

  return (
    <li key={policy.order} className="flex space-x-3">
      <div className="flex-i">
        <CheckIcon size={18} />
      </div>
      <div className="leading-tight">
        <p className="flex-i space-x-2">
          <span>{`${policy.order} Policy:`}</span>
        </p>
        <p>{buffer.join("")}</p>
      </div>
    </li>
  );
}

function getVisitInfo({ date, category, location, time_elapsed, ...provider }) {
  const texts = [],
    providerList = {};

  if (provider.admiter) providerList[provider.admiter] = ["Admitted"];
  if (provider.referrer) {
    if (provider.referrer in providerList)
      providerList[provider.referrer].push("referred");
    else providerList[provider.referrer] = ["Referred"];
  }
  if (provider.attender) {
    if (provider.attender in providerList)
      providerList[provider.attender].push("attended");
    else providerList[provider.attender] = ["Attended"];
  }

  if (category) texts.push(`${category} visit`);
  if (date) texts.push(`on ${date} (${time_elapsed} ago)`);
  if (location) texts.push(`at ${location}`);

  return (
    <div>
      <p>{texts.join(" ")}</p>
      {Object.entries(providerList).map(([p, action]) => (
        <p key={action}>{` - ${action.join(", ")} by ${p}`}</p>
      ))}
    </div>
  );
}

export const Interpretation = memo(({ terms, notes, onView }) => {
  let resources = [];

  const renderTerms = Object.entries(terms).map(
    ([key, { description, translation: term }], index) => {
      let display, CurrIcon;
      const buffer = [];

      switch (key) {
        case "MSH":
          CurrIcon = HeaderIcon;
          display = `${term.code} (${term.trigger}) - ${term.description}`;
          break;
        case "PID":
          CurrIcon = PersonIcon;
          display = `${term.fullname} (${term.mrn}) ${term.age} ${term.identity}`;
          break;
        case "PV1":
          CurrIcon = EncounterIcon;
          display = getVisitInfo(term);
          break;
        case "SCH":
          CurrIcon = ScheduleIcon;
          buffer.push(term.date);
          if (term.reason) buffer.push(`for ${term.reason}`);
          display = (
            <div className="">
              {term.appointment}
              <p>{buffer.join(" ")}</p>
            </div>
          );
          break;
        case "OBR":
          CurrIcon = LabIcon;
          display = `${term.diagnostics} ordered by ${term.provider} for ${term.serviceId} on ${term.date}`;
          break;
        case "ORC":
          CurrIcon = OrderIcon;

          if (term.placerNo) buffer.push(`Order Placer No ${term.placerNo}`);
          if (term.control) buffer.push(term.control);
          display = buffer.join(" ");

          if (term.status) display = `${display}: ${term.status}`;
          break;
        case "TXA":
          CurrIcon = TranscriptIcon;
          buffer.push(term.status);
          if (term.format) buffer.push(term.format);
          buffer.push(term.type);
          if (term.originator) buffer.push(`originated by ${term.originator}`);
          if (term.datetime) buffer.push(`on ${term.datetime}`);
          display = buffer.join(" ");
          break;
        default:
          CurrIcon = OtherIcon;
          switch (key) {
            case "FT1":
              if (term.length > 0) {
                display = (
                  <ol className="flex flex-col space-y-3">
                    {term?.map((charge) => getBilling(charge))}
                  </ol>
                );
              } else {
                display = <p>No transaction available</p>;
              }
              break;
            case "IN1":
              display =
                term.length > 0 ? (
                  <ol className="list-outside ml-2 space-y-1">
                    {term.map((policy) => getCoverage(policy))}
                  </ol>
                ) : (
                  <p>No coverage available</p>
                );
              break;
            case "AIG":
            case "AIL":
            case "AIP":
            case "AIS":
              for (let { action, status, service_id } of term) {
                const resource = [action, status, service_id]
                  .filter(Boolean)
                  .join(" ");
                resources.push(resource);
              }
              return null;
            default:
              return null;
          }
      }

      return createItem(index, CurrIcon, key, description, display);
    }
  );

  const renderNotes = notes.map((note, i) => {
    const CurrIcon = "XHTML".includes(note.subtype) ? HtmlIcon : OpenNoteIcon;
    const [color, label] =
      note.status === "F"
        ? ["ok", "Final results"]
        : note.status === "P"
        ? ["progress", "Partial results"]
        : ["basic", "Unknown status"];

    const indicator = <BadgeIndicator color={color} label={label} />;

    return (
      <li key={note.key} className="flex-i space-x-4 py-1">
        <span className="w-4">
          <CurrIcon size={20} />
        </span>
        <span
          className="w-1/4 text-primary cursor-pointer hover:primarydark hover:no-underline hover:opacity-75"
          onClick={() => onView(note, i)}
        >
          {note.service_id}
        </span>
        {indicator}
        <span className="w-1/4">{note.observer}</span>
        <span>{note.size} KB</span>
      </li>
    );
  });

  if (resources.length > 0) {
    resources.sort();

    const renderResources = resources.map((resource, i) => (
      <li key={`resource-${i}`} className="flex-i space-x-2">
        <CheckIcon size={18} />
        <span>{resource}</span>
      </li>
    ));

    renderTerms.push(
      createItem(
        renderTerms.length,
        OtherIcon,
        null,
        "Resources",
        <ol className="ml-1">{renderResources}</ol>
      )
    );
  }

  if (notes.length > 0)
    renderTerms.push(
      createItem(
        renderTerms.length,
        OtherIcon,
        "notes",
        "Notes & Documents",
        <ol className="divide-y divide-neutral-300 ml-1">{renderNotes}</ol>
      )
    );

  return (
    <>
      <Card>
        {terms.MSH ? (
          <ul className="relative my-6 mx-14 broder-s border-gray-300">
            {renderTerms}
          </ul>
        ) : (
          <span className="p-4 flex-ij text-sm text-neutral-400">
            No translation terms available
          </span>
        )}
      </Card>
    </>
  );
});
