import { useCallback, useState } from "react";

/**
 * Forcing an update on a component
 */
const useForceUpdate = () => {
  const [, updateState] = useState();

  const forceUpdate = useCallback(() => {
    updateState({});
  }, []);

  return { forceUpdate };
};

export default useForceUpdate;
