import { Fragment } from "react";
import { createPortal } from "react-dom";
import { IconButton } from "./Button";
import { MdClose as CloseIcon } from "react-icons/md";
import "../assets/css/modal.css";

const Backdrop = () => {
  return <div className="backdrop" />;
};

const ModalOverlay = ({ children, onClose }) => {
  return (
    <div className="modal">
      <header className="flex justify-end">
        <div>
          <IconButton icon={<CloseIcon size={18} onClick={onClose} />} />
        </div>
      </header>
      <main className="content">{children}</main>
    </div>
  );
};

const Modal = ({ isOpen, onClose, children }) => {
  const selector = document.getElementById("overlays");

  return (
    isOpen && (
      <Fragment>
        {createPortal(<Backdrop />, selector)}
        {createPortal(
          <ModalOverlay onClose={onClose}>{children}</ModalOverlay>,
          selector
        )}
      </Fragment>
    )
  );
};

export default Modal;
