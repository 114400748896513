import { memo } from "react";
import usePagination from "../store/hooks/use-pagination";
import {
  MdKeyboardArrowLeft as LeftArrow,
  MdKeyboardArrowRight as RightArrow,
} from "react-icons/md";

const PageLink = ({ active, disabled, element, children, onChange }) => {
  let content = element || children;

  const handleClick = () => {
    if (!(disabled || active)) onChange(content);
  };

  return (
    <li className={active ? "active" : ""} onClick={handleClick}>
      {content}
    </li>
  );
};

/**
 * Pagination navigation component that displays enough data
 * to inform users and prevents data overload while presenting
 * just a portion of the data at a time and allowing users to navigate between pages
 * 
 * @param display Shows the page list
 * @returns 
 */
const PaginationNav = ({ display, title, config, onChange }) => {
  const { totalCount, pageSize, currPage } = config;
  const totalPageCount = Math.ceil(totalCount / pageSize); // total page count
  const [navigatorRange, dots] = usePagination(totalPageCount, currPage);

  const links = navigatorRange?.map((pg, i) => {
    return pg === dots ? (
      <PageLink key={pg + i} element={dots} />
    ) : (
      <PageLink
        key={pg}
        element={pg}
        active={pg === currPage}
        onChange={onChange}
      />
    );
  });

  let count_from, count_to;

  if (totalCount) {
    const lastExpected = Math.ceil(totalCount / pageSize);
    const offset =
      ((lastExpected < currPage ? lastExpected : currPage) - 1) * pageSize;
    const nextCount = offset + pageSize;

    count_from = offset + 1;
    count_to = nextCount < totalCount ? nextCount : totalCount;
  } else {
    count_from = count_to = 0;
  }

  const renderTotal = title ? (
    <>
      <span className="title">{title}</span>{" "}
      <span>({totalCount?.toLocaleString()})</span>
    </>
  ) : (
    <div>
      Showing{" "}
      <b>
        {count_from.toLocaleString()}-{count_to.toLocaleString()}
      </b>{" "}
      of <b>{totalCount.toLocaleString()}</b>
    </div>
  );

  return (
    <div className="pagination">
      <nav aria-label={`${title} Navigation`}>
        <span className="sb-normal">{renderTotal}</span>
        {(totalPageCount > 1 || (display && totalPageCount !== 0)) && (
          <ul>
            {/* left arrow */}
            <PageLink
              key="prev-pg"
              disabled={currPage === 1}
              element={<LeftArrow fontSize={16} />}
              onChange={() => onChange(currPage - 1)}
            />
            {links}
            {/*right arrow*/}
            <PageLink
              key="next-pg"
              disabled={currPage === totalPageCount}
              element={<RightArrow fontSize={16} />}
              onChange={() => onChange(currPage + 1)}
            />
          </ul>
        )}
      </nav>
    </div>
  );
};

export default memo(PaginationNav);
