const Switcher = ({ activeIndex, children }) => {
  return children.map((step, i) => {
    const currClass = activeIndex === `${i}` ? "h-full" : "hidden";
    return (
      <section key={i} className={currClass}>
        {step}
      </section>
    );
  });
};

const SwitchRoute = ({ children }) => {
  return children;
};

export default Switcher;
export { SwitchRoute };
