import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import {
  MdCheckCircleOutline as CompletedIcon,
  MdCloudOff as NoIcon,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Divider, InputField, Table } from "../../components";
import { useDebounce, useSortableData, useToast } from "../../store/hooks";

function getTotal(groups) {
  return groups
    .reduce((acc, curr) => acc + curr.channels.count, 0)
    .toLocaleString();
}

function initialize(groups, state, selected) {
  return Object.values(groups).reduce((rObj, g) => {
    if (!selected || g.id in selected) rObj[g.id] = state;
    return rObj;
  }, {});
}

const GroupImportForm = ({ mutations, groups }) => {
  const [all, setAll] = useState(false);
  const [channelKeys, setChannelKeys] = useState(() =>
    initialize(groups, false)
  );
  const [filterdGroup, setFilteredGroup] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const [setValue] = useDebounce(
    500,
    null,
    useCallback((debounce) => setFilteredGroup(debounce.toLowerCase()), [])
  );

  const selectedGroups = Object.entries(channelKeys)
    .filter(([_, v]) => v)
    .map(([k, v]) => k);

  const selectedCount = useMemo(
    () => Object.keys(selectedGroups).length,
    [selectedGroups]
  );

  const filteredGroups = useMemo(() => {
    return filterdGroup
      ? groups.filter((g) => g.name.toLowerCase().includes(filterdGroup))
      : groups;
  }, [groups, filterdGroup]);

  // Mutation to load channel groups into a staging table
  const stagingMutation = useMutation({
    mutationKey: ["groups"],
    mutationFn: mutations.stage,
    onSuccess: (response) => {
      const { data, status } = response || {};
      if (status === 200) mutations.onClose(data.message);
      else mutations.dispatch({ type: "error", payload: data.message });
    },
    onError: ({ response }) => {
      if (response?.status === 403) navigate("/expired", { replace: true });
      const { detail } = response?.data || {};
      toast.warn(detail);
    },
  });

  // Step [2]
  // --------
  // Store ChannelGroups into `group_imports` staging table.

  const handleStageImport = useCallback(
    () => {
      stagingMutation.mutate(selectedGroups);
    },
    // eslint-disable-next-line
    [selectedGroups]
  );

  const handleTermChange = useCallback(
    ({ target }) => {
      setValue(target.value);
      if (!target.value && all) setAll(false);
    },
    // eslint-disable-next-line
    []
  );

  const handleSelectAll = useCallback(
    ({ target }) => {
      const keys = {};
      filteredGroups.forEach((g) => (keys[g.id] = true));
      let selected = initialize(groups, target.checked, keys);
      setAll(target.checked);
      setChannelKeys(selected);
    },
    [filteredGroups, groups]
  );

  const handleCheckChange = useCallback(
    ({ target }) => {
      const { id, checked } = target;

      setChannelKeys((prev) => ({ ...prev, [id]: checked }));
      if (!checked && all) setAll(false);
    },
    [all]
  );

  const apiConfig = useMemo(
    () => [
      {
        key: "Select",
        render: (g) => {
          let curr;
          const { count, importCount } = g?.channels || {};
          if (count === undefined)
            curr = (
              <Checkbox
                key="select-all"
                id="all"
                checked={all}
                onChange={handleSelectAll}
              />
            );
          else {
            if (count === 0) {
              if (importCount === 0) curr = <NoIcon color="brown" size={18} />;
            } else {
              curr =
                count === importCount ? (
                  <CompletedIcon color="green" size={18} />
                ) : (
                  <Checkbox
                    key={g.id}
                    id={g.id}
                    checked={channelKeys[g.id] || false}
                    onChange={handleCheckChange}
                  />
                );
            }
          }

          return <section className="pl-2">{curr}</section>;
        },
      },
      {
        label: "Channel Groups",
        render: (g) => <p className="py-1">{g.name}</p>,
        filterValue: (
          <InputField
            search
            id="search-group"
            placeholder="Filter"
            outline
            required
            onChange={handleTermChange}
          />
        ),
      },
      {
        label: "Imported",
        render: (g) => `${g.channels.importCount}/${g.channels.count}`,
        align: "text-center pr-3",
      },
    ],
    [all, channelKeys, handleCheckChange, handleSelectAll, handleTermChange]
  );

  const { dataSorted, headerConfig } = useSortableData(
    filteredGroups,
    apiConfig
  );

  return (
    <div className="drawer-section px-3">
      <main>
        <div className="flex justify-center">{toast.element}</div>
        <div className="tsnw">
          {`${dataSorted.length} Groups [ ${getTotal(dataSorted)} Channels ]`}
        </div>
        <Table filtering config={headerConfig} dataSource={dataSorted} />
      </main>
      <div className="flexe space-x-4">
        <span>{selectedCount} Groups selected</span>
        <Divider />
        <Button
          id="cancel-btn"
          outline
          content="Cancel"
          onClick={() => {
            mutations.onCancel();
            mutations.onClose();
          }}
        />
        <Button
          id="import-btn"
          content="Stage Groups"
          disabled={!selectedCount}
          loading={stagingMutation.isLoading}
          onClick={handleStageImport}
        />
      </div>
    </div>
  );
};

export default GroupImportForm;
