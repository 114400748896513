import { Button } from ".";
import { useSidebar } from "../store/hooks";

const Link = ({ to, label }) => {
  const { onNavigate } = useSidebar();

  return <Button outline content={label} onClick={() => onNavigate(to)} />;
};

export default Link;
