import { useLocation } from "react-router-dom";
import { Link } from ".";

const Crumb = ({ element, link }) => {
  return (
    <li>
      <Link
        to={`${link}`}
        label={element === "repo" ? "Repository" : element}
      />
    </li>
  );
};

const Breadcrumb = () => {
  let currLink = "";
  const location = useLocation();
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb)
    .map((crumb) => {
      currLink += `/${crumb}`;
      return <Crumb key={crumb} element={crumb} link={currLink} />;
    });

  return (
    <nav aria-label="Breadcrumb">
      <ol className="breadcrumb-ol">{crumbs}</ol>
    </nav>
  );
};

export default Breadcrumb;
