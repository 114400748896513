import { useCallback, useEffect, useRef } from "react";
import { FaRegFolderOpen as FolderIcon } from "react-icons/fa";
import { LiaFileMedicalAltSolid as FileIcon } from "react-icons/lia";
import { Button } from ".";

function convertToParent(files) {
  const parent = {};

  Object.values(files).forEach((element) => {
    const splitPath = element.webkitRelativePath.split("/");

    if (splitPath.length === 1) {
      parent[splitPath[0]] = element;
    } else {
      let tempPointer = parent,
        i = 0;

      while (i < splitPath.length - 1) {
        let currSplit = tempPointer[splitPath[i]];
        if (currSplit) tempPointer = currSplit;
        else {
          tempPointer[splitPath[i]] = {};
          tempPointer = tempPointer[splitPath[i]];
        }
        i++;
      }
      tempPointer[element.webkitRelativePath] = element;
    }
  });

  return parent;
}

function readTemplate(template, data) {
  for (let [key, file] of Object.entries(template)) {
    let files;
    const isDirectory = !Boolean(file.size);

    if (isDirectory) {
      files = {};
      readTemplate(file, files);
    }

    const currObj = {
      index: key,
      isDirectory,
      count: 1,
      size: file.size,
    };

    if (isDirectory) {
      currObj.files = files;
      let size = 0,
        count = 0;

      for (let child of Object.values(files)) {
        size += child.size;
        count += child.count;
      }

      currObj.size = size;
      currObj.count = count;
    } else currObj.file = file;

    data[key] = currObj;
  }

  return data;
}

// and process the uploaded file.
// @param multiple: allows to upload multiple files at once.
const FileInput = ({ id, multiple, accept, onChange }) => {
  const fileInputRef = useRef(null);

  const handleFileInput = useCallback(
    (e) => {
      const files = [];
      for (let file of e.target.files) {
        files.push({
          index: file.name,
          isDirectory: false,
          size: file.size,
          count: 1,
          file,
        });
      }

      fileInputRef.current.value = null;
      onChange(files);
    },
    [onChange]
  );

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        id={id || "file-input"}
        ref={fileInputRef}
        hidden
        type="file"
        accept={accept}
        multiple={multiple}
        onChange={handleFileInput}
      />
      <Button icon={{ icon: FileIcon, size: 18 }} onClick={handleClick} />
    </>
  );
};

// and process the uploaded file.
// @param multiple: allows to upload multiple files at once.
const FolderInput = ({ id, accept, onChange }) => {
  // const [root, setRoot] = useState(null);
  const folderInputRef = useRef(null);

  useEffect(() => {
    if (folderInputRef.current) {
      folderInputRef.current.setAttribute("directory", "");
      folderInputRef.current.setAttribute("webkitdirectory", "");
      folderInputRef.current.setAttribute("mozdirectory", "");
    }
  }, [folderInputRef]);

  const handleFileInput = useCallback(
    (e) => {
      const files = e.target.files;

      // const mappedFiles = Object.values(files).map((file) => {
      //   const { name, webkitRelativePath, size } = file;
      //   return { name, webkitRelativePath, size, file };
      // });

      const template = convertToParent(files);
      const value = readTemplate(template, {});

      folderInputRef.current.value = null;
      onChange(Object.values(value)[0]);
    },
    [onChange]
  );

  const handleClick = () => {
    folderInputRef.current.click();
  };

  return (
    <>
      <input
        id={id || "folder-input"}
        ref={folderInputRef}
        type="file"
        accept={accept}
        onChange={handleFileInput}
        multiple
        hidden
      />
      <Button icon={{ icon: FolderIcon, size: 18 }} onClick={handleClick} />
    </>
  );
};

export default FileInput;
export { FolderInput };
