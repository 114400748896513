import { Checkbox } from "../../components";
import {
  MdCheckCircleOutline as CheckIcon,
  MdRadioButtonUnchecked as UnCheckIcon,
} from "react-icons/md";

export const PolicyRow = ({ category, action, editable, onToggle }) => {

  return (
    <div className="flex items-center justify-between">
      <span className="text-[14px]">{action.action}</span>
      {editable ? (
        <Checkbox
          id={`action-${action.actionId}`}
          checked={action.allowed}
          onChange={({ target }) =>
            onToggle(category, action.actionId, target.checked)
          }
        />
      ) : action.allowed ? (
        <CheckIcon size={20} color="green" />
      ) : (
        <UnCheckIcon size={20} color="gray" />
      )}
    </div>
  );
};
