import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdFitbit as InterfaceIcon } from "react-icons/md";
import { Panel, PanelHeader } from "../../components";
import { Urls, removeKey } from "../../store/axios";
import { useAxiosPrivate, useCommon } from "../../store/hooks";
import { InterfaceList } from "./InterfaceList";
import { ServiceCharts } from "./ServiceCharts";

const initialState = { page: 1, limit: 24 };

const Interfaces = () => {
  const filtersRef = useRef(false);
  const axiosPrivate = useAxiosPrivate();
  const { loadCommon } = useCommon();
  const [filters, setFilters] = useState(initialState);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["services"],
    queryFn: async () => {
      const terms = removeKey(
        {
          searchTerm: filters.name,
          exchange: filters.exchange,
          pageSize: filters.limit,
          page: filters.page,
        },
        "All"
      );
      return await axiosPrivate.post(Urls.services, terms);
    },
    enabled: !filtersRef.current,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (filtersRef.current) return;
    filtersRef.current = true;

    if (
      filtersRef.current &&
      Object.keys(filters).length > Object.keys(initialState).length
    )
      refetch();

    return () => (filtersRef.current = false);
    // eslint-disable-next-line
  }, [filters]);

  const handleSourceChange = useCallback((debounce) => {
    setFilters((curr) => {
      return { ...curr, name: debounce };
    });
  }, []);

  const handleChange = useCallback((selection) => {
    setFilters((curr) => ({ ...curr, exchange: selection }));
  }, []);

  const handleRefresh = useCallback(() => {
    refetch();
    loadCommon();
    // eslint-disable-next-line
  }, []);

  // Update filters on current page change
  // or page limit (records per page) change
  const handlePaginate = useCallback(
    (type, value) => {
      setFilters((curr) => {
        if (type === "page") curr.page = value;
        else {
          const { limit, totalCount } = value;
          const lastExpectedPage = Math.ceil(totalCount / limit);
          curr.limit = limit;
          if (curr.page > lastExpectedPage) curr.page = lastExpectedPage;
        }

        return curr;
      });

      refetch();
      window.scrollTo({ top: 300, behavior: "smooth" });
    },
    // eslint-disable-next-line
    []
  );

  const services = data?.data || {};

  return (
    <div className="w-full space-y-4">
      <Panel>
        <PanelHeader
          PanelIcon={InterfaceIcon}
          title="DIS Interfaces"
          subtitle="Integration Services"
        />
      </Panel>

      <ServiceCharts sourcesCount={services?.total} onRefresh={handleRefresh} />

      <InterfaceList
        services={services}
        filters={filters}
        loading={isFetching}
        onTypeChange={handleChange}
        onNameChange={handleSourceChange}
        onPaginate={handlePaginate}
      />
    </div>
  );
};

export default Interfaces;
