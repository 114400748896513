import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FiCheckCircle as SuccessIcon } from "react-icons/fi";
import { LuFolderSync as UpdateIcon } from "react-icons/lu";
import { Button } from "../../components";
import { useAxiosPrivate, useCommon, useToast } from "../../store/hooks";

export const InterfaceBuild = ({ onClose }) => {
  const [complete, setComplete] = useState(false);
  const { loadCommon } = useCommon();
  const toast = useToast();
  const client = useQueryClient();
  const axiosPrivate = useAxiosPrivate();

  const buildMutation = useMutation({
    mutationFn: async () => await axiosPrivate.post("/mirth/services"),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["services"] });
      loadCommon();
      setComplete(true);
    },
    onError: ({ response }) => {
      const { detail } = response.data;
      toast.warn(detail);
    },
    retry: false,
  });

  return (
    <div className="flex-ij-col space-y-3 mb-4">
      {complete ? (
        <>
          <span className="text-green-600">
            <SuccessIcon size={48} />
          </span>
          <p className="font-semibold">Build completed successfully.</p>
          <Button content="Close Window" onClick={onClose} />
        </>
      ) : (
        <>
          <span className="text-secondary">
            <UpdateIcon size={48} />
          </span>
          <p className="font-semibold">Sync Mirth Connect Servers</p>
          <p className="text-center leading-tight">
            Click the button below to sync the EIIR Repository
            <br />
            <span>with the latest changes</span>
          </p>
          {toast.element}

          <Button
            content="Build Repository"
            onClick={() => buildMutation.mutate()}
            loading={buildMutation.isLoading}
          />
        </>
      )}
    </div>
  );
};
