import { useState, useLayoutEffect } from "react";
import { createPortal } from "react-dom";

// Create an element within the provider ID
// if such an element is not found in the DOM
function createWrapper(wrapperId) {
  const element = document.createElement("section");
  element.setAttribute("id", wrapperId);
  // element.setAttribute("class", "portal");
  document.body.appendChild(element);

  return element;
}

// The Portal component accepts the wrapperId property,
// which is the ID attribute of a DOM element and acts as the container for the portal.
const Portal = ({ children, wrapperId }) => {
  const [wrapperElement, setWrapperElement] = useState(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);

    // Add a flag when a new wrapper element is created
    let created = false;

    if (!element) {
      created = true;
      element = createWrapper(wrapperId);
    }

    setWrapperElement(element);

    // Cleanup
    return () => {
      // delete the newly created element
      if (created) element.parentNode.removeChild(element);
    };
  }, [wrapperId]);

  // wrapperElement state will be null on the very first render
  return wrapperElement ? createPortal(children, wrapperElement) : null;
};

export default Portal;
