import { useCallback, useState } from "react";
import { Panel, PanelBody, ToggleSwitch } from "../../components";
import { useTimeout, useToast } from "../../store/hooks";
import { PhoneChange } from "./PhoneChange";

export const ShowProfile = ({ self, account, invalidate }) => {
  const { warn, clear, element: toastEl } = useToast();
  const [updates, setUpdates] = useState({
    active: account.status === "A",
    locked: account.accountStatus !== "Unlocked",
    phone: account.phone,
  });

  const [delay, setDelay] = useState(0);

  const handleClear = useCallback(() => {
    clear();
    setDelay(0);
  }, [clear]);

  const handleCancel = useCallback(() => {
    setDelay(2000);
    warn("Phone number update canceled");
  }, [warn]);

  const handleUpdate = useCallback(
    (message) => {
      if (message !== updates.error) {
        setUpdates((curr) => ({ ...curr, error: message }));
        warn(message);
      }
    },
    [updates.error, warn]
  );

  useTimeout(handleClear, delay);

  return (
    <Panel>
      <PanelBody>
        <div>
          <div className="userprofile">
            <div>
              <span className="header">Public Id:</span>
              <span>{account.publicId}</span>
            </div>
            <div>
              <span className="header">Name Display:</span>
              <span>{account.name.display}</span>
            </div>
            <div>
              <span className="header">User Role:</span>
              <span>{account.role.name}</span>
            </div>
            <div>
              <span className="header">Access Type:</span>
              <span>eiir authentication</span>
            </div>
            <div>
              <span className="header">Created On:</span>
              <span>{account.createdOn}</span>
            </div>
            <div>
              <span className="header">Department:</span>
              <span>{account.department}</span>
            </div>
            <div>
              <span className="header">Last Modified:</span>
              <span>{account.updatedOn}</span>
            </div>
            <div className="flex-i">
              <span className={`header${self ? " mb-[16px]" : ""}`}>
                Phone:
              </span>
              <div className="flex mt-2 items-start">
                {self ? (
                  <>
                    <PhoneChange
                      phone={updates.phone}
                      user={account.publicId}
                      invalidate={invalidate}
                      onError={handleUpdate}
                      onChange={() => handleUpdate(null)}
                      onCancel={handleCancel}
                    />
                    {toastEl}
                  </>
                ) : (
                  <span>{updates.phone}</span>
                )}
              </div>
            </div>
            <div className="flex">
              <span className="header">Active:</span>
              <div className="mt-2">
                <ToggleSwitch
                  disabled
                  id="active-switch"
                  allowed={updates.active}
                />
              </div>
            </div>
            <div className="flex">
              <span className="header">Account Locked:</span>
              <div className="mt-2">
                <ToggleSwitch
                  disabled
                  id="lock-switch"
                  allowed={updates.locked}
                />
              </div>
            </div>
          </div>
        </div>
      </PanelBody>
    </Panel>
  );
};
