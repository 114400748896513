import api, { headers, JsonResponse } from "../axios";

const useLogin = () => {
  const login = async (user, pwd) => {
    // To post the FormData using axios,
    // you need specify in header that you are sending FormData,
    // for that content-type should be multipart/form-data.
    try {
      let config = {
        headers,
        withCredentials: true,
        auth: { username: user, password: pwd },
      };
      const resp = await api.post(
        "/users/auth",
        { username: user, password: pwd },
        config
      );

      return JsonResponse(resp);
    } catch ({ code, response }) {
      const { data, status } = response || {
        data: { detail: "(500) Internal Server Error" },
      };

      return code === "ERR_NETWORK"
        ? {
            status: status || 503,
            code: code || "Network Error",
            message: "Repository Service Unavailable",
          }
        : {
            status,
            message: data.detail,
          };
    }
  };

  return login;
};

export default useLogin;
