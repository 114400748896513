import Checkbox from "./Checkbox";

const RepoTreeItem = ({
  id,
  label,
  description,
  checked,
  children,
  onChange,
}) => {
  return (
    <li key={id}>
      <div className="flex">
        <Checkbox id={id} checked={checked} onChange={onChange} />
        <div>
          <p className="tree-label">{label}</p>
          <p className="description">{description}</p>
        </div>
      </div>
      {children}
    </li>
  );
};

const TreeView = ({ id, caption, children }) => {
  return (
    <div key={id} id={id}>
      <p className="tree-label">{caption}</p>
      <ul className="tree">{children}</ul>
    </div>
  );
};

export default TreeView;
export { RepoTreeItem };
