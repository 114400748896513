import { useCallback } from "react";
import { Modal } from "../../components";
import useOpenState from "./use-openstate";

const useModal = (Component) => {
  const { isOpen, dismiss, showWindow } = useOpenState();

  const showModal = useCallback(() => {
    showWindow();
  }, [showWindow]);

  return {
    modal: (
      <Modal isOpen={isOpen} onClose={dismiss}>
        <Component onClose={dismiss} />
      </Modal>
    ),
    showModal,
  };
};

export default useModal;
