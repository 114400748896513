import hcaLogo from "../../assets/images/hca.png";
import { Card, Divider, StackedAvatar } from "../../components";

const shortNames = {
  Anesthesia: "ANES",
  "Emergency Medicine": "EM",
  "Hospital Medicine": "HM",
  Radiology: "RAD",
  Surgical: "SURG",
  "Women's and Children's": "WC",
};

function getCounts(locations) {
  const locationCount = locations.length;
  const services = locations.reduce((sObj, l) => {
    for (let s of l.specialty) {
      if (!(s.display in sObj)) sObj[s.display] = true;
    }
    return sObj;
  }, {});

  const shortLabels = Object.keys(services).map((s) => shortNames[s]);

  return {
    locationCount,
    serviceCount: Object.keys(services).length,
    shortLabels,
  };
}

const SummaryHeader = ({ org, locations }) => {
  const { locationCount, serviceCount, shortLabels } = getCounts(locations);

  return (
    <Card sx={{ padding: 20 }}>
      <div className="flex items-center justify-start ml-16 space-x-5">
        {org.type === "team" ||
          (org.name === "HCA" && (
            <>
              <img src={hcaLogo} width={100} alt="HCA Logo" />
              <Divider />
            </>
          ))}
        <div className="leading-tight flex flex-col w-auto min-w-min">
          <span className="text-lg">{org.name}</span>
          <span className="-mt-1 text-sm text-[#E55A27] font-semibold">
            {locationCount} location(s), {serviceCount} service(s)
          </span>
        </div>
        <StackedAvatar labels={shortLabels || []} />
      </div>
    </Card>
  );
};

export { SummaryHeader };
