import { useCallback, useState } from "react";
import { Switcher, SwitchRoute } from "../../components";
import { useAxiosPrivate } from "../../store/hooks";
import { AccountCreate } from "./AccountCreate";
import { ReviewCreate } from "./ReviewCreate";
import { ResponseCreate } from "./ResponseCreate";
import { RoleCreate } from "./RoleCreate";

const NewUserForm = ({ onClose }) => {
  const [currIndex, setCurrIndex] = useState("0");
  const [values, setValues] = useState({});
  const { status, message } = values["2"] || {};
  const axiosPrivate = useAxiosPrivate();

  // Takes the user to another screen.
  // If offset is -1, then the user goes back to the previous screen,
  // otherwise, if offset is 1, then proceeds to the next screen
  const handleStep = (index, payload) => {
    if (payload)
      setValues((curr) => {
        return {
          ...curr,
          [currIndex]: payload,
        };
      });
    setCurrIndex(index);
  };

  const handleClose = useCallback(
    (success = false) => {
      onClose(success);
      setValues({});
    },
    [onClose]
  );

  return status ? (
    <ResponseCreate
      status={status}
      message={message}
      onClose={() => handleClose(true)}
      email={values["0"].email}
      pwd={values["0"].generatedPwd}
    />
  ) : (
    <Switcher activeIndex={currIndex}>
      <SwitchRoute>
        <AccountCreate
          api={axiosPrivate}
          onClose={handleClose}
          onNext={(payload) => handleStep("1", payload)}
        />
      </SwitchRoute>
      <SwitchRoute>
        <RoleCreate
          api={axiosPrivate}
          onPrevious={() => handleStep("0")}
          onNext={(payload) => handleStep("2", payload)}
        />
      </SwitchRoute>
      <SwitchRoute>
        <ReviewCreate
          values={values}
          api={axiosPrivate}
          onPrevious={() => handleStep("1")}
          onCreate={(response) => handleStep("3", response)}
        />
      </SwitchRoute>
    </Switcher>
  );
};

export default NewUserForm;
