import { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [currLink, setCurrLink] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const root = document.getElementById("root");
    const sidebar = root.querySelector(".sidebar");
    const toggle = root.querySelector(".toggle");

    const handleToggleClose = () => sidebar.classList.toggle("close");
    toggle.addEventListener("click", handleToggleClose);

    return () => toggle.removeEventListener("click", handleToggleClose);
  }, []);

  const onNavigate = useCallback((pathname) => {
    navigate(pathname);
    setCurrLink(pathname);
    // eslint-disable-next-line
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        currLink,
        onNavigate,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
