import { useRef, useState } from "react";
import { IoCloudUploadOutline as UploadIcon } from "react-icons/io5";

const KB = 1024;
let _dragCounter = 0;

function convertToKB(size) {
  return Math.round((100 * size) / KB) / 100;
}

const DragOverlay = () => {
  return (
    <div className="w-full h-full absolute z-[1049] bg-neutral-100 opacity-80 border-2 border-dashed border-neutral-500">
      <div className="flex-ij h-full w-full">
        <p className="text-sm font-semibold">Drop your HL7 files here!</p>
      </div>
    </div>
  );
};

const DragNDrop = ({ multiple, onChange }) => {
  const [dragging, setDragging] = useState(false);
  const dragRef = useRef(null);

  const handleFileChange = (selectedFiles) => {
    let files = [];
    for (let file of selectedFiles) {
      files.push({
        index: file.name,
        isDirectory: false,
        size: convertToKB(file.size),
        count: 1,
        file,
      });
    }

    dragRef.current.value = null;
    onChange(files);
  };

  const handleDragIn = (e) => {
    // prevents the default behavior of the browser to open the file
    e.preventDefault();
    // stops the event from being propagated through parent and child elements
    e.stopPropagation();

    // If there are child elements inside the drag and drop,
    // the drag events will fire on those nested elements as weel
    // so we want to track of how many elements deep the cursor is
    _dragCounter++;

    // show the overlay if the drag event has any files
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0)
      setDragging(true);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();

    _dragCounter--;

    // only set call once the cursor is all the way out
    if (_dragCounter === 0) setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  //  Handle the drop event.
  //
  //  When files are dropped, hide the overlay,
  //  check that ere are indeed some (acceptable) files included,
  //  and pass the array to our callback.
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);

    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles && droppedFiles.length > 0) {
      handleFileChange(droppedFiles);
      _dragCounter = 0;
      e.dataTransfer.clearData();
    }
  };

  return (
    <form className="flex-ij w-full" encType="multipart/form-data">
      <label
        htmlFor="dropzone-file"
        className="inline-block relative flex-ij-col w-full py-7 space-y-1 border border-slate-300 border-dashed rounded-sm bg-slate-50 hover:bg-slate-100 text-neutral-600 cursor-pointer"
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {dragging && <DragOverlay />}
        <UploadIcon size={24} className="mt-1" />
        {multiple ? (
          <p>Drag and drop files and folders you want to analyze here</p>
        ) : (
          <section className="flex-ij flex-col leading-tight">
            <span className="text-neutral-600">
              Drag and drop an HL7 message
            </span>{" "}
            <span className="font-semibold text-orange-600 text-[14px]">
              or browse for file
            </span>
          </section>
        )}
        <input
          ref={dragRef}
          id="dropzone-file"
          type="file"
          className="hidden"
          accept=".data,.hl7,.txt"
          multiple={multiple}
          onChange={({ target }) => handleFileChange(target.files)}
        />
      </label>
    </form>
  );
};

export default DragNDrop;
