import api, { Urls, headers } from "../axios";
import useAuth from "./use-auth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refreshToken = async () => {
    const { data } = await api.get(Urls.refresh, {
      headers,
      withCredentials: true,
    });

    setAuth(data);
    return data.token;
  };

  return refreshToken;
};

export default useRefreshToken;
