import { useCallback, useState } from "react";
import { useTimeout } from "../store/hooks";

function getInitialTrans(slide, fade) {
  return slide ? "translate-x-full" : fade ? "opacity-0 invisible" : "";
}

const Animate = ({ id, slide, fade, children }) => {
  const [css, setCss] = useState(() => getInitialTrans(slide, fade));

  const cssHandler = useCallback(
    () => setCss(() => (slide ? "animate-slide" : fade ? "animate-fade visible" : "")),
    [slide, fade]
  );

  useTimeout(cssHandler, 150);

  return (
    <main id={id} className={`w-full h-full transform transition ${css}`}>
      {children}
    </main>
  );
};

export default Animate;
