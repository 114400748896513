import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Divider, HorizontalRuler } from "../../components";
import { createInput } from "../../form/FormCreate";
import { required } from "../../form/validation-rules";
import {
  useAttempt,
  useForm,
  useLocalStorage,
  useLogin,
  useToast,
} from "../../store/hooks";
import { SiOkta } from "react-icons/si";

const lockWarning = (
  <>
    <p>
      This account has reached the maximum number of failed login attempts and
      has been locked.
    </p>
    <p className="mt-3 text-neutral-600">
      Please contact your EIIR Administrator!
    </p>
  </>
);

export const LoginForm = ({ onContinue, onForget, onLockUser }) => {
  const { element: toastEl, warn } = useToast("login-toast");
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const { isBelowThreshold, canRetry, resetCount, recordMistake } =
    useAttempt(7);
  const login = useLogin();

  // handle local storage functionality
  const [storage, storeItem] = useLocalStorage({ persist: false, user: "" });
  const { persist, user: email } = storage || {};

  // Input control configuration.
  // Note: the id must be the same as the config object key
  //  to pass the use-form validation and change events.
  const loginConfig = useMemo(
    () => ({
      username: {
        type: "input",
        ...createInput({
          id: "username",
          label: "Username",
        }),
        required: true,
        validateOnBlur: true,
        defaultValue: email,
        validationRules: [required("Username")],
      },
      password: {
        type: "input",
        ...createInput({
          type: "password",
          id: "password",
          label: "Password",
        }),
        required: true,
        validateOnBlur: true,
        validationRules: [required("Password")],
      },
    }),
    [email]
  );

  const { renderForm, resetForm, isFormValid } = useForm(loginConfig);
  const { username: userInput, password: pwdInput } = renderForm();
  const user = userInput?.props?.value;
  const pwd = pwdInput?.props?.value;

  useEffect(() => setCanSubmit(true), [user]);

  const handleForget = useCallback(() => {
    setCanSubmit(true);
    onForget();
  }, [onForget]);

  const handleFailedLogin = useCallback(
    async (user, message) => {
      if (isBelowThreshold) {
        const remaining = canRetry(user);
        if (remaining) {
          warn(
            <>
              {message} <p>Please try again or select Forgot Password below.</p>
              <p className="text-neutral-600 mt-2">
                Remaining attempts: ( {remaining} )
              </p>
            </>
          );
        } else {
          // user has exceeded maximum attempts
          const userLocked = await onLockUser(user, "failed login att");
          if (userLocked) {
            warn(lockWarning);
            resetCount();
            setCanSubmit(false);
          }
        }

        recordMistake(user);
      }
    },
    [onLockUser, warn, resetCount, isBelowThreshold, canRetry, recordMistake]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!canSubmit) return;
    const accurate = await isFormValid();

    if (accurate) {
      setLoading(true);
      storeItem("user", persist ? user : "");

      try {
        const { status, data, message } = await login(user, pwd);
        switch (status) {
          case 200:
            onContinue("login", data);
            resetForm();
            resetCount();
            break;
          case 401:
          case 404:
            handleFailedLogin(user, message);
            break;
          case 403:
            warn(lockWarning);
            setCanSubmit(false);
            break;
          default:
            warn(message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <section>
      <form id="login-form" onSubmit={handleSubmit}>
        <div className="okta">
          <label htmlFor="signOkta-btn">Sign In with</label>
          <Button circle id="signOkta-btn" icon={{ icon: SiOkta, size: 20 }} />
        </div>
        <HorizontalRuler span="Or" />
        {toastEl}
        {userInput}
        {pwdInput}
        <Checkbox
          id="persist"
          label="Remember me!"
          checked={persist}
          onChange={({ target }) => storeItem("persist", target.checked)}
        />
        <div className="flex-i">
          <Button
            type="submit"
            id="loginform-submit"
            content="SIGN IN"
            loading={loading}
          />
          <Divider sx={{ marginLeft: 16 }} />
          <Button
            outline
            id="forgotpwd-btn"
            content="Forgot Password?"
            onClick={handleForget}
          />
        </div>
      </form>
    </section>
  );
};
