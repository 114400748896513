import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Controls,
  ConnectionLineType,
} from "reactflow";
import { useCallback, useState } from "react";
import CustomNode from "./CustomNode";

const nodeTypes = {
  origin: CustomNode,
};

/*
  defined outside of the component
  to prevent re-rendering every time the FlowRender renders
  */

const FlowRender = ({
  initialNodes,
  initialEdges,
  fitView,
  zoom,
  showCotnrols,
}) => {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const handleClick = useCallback((event, node) => {
    console.log(node);
  }, []);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  return (
    <ReactFlow
      style={{ width: "100%", height: "100%" }}
      attributionPosition="bottom-left"
      connectionLineType={ConnectionLineType.SmoothStep}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      fitView={fitView}
      defaultViewport={{ x: 40, y: 20, zoom: zoom || 1 }}
      onNodeClick={handleClick}
    >
      <Background color="#aaa" gap={12} variant="dots" />
      {showCotnrols && false && <Controls />}
    </ReactFlow>
  );
};

export default FlowRender;
