import { useCallback, useEffect, useReducer, useState } from "react";
import { Button, MiniCard, MiniOption } from "../../components";
import {
  useAxiosPrivate,
  useCommon,
  useDropContainer,
  useReport,
} from "../../store/hooks";
import { ReportConnectorForm } from "./ReportConnectorForm";
import { PiChartBarDuotone as ReportIcon } from "react-icons/pi";
import {
  MdSettingsEthernet as ConnectorIcon,
  MdPublishedWithChanges as StatusIcon,
  MdToll as ChannelsIcon,
  MdDevicesOther as ServersIcon,
} from "react-icons/md";
import { Urls } from "../../store/axios";

const initialState = {
  checkList: {},
  servers: "All",
  connectors: "All",
  status: "All",
  channels: "All",
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "accept":
      const { checkList: curr, ...rest } = state;
      const isAll = Object.values(payload).every(Boolean);
      return {
        ...rest,
        checkList: { ...curr, ...payload },
        connectors: isAll ? "All" : "Some",
      };
    case "checks":
      return { ...state, checkList: payload };
    case "download":
      return { ...state, downloading: payload };
    case "status":
      return { ...state, [type]: payload };
    case "reset":
      return initialState;
    default:
      return state;
  }
};

export const ChannelsReports = ({ onAlert }) => {
  const [downloading, setDownloading] = useState(false);
  const [filters, dispatch] = useReducer(reducer, initialState);
  const { sources } = useCommon();
  const { client, xportControl, channelsToCSV } = useReport();
  const [droparea, divRef, showContainer, closeWindow] =
    useDropContainer("channels-rpt");
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const payload = (sources || []).reduce((reduceObj, connector) => {
      if (!(connector.connector in reduceObj))
        reduceObj[connector.alias] = true;
      return reduceObj;
    }, {});

    dispatch({ type: "checks", payload });
  }, [sources]);

  const handleClose = useCallback(() => {
    closeWindow();
  }, [closeWindow]);

  const handleDownload = useCallback(async () => {
    try {
      setDownloading(true);

      const params = {};
      if (filters.status !== "All") params.status = filters.status;
      if (filters.connectors !== "All")
        params.connectors = Object.entries(filters.checkList)
          .filter(([, value]) => value)
          .map(([key]) => key);

      const { data } = await client.fetchQuery({
        queryKey: ["channels-rpts"],
        queryFn: async () =>
          await axiosPrivate.post(Urls.reports.channels, params),
      });

      const csv = channelsToCSV(data);
      xportControl.exportMultiCSV(csv, "mirthchannels-rpt.csv");
      dispatch({ type: "reset" });
    } catch ({ response }) {
      const { data } = response;
      onAlert(data?.detail);
    } finally {
      setDownloading(false);
    }
    // eslint-disable-next-line
  }, [filters]);

  const handleAccept = useCallback(
    (selections) => {
      dispatch({ type: "accept", payload: selections });
      handleClose();
    },
    [handleClose]
  );

  const connectorsHandler = useCallback(() => {
    showContainer({
      content: (
        <ReportConnectorForm
          connectors={filters.checkList}
          onClose={handleClose}
          onAccept={handleAccept}
        />
      ),
    });

    // eslint-disable-next-line
  }, [handleAccept, handleClose, filters.checkList]);

  const statusHandler = useCallback(() => {
    const status =
      filters.status === "All"
        ? "Active"
        : filters.status === "Active"
        ? "Inactive"
        : "All";
    dispatch({ type: "status", payload: status });
  }, [filters.status]);

  return (
    <div className="col-span-2 ml-5 pl-5">
      <div className="flex space-x-5 ml-5 w-[80%]">
        <span className="w-12 h-12 p-2 flex-ij rounded-full border border-slate-400 bg-neutral-50 drop-shadow-sm">
          <ReportIcon color="green" size={36} />
        </span>
        <div className="text-[14px] leading-tight">
          <span className="font-semibold">Mirth Channels Report</span>
          <p>
            Export a report of all the deployed channels from one or more Mirth
            server intances filtered by connector types and/or active statuses.
          </p>
          <p className="mt-1.5">
            Use the filters available below to narrow down your selection.
          </p>
          <div className="relative">
            <MiniCard>
              <MiniOption
                label="Servers"
                value={filters.servers}
                Icon={ServersIcon}
              />
              <div id="connectors-rpt" ref={divRef} className="relative">
                <MiniOption
                  label="Connectors"
                  value={filters.connectors}
                  Icon={ConnectorIcon}
                  callback={connectorsHandler}
                />
                {droparea}
              </div>
              <MiniOption
                label="Status"
                value={filters.status}
                Icon={StatusIcon}
                callback={statusHandler}
              />
              <MiniOption
                label="Channels"
                value={filters.channels}
                Icon={ChannelsIcon}
              />
            </MiniCard>
          </div>
          <div className="mt-4">
            <Button
              content="Download Results"
              onClick={handleDownload}
              loading={downloading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
