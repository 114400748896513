import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Animate, Card, Image } from "../../components";
import api, { Urls, headers } from "../../store/axios";
import { useAuth, useCommon, useLogout } from "../../store/hooks";
import { ForgotPassword } from "./ForgotPassword";
import { LoginForm } from "./LoginForm";
import { ResetForm } from "./ResetForm";

const dashpath = "/repo/dashboard";

async function getIp() {
  const response = await fetch("https://ipapi.co/json/");
  const { ip } = await response.json();
  return ip;
}

const Login = () => {
  const [forgot, setForgot] = useState(false);
  const [trueLocation, setTrueLocation] = useState(null);
  const { auth, setAuth } = useAuth();
  const { loadCommon } = useCommon();
  const location = useLocation();
  const logout = useLogout();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.loggedOut) return;
    if (auth.token && !auth.requireChange) {
      loadCommon();
      navigate(trueLocation, { replace: true });
    }

    // eslint-disable-next-line
  }, [trueLocation, auth, loadCommon]);

  /**
   * Suspend (lock) user account.
   */
  const handleUserLock = useCallback(async (username, cause) => {
    try {
      const ipAddress = await getIp();
      const { status } = await api.post(
        Urls.suspend,
        {
          ipAddress,
          username,
          cause,
        },
        { headers }
      );
      return status === 200;
    } catch ({ response }) {
      const { status, statusText, data } = response;
      console.log(status, statusText, data?.detail);
    }
  }, []);

  /**
   * Navigate to the dashboard if a new user
   * otherwise, navigate to the last visited page.
   */
  const handleContinue = useCallback(
    (from, data = null) => {
      let to;

      if (data && from === "login") {
        to = location.state?.from?.pathname || dashpath;
        setAuth(data);
      } else {
        to = dashpath;
        setAuth((curr) => ({ ...curr, requireChange: false }));
      }
      setTrueLocation(to);
    },
    // eslint-disable-next-line
    [location]
  );

  /**
   * Event Handler: Return user back to login screen.
   */
  const handleBackLogin = useCallback(async () => {
    await logout();
    // eslint-disable-next-line
  }, []);

  const handleForget = useCallback((action) => setForgot(action), []);

  return (
    <div className="login">
      <Card sx={{ width: 428, padding: 32 }}>
        {auth.requireChange ? (
          <Animate id="reset-slide" fade>
            <ResetForm
              user={auth.email}
              onBack={handleBackLogin}
              onContinue={handleContinue}
              onLockUser={handleUserLock}
            />
          </Animate>
        ) : (
          <>
            <span className="logo">
              <Image logo width={192} />
            </span>
            {forgot ? (
              <ForgotPassword onBack={() => handleForget(false)} />
            ) : (
              <Animate id="login-slide" fade>
                <LoginForm
                  onContinue={handleContinue}
                  onForget={() => handleForget(true)}
                  onLockUser={handleUserLock}
                />
              </Animate>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default Login;
