import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useReducer } from "react";
import { LoadSpinner, Toast } from "../../components";
import api, { headers } from "../../store/axios";
import { useAxiosPrivate } from "../../store/hooks";
import GroupImportForm from "./GroupImportForm";
import { MirthLoginForm } from "./MirthLoginForm";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "data":
      const { data, session } = payload;
      return { data, session, loaded: true };
    case "error":
      return {
        loaded: state.loaded,
        data: state.data,
        error: payload,
        session: state.session,
      };
    case "fetch":
      return { loading: true, message: payload };
    case "staging":
      return { ...state, importing: false, stagingId: payload };
    default:
      return state;
  }
};

const SignInForm = ({ server, groups, dispatch }) => {
  return (
    <>
      <div className="flex-j trans300">
        {groups.loading ? (
          <LoadSpinner medium label={groups.message} />
        ) : groups.error ? (
          <Toast warning message={groups.error} />
        ) : null}
      </div>
      <section className={`${groups.loaded && "hidden-scale"}`}>
        <MirthLoginForm
          serverName={server}
          title="Download Channel Groups"
          loading={groups.loading}
          onAuthorize={dispatch}
        />
      </section>
    </>
  );
};

//  Import Mirth Channel Groups and update repository records
//  through data ingestion interface engine servers.
//
//  Quick Note:
//  ----------
//    This process is used to extract data from various Mirth Connect servers,
//    transform it into models suitable for staging and loading
//    into the EIIR repository database.

const DownloadGroupsForm = ({ server, onClose }) => {
  const [groups, dispatch] = useReducer(reducer, {});
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();

  const { name: serverName, serverId } = server;
  const serverUrlPrefix = useMemo(
    () => `/mirth/servers/${serverId}`,
    [serverId]
  );

  const handleCancel = useCallback(async () => {
    queryClient.cancelQueries({ queryKey: ["groups"] });
    await axiosPrivate.post(`${serverUrlPrefix}/logout`, {
      session: groups.session,
    });
    // eslint-disable-next-line
  }, [serverUrlPrefix, queryClient, groups.session]);

  const handleAuthorize = useCallback(
    async (username, password) => {
      let type = "data",
        payload;

      try {
        // Step [1]
        // --------
        //  Extract the relevant ChannelGroups and a list
        //  of their respective channel identifiers.
        //  This step involves reading the data from the MirthConnect server.

        dispatch({ type: "fetch", payload: "Logging into Mirth instance" });
        const { data } = await queryClient.fetchQuery({
          queryKey: ["groups"],
          queryFn: async ({ signal }) => {
            let config = {
              headers,
              withCredentials: true,
              auth: { username, password },
              signal,
            };
            return api.post(
              `${serverUrlPrefix}/channelgroups`,
              { username, password },
              config
            );
          },
        });
        const { session_id, groups } = data || {};
        payload = { session: session_id, data: groups };
      } catch ({ response }) {
        const { detail } = response?.data || {};
        const statusCode = response?.status;

        type = "error";
        payload =
          statusCode === 404
            ? "Failed to fetch Channels: Resource not found."
            : response?.status === 401 || statusCode === 403
            ? `Authorization failed: ${detail}`
            : statusCode === 504
            ? "Dowloading Groups task timed out."
            : `Internal Server Error ${detail}`;
      } finally {
        dispatch({ type, payload });
      }
    },
    // eslint-disable-next-line
    [serverUrlPrefix]
  );

  const mutationList = useMemo(
    () => ({
      dispatch,
      stage: async (selectedGroups) =>
        await axiosPrivate.post(`${serverUrlPrefix}/staging`, {
          session: groups.session,
          selectedGroups,
        }),
      onCancel: handleCancel,
      onClose,
    }),
    // eslint-disable-next-line
    [groups, serverUrlPrefix, onClose, handleCancel]
  );

  return (
    <div className="drawer-form">
      {groups.loaded ? (
        <GroupImportForm groups={groups.data || []} mutations={mutationList} />
      ) : (
        <SignInForm
          server={serverName}
          groups={groups}
          dispatch={handleAuthorize}
        />
      )}
    </div>
  );
};

export default DownloadGroupsForm;
