import { useCallback, useEffect, useState } from "react";
import { Button, Panel, PanelBody } from "../../components";
import {
  createDropdown,
  createInput,
  createRadioGroup,
} from "../../form/FormCreate";
import { useAxiosPrivate, useForm, useReport } from "../../store/hooks";
import { Urls } from "../../store/axios";

export const InventoryReports = () => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { renderForm, resetForm } = useForm(reportsConfig);
  const { client, xportControl } = useReport();
  const axiosPrivate = useAxiosPrivate();

  const { invradios, rptName, reportstatus } = renderForm();
  const { radios: controls, value: selection } = invradios;

  const name = rptName?.props?.value;
  const status = reportstatus?.props?.value;

  useEffect(() => {
    const enabled = !!selection && /^[\w\s-]+\.csv/.test(name);
    setDisabled(!enabled);
  }, [name, selection]);

  const handleDownload = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await client.fetchQuery({
        queryKey: ["inventory-rpts", selection, status],
        queryFn: async () =>
          await axiosPrivate.post(Urls.reports.inventory, {
            selection,
            status,
          }),
      });

      const filename = `${status.toLowerCase() || "all"}-${name}`;
      xportControl.exportCSV(data, filename);
      resetForm();
    } catch ({ response }) {
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [name, selection, status, client]);

  return (
    <div className="h-full w-full">
      <div className="stepper">
        <ul>
          {["first", "between", "last"].map((name, i) => (
            <li key={name}>
              <div className={name}>
                <span>{i + 1}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex space-x-4 h-full">
        <div className="w-[30%]">
          <Panel>
            <PanelBody>
              <p className="font-semibold mb-2 pb-2 text-[15px]">
                Select Report
              </p>
              <ol className="flex flex-col space-y-1">
                {Object.entries(controls).map(([key, radio]) => (
                  <li key={key}>{radio}</li>
                ))}
              </ol>
            </PanelBody>
          </Panel>
        </div>

        <div className="w-[50%] h-full">
          <Panel>
            <PanelBody>
              <p className="font-semibold mb-2 pb-2 text-[15px]">
                Customize Your Report
              </p>
              <div className="h-40 space-y-3">
                {rptName}
                <div className="ml-1.5"> {reportstatus}</div>
              </div>
            </PanelBody>
          </Panel>
        </div>

        <div className="w-[20%] p-4">
          <p className="font-semibold text-[15px] mb-1">Generate Report</p>
          <Button
            onClick={handleDownload}
            disabled={disabled}
            loading={loading}
          >
            <span className="w-44">Download Report</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

const reportsConfig = {
  invradios: {
    type: "radio-group",
    required: true,
    ...createRadioGroup({
      name: "inventorygrp",
      group: {
        summary: {
          id: "summary",
          value: "summary",
          label: "Inventory Summary",
        },
        orgs: {
          id: "orgs",
          value: "orgs",
          label: "Managing Organizations",
        },
      },
    }),
  },
  rptName: {
    type: "input",
    ...createInput({
      id: "rptName",
      label: "Report Name",
      helperText: "Example: inventory-summary.csv",
    }),
    required: true,
  },
  reportstatus: {
    type: "dropdown",
    ...createDropdown({
      id: "reportstatus",
      name: "rptstatus",
      helperText: "Active Status",
    }),
    items: ["All", "Active", "Inactive"],
    value: "All",
  },
};
