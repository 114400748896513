import { useCallback, useState } from "react";

function checkInputValid(value, rules, validCallback = null) {
  if (!rules) return "";

  let message = "",
    checks = {};

  for (const rule of rules) {
    if (rule.blurOnly) continue;

    let valid = true;
    if (!rule.validate(value)) {
      valid = false;
      message = rule.message;
    }

    checks[rule.name] = valid;
  }

  if (validCallback) validCallback(checks);
  return message;
}

async function checkInputExtraValid(rules, value) {
  if (!rules) return "";
  for (let rule of rules) {
    const valid = await rule.validate(value);
    if (!valid) return rule.message;
  }
  return "";
}

const useForm = (formObj, validCallback = null) => {
  const [form, setForm] = useState(formObj);

  const falseHandler = useCallback((valid) => !valid, []);

  const validateInput = useCallback(
    async (inputObj, blurCallback, isSubmitting = false) => {
      const value = inputObj.value || inputObj.defaultValue;

      // update the input control validity
      let error = checkInputValid(
        value,
        inputObj.validationRules,
        validCallback
      );
      let valid = error === "";

      if (inputObj.valid && inputObj.extraRules) {
        error = await checkInputExtraValid(inputObj.extraRules, value);
        valid = error === "";
      }

      inputObj.error = error;
      inputObj.valid = valid;

      // mark it as touched
      if (!(inputObj.touched || isSubmitting)) inputObj.touched = true;
    },
    [validCallback]
  );

  const inputHandler = useCallback(
    async (event, isBlurred = false, blurCallback = null) => {
      const { id, value } = event.target;

      // copy the input control whose value has changed
      const inputObj = form[id];
      // update value
      inputObj.value = value;

      if (!inputObj.validateOnBlur || (inputObj.validateOnBlur && isBlurred)) {
        await validateInput(inputObj, blurCallback);
        if (inputObj.onInputChange) inputObj.onInputChange(id, value);
      }

      setForm({ ...form, [id]: inputObj });
      if (blurCallback) blurCallback(value);
    },
    [form, validateInput]
  );

  const selectionHandler = useCallback((id, value) => {
    if (id && value)
      setForm((curr) => ({ ...curr, [id]: { ...curr[id], value } }));
  }, []);

  const checkHandler = useCallback(
    (event) => {
      const { id, checked } = event.target;
      setForm({ ...form, [id]: { ...form[id], checked } });
    },
    [form]
  );

  const radioGroupHandler = useCallback(
    (key, value) =>
      setForm({ ...form, [key]: { ...form[key], value, touched: true } }),
    [form]
  );

  /**
   * Indicates whether an overall form is free from errors.
   *
   * Each input has an error message and a valid status associated with it.
   * The error message will only be displayed when the errorMessage prop
   * contains a value and the input is not valid.
   *
   * To specify if an input is valid, it must:
   * 1: have a default value
   * 2: have any value if required
   *
   * @returns true if form is valid, false otherwise.
   */
  const isFormValid = useCallback(async () => {
    const arr = [];
    // verify form is validated when untouched
    let modified = false;
    // copy input object if validation is needed for untouched inputs
    const currForm = { ...form };

    for (let [id, control] of Object.entries(currForm)) {
      if (control.type === "input") {
        if (control.required) {
          await validateInput(control, null, true);
          currForm[id] = control;

          if (control.touched) {
            // use current control state
            arr.push(control.valid);
          } else {
            if (!modified) modified = true;
            // if true, the control either has a default value
            // or the autocomplete is set
            arr.push(
              (!!control.defaultValue || !!control.value) && control.valid
            );
            continue;
          }
        }

        if (!control.valid && control.defaultValue === undefined)
          arr.push(false);
      } else if (control.type === "radio-group") {
        if (!control.value) arr.push(false);
      }
    }

    if (modified) setForm(currForm);
    return !arr.some(falseHandler);
  }, [form, validateInput, falseHandler]);

  const resetForm = useCallback(() => {
    const currForm = { ...form };
    for (let control of Object.values(currForm)) {
      if (control.type === "input") {
        control.value = control.error = "";
        control.touched = control.valid = false;
      } else if (control.type === "radio-group") {
        control.value = "";
      }
    }
    setForm(currForm);
  }, [form]);

  const renderForm = useCallback(() => {
    const controls = {};

    for (let key of Object.keys(form)) {
      const { type, renderControl, ...rest } = form[key];

      switch (type) {
        case "input":
        case "phone":
          controls[key] = renderControl(
            rest.touched,
            rest.value,
            rest.defaultValue,
            rest.error,
            inputHandler,
            rest.focusHandler,
            (event) => inputHandler(event, true, rest.onBlur),
            rest.required
          );
          break;

        case "check":
        case "simple":
          controls[key] = renderControl(rest.checked, checkHandler);
          break;

        case "radio-group":
          controls[key] = renderControl(
            key,
            rest.touched,
            rest.value,
            rest.defaultValue,
            radioGroupHandler
          );
          break;

        case "dropdown":
          const { items, value: selection } = rest;
          controls[key] = renderControl(items, selection, selectionHandler);
          break;

        default:
          return null;
      }
    }

    return controls;
  }, [form, inputHandler, radioGroupHandler, selectionHandler, checkHandler]);

  return { renderForm, isFormValid, resetForm, setForm };
};

export default useForm;
