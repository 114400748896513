import { forwardRef, useCallback, useRef, useState } from "react";
import { HelperText } from ".";
import { VscEyeClosed as OffIcon, VscEye as OnIcon } from "react-icons/vsc";
import { MdClose as ClearIcon } from "react-icons/md";

const searchClass =
  "absolute right-2 top-0 pt-3 text-gray-400 cursor-pointer transition-all duration-300 hover:text-primarydark";

const InputField = (
  {
    type,
    search,
    outline,
    label,
    placeholder,
    helperText,
    errorMessage,
    touched,
    width,
    required,
    onChange,
    ...props
  },
  ref
) => {
  const [curr, setCurr] = useState({ type, Icon: OnIcon });
  const [editing, setEditing] = useState(false);

  const inputRef = useRef(null);
  const currRef = ref || inputRef;
  let inputClass = [];
  if (outline) inputClass.push("outline");
  if (errorMessage) inputClass.push("error");

  const helperOrErr = !!errorMessage ? (
    <div id="error">{errorMessage}</div>
  ) : (
    helperText && <HelperText label={helperText} />
  );

  const handleChange = useCallback(
    (event) => {
      if (currRef.current) {
        const { value } = currRef.current;
        if (value) {
          if (!editing) setEditing(true);
        } else if (editing) setEditing(false);
      }
      onChange(event);
    },
    // eslint-disable-next-line
    [editing, onChange]
  );

  const handleCancelEdit = useCallback(() => {
    currRef.current.value = "";
    onChange({ target: { value: "" } });
    setEditing(false);
    // eslint-disable-next-line
  }, [onChange]);

  const handleClick = useCallback(() => {
    setCurr((curr) => {
      return curr.type === "password"
        ? { type: "text", Icon: OffIcon }
        : { type, Icon: OnIcon };
    });
  }, [type]);

  const renderView = type === "password" && (
    <div
      className="absolute top-3 right-2 pl-2 cursor-pointer"
      onClick={handleClick}
    >
      <curr.Icon color="#606060" />
    </div>
  );

  return (
    <div className="input-container" style={{ width }}>
      <input
        type={curr.type}
        autoComplete="off"
        ref={currRef}
        className={inputClass.join(" ")}
        placeholder={placeholder}
        onChange={handleChange}
        {...props}
      />
      {search && editing && (
        <div className={searchClass} onClick={handleCancelEdit}>
          <ClearIcon size={18} />
        </div>
      )}
      <label htmlFor={props.id}>
        {label} {!required && "(optional)"}
      </label>

      {helperOrErr}
      {renderView}
    </div>
  );
};

export default forwardRef(InputField);
