import { useEffect, useState } from "react";
import { useAlert, useForm } from "../../store/hooks";
import { Button } from "../../components";
import { createInput } from "../../form/FormCreate";
import { required, isValidEmail } from "../../form/validation-rules";
import { BsCheck2Circle as VerifiedIcon } from "react-icons/bs";
import { Urls } from "../../store/axios";

export const UserCreate = ({ api, onVerify, onReset }) => {
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(null);
  const { repoAlert, setAlertMessage } = useAlert("usercreate-alert", true);

  const handleVerify = async () => {
    setLoading(true);
    try {
      const { value, errorMessage } = username.props;
      if (errorMessage === "") {
        const { data } = await api.post(Urls.verify, { email: value });
        const { valid } = data || { valid: false };

        if (valid) {
          onVerify(email);
          setVerified(true);
        } else {
          setAlertMessage("Username already exists", true);
          onVerify(null);
        }
      }
    } catch (error) {
      const { detail } = error.data;
      onVerify(null);
      setAlertMessage(detail);
      setVerified(false);
    } finally {
      setLoading(false);
    }
  };

  const userConfig = {
    username: {
      type: "input",
      ...createInput({
        type: "text",
        id: "username",
        label: "Email Address",
        helperText: "Envision Healthcare email address",
        width: 340,
        outline: true,
      }),
      value: "",
      required: true,
      validateOnBlur: true,
      validationRules: [required("Email"), isValidEmail("Account")],
    },
  };

  const { renderForm } = useForm(userConfig);
  const { username } = renderForm();
  const email = username.props.value;

  useEffect(() => {
    if (verified) {
      setVerified(null);
      onReset(null);
    }

    // eslint-disable-next-line
  }, [email]);

  return (
    <div className="flexj">
      {repoAlert}
      <div className="flex my-4 ml-2 space-x-4">
        {username}

        <div className="mt-1">
          {verified ? (
            <VerifiedIcon className="text-[28px] text-green-600 mb-4" />
          ) : (
            <Button onClick={handleVerify} loading={loading}>
              <div className="stepbtn">
                <span className="label">Verify Now</span>
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
