import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAxiosPrivate, useForm, useRequirements } from "../../store/hooks";
import { Button, Panel, PanelBody } from "../../components";
import { createPasswordInput } from "../../form/FormCreate";
import { required } from "../../form/validation-rules";
import { MdOutlineNearbyError as ErrIcon } from "react-icons/md";
import { Urls } from "../../store/axios";

export const ShowPassword = ({ invalidate }) => {
  const [error, setError] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  const {
    element: reqElements,
    handleBlur,
    handleClear,
    handleValidation,
    handleVerify,
  } = useRequirements();

  const pwdMutation = useMutation({
    mutationFn: async ({ currValue, newValue }) =>
      await axiosPrivate.patch(Urls.resetPassword, {
        current: currValue,
        passwd: newValue,
        reset: true,
      }),
    onSuccess: () => {
      invalidate("Password was updated succesfully.");
      handleReset();
    },
    onError: ({ response }) => {
      const { detail } = response.data;
      setError(detail);
    },
  });

  const pwdConfig = {
    currpwd: {
      type: "input",
      ...createPasswordInput({
        type: "password",
        id: "currpwd",
        label: "Current Password",
        helperText: "Your EIIR account",
      }),
      required: true,
      validateOnBlur: true,
      validationRules: [required("Current Password")],
    },
    newpwd: {
      type: "input",
      ...createPasswordInput({
        type: "password",
        id: "newpwd",
        label: "New Password",
        helperText: "Must be different than current password",
      }),
      required: true,
      validationRules: [required("New Password")],
      onInputChange: (control, value) => handleValidation(control, value),
      onBlur: (value) => handleBlur("pwdValue", value),
    },
    confirmpwd: {
      type: "input",
      ...createPasswordInput({
        type: "password",
        id: "confirmpwd",
        label: "Confirm Password",
        helperText: "Must match new password",
      }),
      required: true,
      validateOnBlur: true,
      validationRules: [required("Confirm Password")],
      onBlur: (value) => handleBlur("confirmValue", value),
    },
  };

  const { renderForm, resetForm, isFormValid } = useForm(pwdConfig);
  const { currpwd, newpwd, confirmpwd } = renderForm();

  const currValue = currpwd.props.value;
  const newValue = newpwd.props.value;
  const confirmValue = confirmpwd.props.value;

  useEffect(() => {
    setError(null);
  }, [currValue, newValue, confirmValue]);

  const handleReset = useCallback(() => {
    handleClear();
    resetForm();
    setError(null);
  }, [resetForm, handleClear]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const sameValue = !!newValue && !!confirmValue && newValue === confirmValue;
    const message = handleVerify(await isFormValid(), newValue, sameValue);

    if (message) setError(message);
    else pwdMutation.mutate({ currValue, newValue });
  };

  return (
    <>
      <Panel>
        <PanelBody>
          <div className="inline-flex my-3 ml-10">
            <div className="flex flex-col space-y-4 w-[300px] min-w-[300px]">
              <form
                onReset={handleReset}
                id="resetpwd-form"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col space-y-3">
                  {currpwd}
                  {newpwd}
                  {confirmpwd}
                </div>
                <div className="flex space-x-1 pt-3">
                  <Button
                    type="submit"
                    content="Save Changes"
                    sx={{ padding: 10 }}
                    loading={pwdMutation.isLoading}
                  />
                  <Button type="reset" content="Reset" sx={{ padding: 10 }} />
                </div>
              </form>
            </div>

            <div className="ml-10">
              <p className="font-semibold leading-tight text-[15px]">
                Change Password & Requirements:
              </p>
              <span className="text-sm leading-tight">
                Ensure the following are met:
              </span>
              <ul className="inline-block text-sm text-clip overflow-hidden mt-3 mr-3 space-y-0.5 max-w-[280px]">
                {reqElements}
                {error && (
                  <li className="flex items-center space-x-2 text-red-500">
                    <span>
                      <ErrIcon size={19} />
                    </span>
                    <span>{error}</span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </PanelBody>
      </Panel>
    </>
  );
};
