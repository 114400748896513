// Sortable Table Hoot:
//  Accepts a data array object, an optional initial sort state.
//  @retruns an object with the sorted items, and a function to re-sort the items.

import { useCallback, useMemo, useState } from "react";
import {
  BsSortAlphaUpAlt as DArrow,
  BsSortAlphaDown as UArrow,
  BsArrowDownUp as DUArrow,
} from "react-icons/bs";

function getIcon(direction, reset) {
  if (reset) return <DUArrow />;
  return direction === null ? (
    <DUArrow />
  ) : direction === "asc" ? (
    <UArrow />
  ) : (
    <DArrow />
  );
}

const useSortableData = (data, config, initConfig = {}) => {
  const [sortConfig, setSortConfig] = useState(initConfig);
  const { sortBy, sortOrder } = sortConfig;

  const requestSort = useCallback(
    (key) => {
      const direction = !(sortOrder && sortBy === key)
        ? "asc"
        : sortOrder === "asc"
        ? "desc"
        : null;

      setSortConfig({ sortBy: key, sortOrder: direction });
    },
    [sortBy, sortOrder]
  );

  // useMemo is a way to cache — or memoize — expensive computations.
  // So given the same input, it doesn’t have to sort the products twice if we re-render our component
  // Note:
  //  We trigger a new sort whenever our data change,
  //  or the field or direction we sort by changes.

  const dataSorted = useMemo(() => {
    let sortedItems = data === undefined || data === null ? [] : [...data];

    if (sortOrder) {
      const { sortValue } =
        config.find((column) => column.label === sortBy) || {};
      if (sortValue) {
        sortedItems.sort((a, b) => {
          const firstVal = sortValue(a);
          const secondVal = sortValue(b);
          const reversOrder = sortOrder === "asc" ? 1 : -1;

          return (
            reversOrder *
            (typeof firstVal === "string"
              ? firstVal.localeCompare(secondVal)
              : firstVal - secondVal)
          );
        });
      }
    }

    return sortedItems;
  }, [config, data, sortBy, sortOrder]);

  const headerConfig = useMemo(
    () =>
      config.map((column) => {
        return {
          ...column,
          header: column.sortValue
            ? () => (
                <th scope="col" key={column.label || column.key}>
                  <div className="flex-is">
                    <span
                      className="mr-2 cursor-pointer"
                      onClick={() => requestSort(column.label)}
                    >
                      {getIcon(sortOrder, column.label !== sortBy)}
                    </span>
                    <span>{column.label}</span>
                  </div>
                </th>
              )
            : () => (
                <th className={column.align} style={{ width: column.width }}>
                  {column.key === "Select"
                    ? column.render({})
                    : column.label}
                </th>
              ),
        };
      }),
    [sortBy, sortOrder, requestSort, config]
  );

  return { dataSorted, requestSort, headerConfig };
};

export default useSortableData;
