import { forwardRef, useEffect } from "react";
import { useDebounce } from "../store/hooks";

const GroupInput = (
  {
    lead = {},
    width = 240,
    placeholder,
    helperText,
    right,
    onChange,
    ...props
  },
  ref
) => {
  const [setValue, debounce] = useDebounce(300);

  useEffect(() => {
    if (onChange && debounce !== null) onChange(debounce);
  }, [debounce, onChange]);

  return (
    <div className="ginput">
      <div className="flex" style={{ width }}>
        {!right && <span className="gleft">{lead.icon || lead.text}</span>}
        <input
          ref={ref}
          type="text"
          placeholder={placeholder}
          {...props}
          onChange={({ target }) => setValue(target.value)}
        />
        {right && <span className="gright">{lead.icon || lead.text}</span>}
      </div>
      {helperText && <div className="helper">{helperText}</div>}
    </div>
  );
};

export default forwardRef(GroupInput);
