import { useCallback } from "react";
import { IoMdCube as ServersIcon } from "react-icons/io";
import { Panel, PanelHeader, TabContent, Tabs } from "../../components";
import { useServer } from "../../store/hooks";
import DownloadGroupsForm from "./DownloadGroupsForm";
import NewServerForm from "./NewServerForm";
import ServerList from "./ServerList";

const Servers = () => {
  const { drawer, repoAlert, handlers } = useServer();

  const handleSetDialog = useCallback(
    (title, server, isApi = false) => {
      const Form = isApi ? DownloadGroupsForm : NewServerForm;
      handlers.dialog({
        title,
        content: <Form server={server} onClose={handlers.close} />,
      });
    },
    [handlers]
  );

  return (
    <div className="server-container">
      {repoAlert}

      <Panel>
        <PanelHeader
          PanelIcon={ServersIcon}
          title="Server Manager"
          subtitle="Mirth Connect"
        />
      </Panel>

      <Tabs>
        <TabContent label="Info">
          <ServerList onSetDialog={handleSetDialog} />
        </TabContent>
      </Tabs>

      {drawer}
    </div>
  );
};

export default Servers;
