import { memo } from "react";
import { useMutation } from "@tanstack/react-query";
import { FloatingInput } from "../../components";
import { useAxiosPrivate } from "../../store/hooks";

export const PhoneChange = memo(
  ({ user, phone, invalidate, onChange, onError, onCancel }) => {
    const axiosPrivate = useAxiosPrivate();

    const phoneMutation = useMutation({
      mutationKey: ["users", { publicId: user }],
      mutationFn: async (number) =>
        await axiosPrivate.patch(`/users/profile/phone`, { phone: number }),
      onSuccess: () => invalidate("Phone upated successfully"),
      onError: ({ response }) => {
        const { detail } = response.data;
        onError(detail);
      },
    });

    const handleUpdatePhone = async (phone, isSame) => {
      if (isSame) return;
      const phoneValid =
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(phone);
      if (!phoneValid) onError("Improper phone number!");
      else phoneMutation.mutate(phone);
    };

    return (
      <FloatingInput
        helperText="Numbers only, e.g.: 3051234567"
        defaultValue={phone}
        width={190}
        label="Phone"
        id="user-phone"
        onChange={onChange}
        onPopOver={handleUpdatePhone}
        onCancel={onCancel}
      />
    );
  }
);
