import { memo, useCallback, useState } from "react";
import { useForm, usePasswordGen } from "../../store/hooks";
import { Button, Divider } from "../../components";
import { createInput, createCheck } from "../../form/FormCreate";
import { required, phone } from "../../form/validation-rules";
import { UserCreate } from "./UserCreate";
import {
  MdClose as CloseIcon,
  MdNavigateNext as NextIcon,
} from "react-icons/md";

export const AccountCreate = memo(({ api, onClose, onNext }) => {
  const [user, setUser] = useState(null);
  const { password } = usePasswordGen(10);

  const currClass = user ? "trans-opac" : "opacity-none";
  const { renderForm, isFormValid, resetForm } = useForm(basicConfig);
  const controls = renderForm();

  const handleReset = useCallback(
    (email) => {
      setUser(email);
      resetForm();
    },
    [resetForm]
  );

  const handleClose = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formValid = await isFormValid();
    if (!(formValid && user)) return;

    const payload = {
      ...Object.entries(controls).reduce((reduceObj, [key, control]) => {
        reduceObj[key] = control.props?.value;
        return reduceObj;
      }, {}),

      email: user,
      username: user.replace("@envisionhealth.com", ""),
      generatedPwd: password,
      reset: true,
    };

    onNext(payload);
  };

  return (
    <form id="account-form" onSubmit={handleSubmit} className="drawer-section">
      <UserCreate
        api={api}
        onVerify={(email) => handleReset(email)}
        onReset={handleReset}
      />

      <div className={`accnt ${currClass}`}>
        <p className="text-center mt-1 mb-3">
          Enter the basic information for the user.
        </p>

        <div className="controls">
          <div className="name">
            {controls.given}
            {controls.family}
          </div>
          {controls.display}
          {controls.phone}
          {controls.department}
        </div>
      </div>

      <div className={`flexj ${currClass} space-x-2 mb-5`}>
        <Button onClick={handleClose}>
          <div className="stepbtn">
            <span>
              <CloseIcon size={18} />
            </span>
            <span className="label pr-1">CANCEL</span>
          </div>
        </Button>
        <Divider />
        <Button type="submit" disabled={user === null}>
          <div className="stepbtn">
            <span className="label pl-1.5">NEXT</span>
            <span>
              <NextIcon size={20} />
            </span>
          </div>
        </Button>
      </div>
    </form>
  );
});

const basicConfig = {
  requirechange: {
    type: "check",
    ...createCheck({
      id: "requirechange",
      label: "Requrie password change on first login",
    }),
    checked: true,
    valid: true,
    value: true,
  },
  given: {
    type: "input",
    ...createInput({
      id: "given",
      name: "userdetails-given",
      width: 200,
      helperText: "Given Names",
      outline: true,
    }),
    value: "",
    required: true,
    validateOnBlur: true,
    validationRules: [required("Given Names")],
  },
  family: {
    type: "input",
    ...createInput({
      id: "family",
      name: "userdetails-family",
      helperText: "Last Name",
      outline: true,
    }),
    value: "",
    required: true,
    validateOnBlur: true,
    validationRules: [required("Family Name")],
  },
  display: {
    type: "input",
    ...createInput({
      id: "display",
      name: "userdetails-display",
      width: "100%",
      label: "Display Name",
      helperText: "Ex.: First Name Last Name",
    }),
    value: "",
    required: true,
    validateOnBlur: true,
    validationRules: [required("Display Name")],
  },
  phone: {
    type: "phone",
    ...createInput({
      id: "phone",
      name: "userdetails-phone",
      label: "Phone Number",
      helperText: "Home, office, or mobile only",
    }),
    value: "",
    validateOnBlur: true,
    extraRules: [phone("User Phone Number")],
  },
  department: {
    type: "input",
    ...createInput({
      id: "department",
      name: "userdetails-dept",
      label: "IT Department",
      helperText: "Full department name",
    }),
    value: "",
    required: true,
    validateOnBlur: true,
    validationRules: [required("IT Department")],
  },
};
