const TimeBullet = () => {
  return <div className="timebullet"></div>;
};

const Timeline = ({ children, sx }) => {
  return (
    <div style={sx}>
      <ol className="timeline">{children}</ol>
    </div>
  );
};

export const TimelineItem = ({ title, date, children }) => {
  return (
    <li>
      <div className="title">
        <TimeBullet />
        <h4 className="-mt-2 font-semibold">{title}</h4>
      </div>
      <div className="mb-6 ml-[16px] pb-6">
        <span className="date">{date}</span>
        {children && (
          <p className="mb-4 mt-1 text-neutral-600 leading-tight">{children}</p>
        )}
      </div>
    </li>
  );
};

export default Timeline;
