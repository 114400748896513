import {
  Children,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import HelperText from "./HelperText";
import { GoChevronDown } from "react-icons/go";
import { MdClose } from "react-icons/md";
import { useClickAway } from "../store/hooks";

export const Option = (_) => {
  return;
};

const OptionItem = ({ value, onChange }) => {
  return <li onClick={() => onChange(value)}>{value}</li>;
};

const DropdownLabel = forwardRef(
  (
    {
      label,
      outline,
      disabled,
      showClearButton,
      isOpen,
      width,
      onClick,
      onClear,
    },
    ref
  ) => {
    return (
      <div className="flex-ij space-x-0 w-full">
        <button
          type="button"
          disabled={disabled}
          className="outline-none p-0 focus:outline-none ease_io"
          onClick={onClick}
        >
          <div
            ref={ref}
            className={`droparea${outline ? " outline" : ""}`}
            style={{ width }}
          >
            <div>{label}</div>
            <GoChevronDown
              size={18}
              className={`ease_io${isOpen ? " rotate-180" : ""}`}
            />
          </div>
        </button>
        {showClearButton && label && (
          <span
            className="border border-slate-500 rounded-full p-0.5 shadow"
            onClick={onClear}
          >
            <MdClose size={12} />
          </span>
        )}
      </div>
    );
  }
);

const Dropdown = ({
  outline,
  sourceItems,
  children,
  value,
  helperText,
  width,
  onChange,
  disabled,
  clearButton,
  top,
  id,
}) => {
  const [selection, setSelection] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const dropRef = useRef(false);

  const divRef = useClickAway((outside) => {
    if (outside) setIsOpen(false);
  });

  const handleToggle = useCallback(() => {
    if (!disabled) setIsOpen((prev) => !prev);
  }, [disabled]);

  const handleSelectionChange = useCallback(
    (value = null) => {
      setSelection(value);
      setIsOpen(false);
      onChange(value);
    },
    [onChange]
  );

  useEffect(() => {
    if (dropRef.current) return;
    dropRef.current = true;

    let values;
    if (sourceItems) values = sourceItems;
    else values = Children.count(children) === 1 ? [children] : children;

    const options = values.map((opt) => {
      const props = opt.props || {};
      return (
        <OptionItem
          key={props.value}
          value={props.value}
          selected={props.value === value}
          onChange={handleSelectionChange}
        />
      );
    });

    setOptions(options);
  }, [sourceItems, value, children, onChange, handleSelectionChange]);

  return (
    <div
      ref={divRef}
      id={id}
      className={["dropdown", top && "top"].filter(Boolean).join(" ")}
      style={{ width }}
    >
      <DropdownLabel
        label={value || selection}
        showClearButton={clearButton}
        outline={outline}
        disabled={disabled}
        width={width}
        isOpen={isOpen}
        onClick={handleToggle}
        onClear={() => handleSelectionChange("")}
      />
      <ul className={isOpen ? "open" : "close"}>{options}</ul>
      {helperText && <HelperText label={helperText} />}
    </div>
  );
};

export default memo(Dropdown);
